import React from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

import ComponentProducts from '../../components/login/ComponentProducts';

function Products() {
    return (
        <div>
            <Header isLogin={false} />
            <div className='body'>
                <ComponentProducts/>
            </div>
            <Footer />
        </div>
    )
}

export default Products
