import React, { useState, useEffect } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import SearchModal from '../../components/Modals/SearchModal'
import Table from '../../components/Table/Table'
import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
import NewAccount from '../../components/Modals/NewAccount'

import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';

function AdministerAccounts() {
    const isLogin = true;
    const tipo = "admin cuentas";

    const tableHeaders = ['Cuenta Clabe', 'Nombre', 'Empresa','¿En renta?','Cuenta de Cobro','Acciones'];
    const tableStyles = ['accountStyle', 'textStyle', 'textStyle','textStyle','textStyle','centeredStyle'];

    const selectedOption = "4";

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);

    const createAccount = () => {
        handleShowAdd();
    }
    const isMenu = true;
    const menuIcon = [
        { icon: Edit, url: '/actualizarcuenta', modal: '', clave: '' },
    ];

    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const [updateData, setUpdateData] = useState(1);
    const reload = () => {
        setUpdateData(updateData + 1);
    }

    //Busqueda
    const [searchData, setSearchData] = useState({
        byNumberAccount: '',
        byNameAccount: ''
    });

    function loadAccounts(currentPage) {
        if(currentPage===undefined)
            currentPage = pagination.currentPage;

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        if(searchData.byNumberAccount)
            searchQuery = '&actprefix=' + searchData.byNumberAccount;
        if(searchData.byNameAccount)
            searchQuery = searchQuery + '&actname=' + searchData.byNameAccount;

        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                const acctResponse = response.cuentas.map(acct => ({
                    clabe: acct.accountNumber,
                    nombre: acct.description,
                    empresa: acct.beneficiary,
                    enRenta: acct.enRenta ? 'Si' : '',
                    cuentaCobro: acct.cuentaCobro,
                    id: acct.id
                }));
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setTableData(acctResponse);
            }
        });
    }

    useEffect( () => {
        if(updateData)
            loadAccounts(pagination.currentPage);
    },[pagination.currentPage, updateData])
    
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        loadAccounts(1);
    }

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption}/>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-7 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Cuentas</p>   
                                        </div>
                                        <div className="col-sm-4 contenBtnAdmin">
                                            <button className="btn btn-primary" type="button" onClick={createAccount}>Nueva Cuenta</button>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={tableData} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} isMenu={isMenu} /*link={link}*/
                                        from='admin' currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems/pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData}/>
            <NewAccount /*data={selected}*/ show={showAdd} handleClose={handleCloseAdd} reload={reload} />
        </div>
    )
}

export default AdministerAccounts
