import React from "react";

//import imgFondo2 from '../../assets/img/Edificios3.png';
import imac from "../../assets/img/imac.png";
import terminal from "../../assets/img/punto-ventaPIN.png";
import iconEmpresa from "../../assets/img/empresarial.png";
import iconTarjeta from "../../assets/img/tarjeta.png";
import iconTerminal from "../../assets/img/puntoventa.png";

function ComponentProducts() {
  return (
    <div className="overlay2">
      <div className="col-primary">
        <div className="row bg-primary-p space">
          <div className="col-sm-6">
            <div className="bg-text-terminal">
              <p className="text-productos">
                {" "}
                PRODUCTOS <br /> QUE
                <span className="resaltar-login"> PERMITEN</span> A NUESTROS{" "}
                <br />
                <span className="resaltar-login">
                  {" "}
                  CLIENTES MANEJAR
                </span> SUS <br /> NECESIDADES
                <span className="resaltar-login">
                  {" "}
                  FINANCIERAS Y <br /> CREDITICIAS{" "}
                </span>{" "}
                DE MANERA <br />
                <span className="resaltar-login"> FÁCIL Y SENCILLA</span>
              </p>
            </div>
            <div className="bg-terminal">
              <img className="imgTerminal" src={terminal} alt="" />
            </div>
          </div>
          <div className="col-sm-6 bg-mac">
            <img className="imgImac" src={imac} alt="" />
          </div>
        </div>
      </div>
      <div className="col-succundar">
        <div className="row space bg-secondary-p">
          <div className="col-4 col-productos">
            <div className="col-content-p text-center">
              <h3 className="form-label-p">
                CUENTA EMPRESARIAL <br />
              </h3>
              <img className="iconEmpresa" src={iconEmpresa} alt="" />
            </div>
          </div>
          <div className="col-4 col-productos">
            <div className="col-content-p text-center">
              <h3 className="form-label-p">
                TARJETA DE CRÉDITO <br /> GARANTIZADA
              </h3>
              <img className="iconEmpresa" src={iconTarjeta} alt="" />
            </div>
          </div>
          <div className="col-4 col-productos">
            <div className="col-content-p text-center">
              <h3 className="form-label-p">TERMINAL PUNTO DE VENTA</h3>
              <img className="iconEmpresa" src={iconTerminal} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponentProducts;
