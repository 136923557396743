import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';

function NewCompanyB({showModal, handleCloseModal, isPersonaFisica, reload}) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    var workingWith = 'Empresa';
    if(isPersonaFisica)
        workingWith = 'Persona física'

    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }
    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN de la Empresa'
    }]);

    useEffect( () => {
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const udns = response.udns.map( item => ({
                        id: item.id, 
                        value: item.descripcion,
                    }));
                    setUdnsToSelect(udns);
                }
             });    
        }

        getUdns();
    }, [navigate]);

    const handleChange = (e) => {
        const { id, value } = e.target;

        if( id === 'nombre' && errorIn.maxLength(value, 100, 'error en nombre') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id === 'rfc' && errorIn.maxLength(value, 13, 'error en rfc') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id === 'razonSocial' && errorIn.maxLength(value, 255, 'error en razon social') ) {
            e.target.value = companyData[id] || '';
            return;
        }

		setCompanyData((prevData) => ({
			...prevData,
			[id]: value
		}));

    }

    const saveChanges = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const dataToCreate = {
            id: 0,
            razon_social: companyData.razonSocial,
            rfc: companyData.rfc,
            idCliente: companyData.idCliente,
            nombreCorto: companyData.nombre,
        }

        const strPayload = JSON.stringify(dataToCreate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        var urlToCreate = '';
        if(isPersonaFisica)
            urlToCreate = '&ispf=true';

        postData(process.env.REACT_APP_ADMIN_COMPANIES + "2/?token=" + userData.token + urlToCreate, payload, headers)
        .then(({ response, error}) => {
            if (error) { resetData();
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                resetData();
                setAlert({tipo: 'success', msn: workingWith + ' creada satisfactoriamente', show: true});
                reload();
            }
        });
    }

    const [companyData, setCompanyData] = useState({
        idCliente: 0,
        nombre: '',
        rfc: '',
        razonSocial: ''
    });

    const resetData = () => {
        setCompanyData({
            idCliente: 0,
            nombre: '',
            rfc: '',
            razonSocial: ''
        });
    };

    const handleExitButtonClick = () => {
        handleCloseModal();
        closeAlert();
    };

    return ( 
        <div>
            <Modal show={showModal} onHide={() => {handleExitButtonClick();}} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title"> Crear una nueva {workingWith} </h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula"> 
                        <div className="row row-form-text7">
                            <Message alert={alert} closeAlert={closeAlert} />
                            <div className="col-lg-6 content-input3">
                                <label className="col-form-label">UDN</label>
                                <select className="form-select txtControl" id="idCliente" value={companyData.idCliente} onChange={handleChange} >
                                    <Options options={udnsToSelect} />
                                </select>
                            </div>
                            <div className="col-lg-6 content-input3">
                                <label className="col-form-label">Nombre</label>
                                <input type="text" className="form-control" value={companyData.nombre} id='nombre' placeholder="Nombre" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row row-form-text7">
                            <div className="col-lg-6 content-input2">
                                <label className="col-form-label">RFC</label>
                                <input type="text" className="form-control" value={companyData.rfc} id='rfc' placeholder="RFC" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-input2">
                                <label className="col-form-label">Nombre o Razón Social</label>
                                <input type="text" className="form-control" value={companyData.razonSocial} id='razonSocial' placeholder="Razón social" onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={saveChanges}>Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NewCompanyB
