import React from 'react';
import { Link } from 'react-router-dom';

function MenuIcon({id, menuIcon, tipo}) {

    return (
        <div>
            {tipo != null ? (
                <div>
                    {menuIcon.map((item, index) => (
                        <button key={index} className='btnSearchModal' onClick={item.modal}>
                            <img src={item.icon} alt="" className='iconButton' />
                        </button>
                    ))}
                </div>
            ) : (
                <div>
                    {menuIcon.map((item, index) => (
                        <Link key={index} to={item.url + "/" + id} className='iconTable' data-bs-toggle={item.modal} data-bs-target={item.clave}>
                            <img src={item.icon} alt="" className='iconButton' />
                        </Link>
                    ))}
                </div>
            )}
            
        </div> 
    )
}

export default MenuIcon
