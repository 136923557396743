import React from 'react';
import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

function Support() {
    const isLogin = false;
    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className='container-fluid2 position-absolute space-acconts'>
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-sub-title-bg'>
                                    <h4 className="subTitle">Soporte y Ayuda de SitesPay</h4>
                                </div>
                                <div className="bg-container-body-card2">
                                    <div className="card cardPolitice">
                                        <p className='txtPolicies'>
                                            Bienvenido/a a nuestra página de soporte y ayuda. En esta sección, te proporcionamos información y recursos para resolver cualquier pregunta o problema que puedas tener con nuestro sitio web.
                                        </p>
                                        <div className="accordion" id="accordionExample">
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        ¿Necesitas Ayuda?
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p className='txtPolicies'>
                                                            Si tienes alguna pregunta, problema técnico o simplemente necesitas ayuda con nuestro sitio web, estamos aquí para ayudarte. Por favor, no dudes en contactarnos a través de nuestro correo electrónico de soporte:
                                                        </p>
                                                        <p className='txtPolicies2'>Correo Electrónico de Soporte: </p>
                                                        <a href="#" className="link-primary2">support@empresa.com</a>
                                                        <p className='txtPolicies4'>
                                                            Uno de nuestros representantes de atención al cliente se pondrá en contacto contigo lo antes posible para ayudarte con tus inquietudes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        Preguntas Frecuentes (FAQ)
                                                    </button>
                                                </h2>
                                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p className='txtPolicies'>
                                                            Antes de contactarnos, te recomendamos que consultes nuestra sección de Preguntas Frecuentes (FAQ) donde puedes encontrar respuestas a las preguntas más comunes sobre nuestro sitio web, productos y servicios.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        Nuestro Compromiso
                                                    </button>
                                                </h2>
                                                <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p className='txtPolicies'>
                                                            En <span class="resaltar">SitesPay</span>, nos comprometemos a brindarte un excelente servicio de soporte y ayuda. Valoramos tu experiencia con nuestro sitio web y estamos aquí para garantizar que obtengas la asistencia que necesitas.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                        Enviar Comentarios
                                                    </button>
                                                </h2>
                                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p className='txtPolicies'>
                                                            Tu opinión es importante para nosotros. Si tienes algún comentario, sugerencia o simplemente quieres compartir tu experiencia con nuestro sitio web, ¡nos encantaría escucharte! Por favor, envíanos tus comentarios a través del correo electrónico proporcionado anteriormente.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                        Mantente Conectado
                                                    </button>
                                                </h2>
                                                <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        <p className='txtPolicies'>
                                                            Síguenos en nuestras redes sociales para estar al tanto de las últimas actualizaciones, noticias y consejos útiles sobre nuestro sitio web y servicios.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mg' >
                                            <p className='txtPolicies'>
                                                Facebook: [enlace]
                                            </p>
                                            <p className='txtPolicies'>
                                                Twitter: [enlace]
                                            </p>
                                            <p className='txtPolicies'>
                                                Instagram: [enlace]
                                            </p>
                                            <p className='txtPolicies'>
                                                ¡Gracias por elegirnos! Estamos aquí para ayudarte en cualquier momento
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Support
