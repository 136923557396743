import React from 'react'

function Message({alert, closeAlert}) {

  const handleClose = () => {
    const alert = { tipo: '', msn: '', show: false };
    closeAlert(alert);
  }

  return (
    <div>
      {alert.show && (
        <div className={"alert alert-" + alert.tipo + " alert-dismissible fade show message"} role="alert" style={{ whiteSpace: 'pre-wrap' }}>
          {alert.msn}
          <button onClick={handleClose} type="button" className="btn-close"  aria-label="Close"></button>
        </div>
      )}
    </div>
  );
    
}

export default Message;