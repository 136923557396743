import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { fetchData, putData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';
import * as catalogs from '../../utils/catalogs';

function Company({ id, loadData, reload }) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [companyData, setCompanyData] = useState({
        id: 0,
        perfilId: 0,
        domicilioId: 0,
        repLegalId: 0,
        razonSocial: '',
        rfc: '',
        IdIdentificacion: '1',
        numIdentificacion: '',
        fechaCreacion: '',
        entidad: 6,
        pais: 'MEXICO',
        nacionalidad: 1,
        serieFirmaElect: '',
        giro: 673,
        telefono: '',
        geolocalizacion: '',
        email: '',
        denominacion: 2,
        udnId: 1268,
        nivelCuenta: 4,
        uuid: '',

        idCliente: 0,
        nombre: '',
        costoFijoSpeiIn: 0,
        costoPctSpeiIn: 0,
        costoFijoSpeiOut: 0,
        costoPctSpeiOut: 0,
        tieneDist: 0,

        limiteCredito: 0,
        comisionTarjeta: 0,

        callePrincipal: '',
        calleSecundaria2: '',
        calleSecundaria3: '',
        ciudad: '',
        codPostal: '',
        colonia: '',
        numExterior: '',
        numInterior: ''
    });

    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 1268,
        value: 'Seleccione la UDN de la Empresa'
    }]);

    //traemos los datos que se necesitan desde los servicios
    useEffect(() => {
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const udns = response.udns.map(item => ({
                            id: item.id, //idUdn,
                            value: item.descripcion,
                            //clientId: item.id
                        }));
                        setUdnsToSelect(udns);
                    }
                });
        }
        getUdns();
    }, [navigate]);

    useEffect(() => {
        if (loadData) {
            var domicilioObj = loadData.domicilio;
            var pattern = /(\d{4})(\d{2})(\d{2})/;
            var newDate = '';
            if (loadData.fechaCreacion)
                newDate = loadData.fechaCreacion.replace(pattern, '$1-$2-$3');
            var countryId = 'MEXICO';
            //console.log(loadData.pais);
            if (loadData.pais)
                countryId = catalogs.findCountry(loadData.pais);

            setCompanyData({
                id: loadData.id,
                udnId: loadData.idCliente, //udnId,
                perfilId: loadData.perfilId,
                domicilioId: loadData.domicilioId,
                repLegalId: loadData.repLegalId,
                razonSocial: loadData.razonSocial,
                rfc: loadData.rfc,
                IdIdentificacion: loadData.IdIdentificacion ? loadData.IdIdentificacion.toString() : '1',
                numIdentificacion: loadData.numIdentificacion ? loadData.numIdentificacion : '',
                fechaCreacion: newDate, //loadData.fechaCreacion, //.replace(pattern,'$1/$2/$3'),
                entidad: loadData.entidad ? loadData.entidad : 6,
                pais: countryId,
                nacionalidad: loadData.nacionalidad ? loadData.nacionalidad : 1,
                serieFirmaElect: loadData.serieFirmaElect ? loadData.serieFirmaElect : '',
                giro: loadData.giro ? loadData.giro : 673,
                telefono: loadData.telefono ? loadData.telefono : '',
                geolocalizacion: loadData.geolocalizacion ? loadData.geolocalizacion : '',
                email: loadData.email ? loadData.email : '',
                denominacion: loadData.denominacion ? loadData.denominacion : 2,
                nivelCuenta: loadData.nivelCuenta ? loadData.nivelCuenta : 4,
                uuid: loadData.uuid,

                idCliente: loadData.idCliente,
                nombre: loadData.nombre ? loadData.nombre : '',
                costoFijoSpeiIn: loadData.costoFijoSpeiIn ? loadData.costoFijoSpeiIn : 0,
                costoPctSpeiIn: loadData.costoPctSpeiIn ? loadData.costoPctSpeiIn : 0,
                costoFijoSpeiOut: loadData.costoFijoSpeiOut ? loadData.costoFijoSpeiOut : 0,
                costoPctSpeiOut: loadData.costoPctSpeiOut ? loadData.costoPctSpeiOut : 0,
                tieneDist: loadData.tieneDist,

                limiteCredito: loadData.limiteCredito,
                comisionTarjeta: loadData.comisionTarjeta,

                callePrincipal: domicilioObj.callePrincipal ? domicilioObj.callePrincipal : '',
                calleSecundaria2: domicilioObj.calleSecundaria2 ? domicilioObj.calleSecundaria2 : '',
                calleSecundaria3: domicilioObj.calleSecundaria3 ? domicilioObj.calleSecundaria3 : '',
                ciudad: domicilioObj.ciudad ? domicilioObj.ciudad : '',
                codPostal: domicilioObj.codPostal ? domicilioObj.codPostal : '',
                colonia: domicilioObj.colonia ? domicilioObj.colonia : '',
                numExterior: domicilioObj.numExterior ? domicilioObj.numExterior : '',
                numInterior: domicilioObj.numInterior ? domicilioObj.numInterior : ''
            });
        }
    }, [loadData]);

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'costoFijoSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            if (value !== '' && errorIn.integer(value)) {
                e.target.value = companyData[id] || '';
                return;
            }
        }

        if (id === 'costoPctSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }

        if (id === 'costoPctSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }

        if (id === 'costoFijoSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'limiteCredito' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'comisionTarjeta' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        
        /*
        if (id === 'nombre' && errorIn.lengthTrim(value, 1, 100, 'error en nombre')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'numIdentificacion' && errorIn.lengthTrim(value, 1, 30, 'error en numIdentificacion')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'rfc' && errorIn.lengthTrim(value, 1, 13, 'error en rfc')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'razonSocial' && errorIn.lengthTrim(value, 1, 255, 'error en razon social')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'telefono' && errorIn.lengthTrim(value, 1, 16, 'error en telefono')) {
            e.target.value = companyData[id] || '';
            return;
        }
        
        if (id === 'email' && (errorIn.email(value, 'error en email') || errorIn.lengthTrim(value, 1, 45, 'error en email'))) {
            e.target.value = companyData[id] || '';
            return;
        }
        
        if (id === 'serieFirmaElect' && errorIn.lengthTrim(value, 1, 50, 'error en serieFirmaElect')) {
            e.target.value = companyData[id] || '';
            return;
        }
        */

        setCompanyData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const UpdateCompany = () => {
        //guardo... luego regreso
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        var dataToUpdate = {};
        var urlToPut = '';
        var update = 'Datos de empresa';
        var idToUpdate = 0;

        if (isAddress) {
            if (errorIn.lengthTrim(companyData.callePrincipal, 1, 100, 'Error en calle principal')) {
                setAlert({ tipo: 'danger', msn: 'Error en calle principal', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.ciudad, 1, 100, 'Error en ciudad')) {
                setAlert({ tipo: 'danger', msn: 'Error en ciudad', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.codPostal, 1, 5, 'Error en codigo postal')) {
                setAlert({ tipo: 'danger', msn: 'Error en código postal', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.colonia, 1, 400, 'Error en colonia')) {
                setAlert({ tipo: 'danger', msn: 'Error en colonia', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.numExterior, 1, 15, 'Error en número exterior')) {
                setAlert({ tipo: 'danger', msn: 'Error en número exterior', show: true });
                return;
            }
            dataToUpdate = {
                id: companyData.domicilioId,
                callePrincipal: companyData.callePrincipal,
                calleSecundaria2: companyData.calleSecundaria2,
                calleSecundaria3: companyData.calleSecundaria3,
                ciudad: companyData.ciudad,
                codPostal: companyData.codPostal,
                colonia: companyData.colonia,
                numExterior: companyData.numExterior,
                numInterior: companyData.numInterior
            }
            urlToPut = process.env.REACT_APP_ADMIN_ADDRESS;
            update = 'Domicilio de empresa';
            idToUpdate = companyData.domicilioId;
        } else {
            if (errorIn.lengthTrim(companyData.razonSocial, 1, 250, 'Error en razonSocial')) {
                setAlert({ tipo: 'danger', msn: 'Error en razón social', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.rfc, 12, 13, 'Error en rfc')) {
                setAlert({ tipo: 'danger', msn: 'Error en rfc', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.nombre, 1, 100, 'Error en nombre')) {
                setAlert({ tipo: 'danger', msn: 'Error en nombre', show: true });
                return;
            }
            if (errorIn.floatPoint(companyData.costoFijoSpeiIn, 'error')) {
                setAlert({ tipo: 'danger', msn: 'Error en Costo Fijo SPEI In', show: true });
                return;
            }
            if (errorIn.floatPoint(companyData.costoPctSpeiIn, 'error')) {
                setAlert({ tipo: 'danger', msn: 'Error en Costo Porcentual SPEI In', show: true });
                return;
            }
            if (errorIn.floatPoint(companyData.costoFijoSpeiOut, 'error')) {
                setAlert({ tipo: 'danger', msn: 'Error en Costo Fijo SPEI Out', show: true });
                return;
            }
            if (errorIn.floatPoint(companyData.costoPctSpeiOut, 'error')) {
                setAlert({ tipo: 'danger', msn: 'Error en Costo Porcentual SPEI Out', show: true });
                return;
            }
            if (errorIn.email(companyData.email, 'Error en correo')) {
                setAlert({ tipo: 'danger', msn: 'Error en correo', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.fechaCreacion, 8, 10, 'Error en fechaCreacion')) {
                setAlert({ tipo: 'danger', msn: 'Error en fecha creación', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.numIdentificacion, 1, 50, 'Error en numIdentificacion')) {
                console.log(companyData);
                setAlert({ tipo: 'danger', msn: 'Error en identificación oficial', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.telefono, 8, 16, 'Error en telefono')) {
                setAlert({ tipo: 'danger', msn: 'Error en teléfono', show: true });
                return;
            }
            if (errorIn.lengthTrim(companyData.serieFirmaElect, 1, 50, 'Error en serieFirmaElect')) {
                setAlert({ tipo: 'danger', msn: 'Error en serieFirmaElect', show: true });
                return;
            }

            dataToUpdate = {
                id: companyData.id,
                razon_social: companyData.razonSocial,
                rfc: companyData.rfc,
                IdIdentificacion: companyData.IdIdentificacion,
                numIdentificacion: companyData.numIdentificacion,
                fechaCreacion: companyData.fechaCreacion,
                entidad: companyData.entidad,
                pais: catalogs.countryName(companyData.pais),
                nacionalidad: companyData.nacionalidad,
                serieFirmaElect: companyData.serieFirmaElect,
                giro: companyData.giro,
                telefono: companyData.telefono,
                geolocalizacion: companyData.geolocalizacion,
                email: companyData.email,
                denominacion: companyData.denominacion,
                nivelCuenta: companyData.nivelCuenta,
                nombre: companyData.nombre,
                costoFijoSpeiIn: companyData.costoFijoSpeiIn,
                costoPctSpeiIn: companyData.costoPctSpeiIn,
                costoFijoSpeiOut: companyData.costoFijoSpeiOut,
                costoPctSpeiOut: companyData.costoPctSpeiOut,
                limiteCredito: companyData.limiteCredito,
                comisionTarjeta: companyData.comisionTarjeta
            }
            urlToPut = process.env.REACT_APP_ADMIN_COMPANIES;
            idToUpdate = id;
        }

        //console.log('Mandamos a BD: ', dataToUpdate);
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = { data: cryptPayload };


        putData(urlToPut + idToUpdate + "?token=" + userData.token, payload, headers)
            .then(({ response, error }) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }

                if (response) {
                    console.log(response);
                    setAlert({ tipo: 'success', msn: update + ' actualizados satisfactoriamente', show: true });
                    //handleCloseModal();
                    reload();
                }
            });
    }

    function Options({ options }) {
        //console.log(options);
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [isAddress, setIsAddress] = useState(false);

    const showAddress = () => {
        setIsAddress(true);
    }

    const closeAddress = () => {
        setIsAddress(false);
    }

    return (
        <div>
            <Message alert={alert} closeAlert={closeAlert} />
            <div className="row bg-title-admin">
                <div className='col-sm-4'>
                    {!isAddress ? (
                        <h5 className="title-admin"> Datos de la empresa </h5>
                    ) : (
                        <h5 className="title-admin"> Domicilio de la empresa </h5>
                    )}
                </div>
                <div className='col-sm-8 d-flex justify-content-end'>
                {!isAddress ? (
                            <button className="btn btn-secondary" type="button" onClick={showAddress}>Dirección de la Empresa</button>
                        ) : (
                            <button className="btn btn-secondary" type="button" onClick={closeAddress}>Datos de la Empresa</button>
                        )}
                        <button className="btn btn-success btn-space" type="button" onClick={() => UpdateCompany()} >Guardar</button>
                </div>
            </div>
            <hr></hr>
            {!isAddress ? (
                <div>
                    <div className='container-admin'>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">UDN</label>
                                <select className="form-select txtControl" id="udnid" value={companyData.udnId} onChange={handleChange} >
                                    <Options options={udnsToSelect} />
                                </select>
                            </div>
                            <div className='col-md-6' >
                                <label className="col-form-label-admin">Nombre</label>
                                <input type="text" className="form-control txtControl" value={companyData.nombre} id='nombre' placeholder="Nombre" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">RFC</label>
                                <input type="text" className="form-control txtControl" value={companyData.rfc} id='rfc' placeholder="RFC" onChange={handleChange} />
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Razón Social</label>
                                <input type="text" className="form-control txtControl" value={companyData.razonSocial} id='razonSocial' placeholder="Razón social" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Denominación</label>
                                <select className="form-select txtControl" id="denominacion" value={companyData.denominacion} onChange={handleChange} >
                                    <option value="2">S.A. de C.V. - SOCIEDAD ANONIMA DE CAPITAL VARIABLE</option>
                                    <option value="16">SAPI de CV - SOCIEDAD ANONIMA PROMOTORA DE INVERSION DE CAPITAL VARIABLE</option>
                                    <option value="12">S. de R.L. de C.V. - SOCIEDAD DE RESPONSABILIDAD LIMITADA DE CAPITAL VARIABLE</option>
                                    <option value="7">S. de R.L. - SOCIEDAD DE RESPONSABILIDAD LIMITADA</option>
                                    <option value="18">A.C. - ASOCIACION CIVIL</option>
                                    <option value="10">S.C. - SOCIEDAD CIVIL</option>
                                    <option value="1">S.A. - SOCIEDAD ANONIMA</option>
                                    <option value="4">S.C. - SOCIEDAD COOPERATIVA</option>
                                    <option value="6">S en C - SOCIEDAD EN COMANDITA SIMPLE</option>
                                    <option value="8">S. en C. por A. - SOCIEDAD EN COMANDITA POR ACCIONES</option>
                                    <option value="11">SPR. de RI. - SOCIEDAD DE PRODUCCION RURAL DE RESPONSABILIDAD ILIMITADA</option>
                                    <option value="19">SPR. de RL. - SOCIEDAD DE PRODUCCION RURAL DE RESPONSABILIDAD LIMITADA</option>
                                    <option value="13">SC de RL de CV - SOCIEDAD COOPERATIVA DE RESPONSABILIDAD LIMITADA DE CAPITAL VARIABLE</option>
                                    <option value="15">A.R. de I.C - ASOCIACIONES RURALES DE INTERES COLECTIVO</option>
                                    <option value="17">S.A.S. de C.V. - SOCIEDAD POR ACCIONES SIMPLIFICADA DE CAPITAL VARIABLE</option>
                                    <option value="20">S de PL de RS. - SOCIEDAD DE PRODUCCION RURAL DE RESPONSABILIDAD SUPLEMENTADA</option>
                                    <option value="21">SCP de RL de CV - SOCIEDAD COOPERATIVA DE PRODUCCION DE RESPONSABILIDAD LIMITADA DE CAPITAL VARIABLE</option>
                                    <option value="22">S. de R.L. M.I. - SOCIEDAD DE RESPONSABILIDAD LIMITADA MICROINDUSTRIAL</option>
                                    <option value="23">S. de S.S. -SOCIEDAD DE SOLIDARIDAD SOCIAL</option>
                                    <option value="14">EJIDO</option>
                                </select>
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Fecha de Creación</label>
                                <input type="date" className="form-control txtControl" value={companyData.fechaCreacion} id='fechaCreacion' placeholder="Fecha Creación" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin' >
                                <label className="col-form-label-admin">Teléfono</label>
                                <input type="text" className="form-control txtControl" value={companyData.telefono} id='telefono' placeholder="Teléfono" onChange={handleChange} />
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Correo Electrónico</label>
                                <input type="text" className="form-control txtControl" value={companyData.email} id='email' placeholder="Correo electrónico" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Tipo de identificación</label>
                                <select className="form-select txtControl" id="IdIdentificacion" value={companyData.IdIdentificacion} onChange={handleChange}>
                                    <option value="1">INE</option>
                                    <option value="3">CARTILLA MILITAR</option>
                                    <option value="4">CEDULA PROFESIONAL</option>
                                    <option value="8">DOCUMENTO MIGRATORIO</option>
                                    <option value="7">INAPAM</option>
                                    <option value="6">LICENCIA DE CONDUCIR</option>
                                    <option value="5">OTRO</option>
                                    <option value="2">PASAPORTE</option>
                                </select>
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Número de identificación</label>
                                <input type="text" className="form-control txtControl" value={companyData.numIdentificacion} id='numIdentificacion' placeholder="Número de Identificación" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Nacionalidad</label>
                                <select className="form-select txtControl" id="nacionalidad" value={companyData.nacionalidad} onChange={handleChange}>
                                    <option value="1">MEXICANO</option>
                                    <option value="14">ARABE</option>
                                    <option value="22">ARGENTINO</option>
                                    <option value="42">BOLIVIANO</option>
                                    <option value="28">BRASILEÑO</option>
                                    <option value="36">BULGARA</option>
                                    <option value="34">CANADIENSE</option>
                                    <option value="21">CHILENO</option>
                                    <option value="31">CHINA</option>
                                    <option value="24">COLOMBIANA</option>
                                    <option value="8">COSTARRICENSE</option>
                                    <option value="12">CUBANO</option>
                                    <option value="29">DOMINICANO</option>
                                    <option value="33">ECUATORIANO</option>
                                    <option value="13">ESPAÑOL</option>
                                    <option value="5">ESTADOUNIDENSE</option>
                                    <option value="20">FRANCESA</option>
                                    <option value="16">GUATEMALA</option>
                                    <option value="11">GUATEMALTECO</option>
                                    <option value="10">HONDUREÑO</option>
                                    <option value="19">IRAQUI</option>
                                    <option value="37">ISRAELI</option>
                                    <option value="18">ITALIANO</option>
                                    <option value="35">JAPONES</option>
                                    <option value="30">NICARAGUENSE</option>
                                    <option value="3">PALESTINO</option>
                                    <option value="26">PANAMEÑO</option>
                                    <option value="15">PERUANO</option>
                                    <option value="25">PORTORRIQUEÑO</option>
                                    <option value="32">REPUBLICA DE COREA</option>
                                    <option value="2">SALVADOREÑO</option>
                                    <option value="23">SUIZO</option>
                                    <option value="9">URUGUAYO</option>
                                    <option value="27">VENEZOLANO</option>
                                </select>
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Serie de Firma Electrónica</label>
                                <input type="text" className="form-control txtControl" value={companyData.serieFirmaElect} id='serieFirmaElect' placeholder="Número de serie de firma electrónica" onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">País</label>
                                <select className="form-select txtControl" id="pais" value={companyData.pais} onChange={handleChange}>
                                    <option value="117">MEXICO</option>
                                    <option value="1">AFGANISTAN</option>
                                    <option value="2">ALBANIA</option>
                                    <option value="3">ALEMANIA</option>
                                    <option value="4">ANDORRA</option>
                                    <option value="5">ANGOLA</option>
                                    <option value="6">ANTIGUA Y BARBUDA</option>
                                    <option value="7">ARABIA SAUDITA</option>
                                    <option value="8">ARGELIA</option>
                                    <option value="9">ARGENTINA</option>
                                    <option value="10">ARMENIA</option>
                                    <option value="11">AUSTRALIA</option>
                                    <option value="12">AUSTRIA</option>
                                    <option value="13">AZERBAIYÁN</option>
                                    <option value="14">BAHAMAS</option>
                                    <option value="15">BANGLADÉS</option>
                                    <option value="16">BARBADOS</option>
                                    <option value="17">BARÉIN</option>
                                    <option value="18">BÉLGICA</option>
                                    <option value="19">BELICE</option>
                                    <option value="20">BENÍN</option>
                                    <option value="21">BIELORRUSIA</option>
                                    <option value="22">BIRMANIA</option>
                                    <option value="23">BOLIVIA</option>
                                    <option value="24">BOSNIA Y HERZEGOVINA</option>
                                    <option value="25">BOTSUANA</option>
                                    <option value="26">BRASIL</option>
                                    <option value="27">BRUNÉI</option>
                                    <option value="28">BULGARIA</option>
                                    <option value="29">BURKINA FASO</option>
                                    <option value="30">BURUNDI</option>
                                    <option value="31">BUTÁN</option>
                                    <option value="32">CABO VERDE</option>
                                    <option value="33">CAMBOYA</option>
                                    <option value="34">CAMERÚN</option>
                                    <option value="35">CANADÁ</option>
                                    <option value="36">CATAR</option>
                                    <option value="37">CHAD</option>
                                    <option value="38">CHILE</option>
                                    <option value="39">CHINA</option>
                                    <option value="40">CHIPRE</option>
                                    <option value="41">CIUDAD DEL VATICANO</option>
                                    <option value="42">COLOMBIA</option>
                                    <option value="43">COMORAS</option>
                                    <option value="44">COREA DEL NORTE</option>
                                    <option value="45">COREA DEL SUR</option>
                                    <option value="46">COSTA DE MARFIL</option>
                                    <option value="47">COSTA RICA</option>
                                    <option value="48">CROACIA</option>
                                    <option value="49">CUBA</option>
                                    <option value="50">DINAMARCA</option>
                                    <option value="51">DOMINICA</option>
                                    <option value="52">ECUADOR</option>
                                    <option value="53">EGIPTO</option>
                                    <option value="54">EL SALVADOR</option>
                                    <option value="55">EMIRATOS ÁRABES UNIDOS</option>
                                    <option value="56">ERITREA</option>
                                    <option value="57">ESLOVAQUIA</option>
                                    <option value="58">ESLOVENIA</option>
                                    <option value="59">ESPAÑA</option>
                                    <option value="60">ESTADOS UNIDOS</option>
                                    <option value="61">ESTONIA</option>
                                    <option value="62">ETIOPÍA</option>
                                    <option value="63">FILIPINAS</option>
                                    <option value="64">FINLANDIA</option>
                                    <option value="65">FIJI</option>
                                    <option value="66">FRANCIA</option>
                                    <option value="67">GABÓN</option>
                                    <option value="68">GAMBIA</option>
                                    <option value="69">GEORGIA</option>
                                    <option value="70">GHANA</option>
                                    <option value="71">GRANADA</option>
                                    <option value="72">GRECIA</option>
                                    <option value="73">GUATEMALA</option>
                                    <option value="75">GUINEA</option>
                                    <option value="74">GUINEA ECUATORIAL</option>
                                    <option value="76">GUINEA-BISÁU</option>
                                    <option value="77">GUYANA</option>
                                    <option value="78">HAITÍ</option>
                                    <option value="79">HONDURAS</option>
                                    <option value="80">HUNGRÍA</option>
                                    <option value="81">INDIA</option>
                                    <option value="82">INDONESIA</option>
                                    <option value="83">IRAK</option>
                                    <option value="84">IRÁN</option>
                                    <option value="85">IRLANDA</option>
                                    <option value="86">ISLANDIA</option>
                                    <option value="87">ISLAS MARSHALL</option>
                                    <option value="88">ISLAS SALOMÓN</option>
                                    <option value="89">ISRAEL</option>
                                    <option value="90">ITALIA</option>
                                    <option value="91">JAMAICA</option>
                                    <option value="92">JAPÓN</option>
                                    <option value="93">JORDANIA</option>
                                    <option value="94">KAZAJISTÁN</option>
                                    <option value="95">KENIA</option>
                                    <option value="96">KIRGUISTÁN</option>
                                    <option value="97">KIRIBATI</option>
                                    <option value="98">KUWAIT</option>
                                    <option value="99">LAOS</option>
                                    <option value="100">LESOTO</option>
                                    <option value="101">LETONIA</option>
                                    <option value="102">LÍBANO</option>
                                    <option value="103">LIBERIA</option>
                                    <option value="104">LIBIA</option>
                                    <option value="105">LIECHTENSTEIN</option>
                                    <option value="106">LITUANIA</option>
                                    <option value="107">LUXEMBURGO</option>
                                    <option value="108">MADAGASCAR</option>
                                    <option value="109">MALASIA</option>
                                    <option value="110">MALAUI</option>
                                    <option value="111">MALDIVAS</option>
                                    <option value="112">MALÍ</option>
                                    <option value="113">MALTA</option>
                                    <option value="114">MARRUECOS</option>
                                    <option value="115">MAURICIO</option>
                                    <option value="116">MAURITANIA</option>
                                    <option value="118">MICRONESIA</option>
                                    <option value="119">MOLDAVIA</option>
                                    <option value="120">MÓNACO</option>
                                    <option value="121">MONGOLIA</option>
                                    <option value="122">MONTENEGRO</option>
                                    <option value="123">MOZAMBIQUE</option>
                                    <option value="124">NAMIBIA</option>
                                    <option value="125">NAURU</option>
                                    <option value="126">NEPAL</option>
                                    <option value="127">NICARAGUA</option>
                                    <option value="128">NÍGER</option>
                                    <option value="129">NIGERIA</option>
                                    <option value="130">NORUEGA</option>
                                    <option value="131">NUEVA ZELANDA</option>
                                    <option value="132">OMÁN</option>
                                    <option value="133">PAÍSES BAJOS</option>
                                    <option value="134">PAKISTÁN</option>
                                    <option value="135">PALAOS</option>
                                    <option value="136">PANAMÁ</option>
                                    <option value="137">PAPÚA NUEVA GUINEA</option>
                                    <option value="138">PARAGUAY</option>
                                    <option value="139">PERÚ</option>
                                    <option value="140">POLONIA</option>
                                    <option value="141">PORTUGAL</option>
                                    <option value="142">REINO UNIDO</option>
                                    <option value="143">REPÚBLICA CENTROAFRICANA</option>
                                    <option value="144">REPÚBLICA CHECA</option>
                                    <option value="145">REPÚBLICA DE MACEDONIA</option>
                                    <option value="146">REPÚBLICA DEL CONGO</option>
                                    <option value="147">REPÚBLICA DEMOCRÁTICA DEL CONGO</option>
                                    <option value="148">REPÚBLICA DOMINICANA</option>
                                    <option value="149">REPÚBLICA SUDAFRICANA</option>
                                    <option value="150">RUANDA</option>
                                    <option value="151">RUMANÍA</option>
                                    <option value="152">RUSIA</option>
                                    <option value="153">SAMOA</option>
                                    <option value="154">SAN CRISTÓBAL Y NIEVES</option>
                                    <option value="155">SAN MARINO</option>
                                    <option value="156">SAN VICENTE Y LAS GRANADINAS</option>
                                    <option value="157">SANTA LUCÍA</option>
                                    <option value="158">SANTO TOMÉ Y PRÍNCIPE</option>
                                    <option value="159">SENEGAL</option>
                                    <option value="160">SERBIA</option>
                                    <option value="161">SEYCHELLES</option>
                                    <option value="162">SIERRA LEONA</option>
                                    <option value="163">SINGAPUR</option>
                                    <option value="164">SIRIA</option>
                                    <option value="165">SOMALIA</option>
                                    <option value="166">SRI LANKA</option>
                                    <option value="167">SUAZILANDIA</option>
                                    <option value="169">SUDÁN</option>
                                    <option value="168">SUDÁN DEL SUR</option>
                                    <option value="170">SUECIA</option>
                                    <option value="171">SUIZA</option>
                                    <option value="172">SURINAM</option>
                                    <option value="173">TAILANDIA</option>
                                    <option value="174">TANZANIA</option>
                                    <option value="175">TAYIKISTÁN</option>
                                    <option value="176">TIMOR ORIENTAL</option>
                                    <option value="177">TOGO</option>
                                    <option value="178">TONGA</option>
                                    <option value="179">TRINIDAD Y TOBAGO</option>
                                    <option value="180">TÚNEZ</option>
                                    <option value="181">TURKMENISTÁN</option>
                                    <option value="182">TURQUÍA</option>
                                    <option value="183">TUVALU</option>
                                    <option value="184">UCRANIA</option>
                                    <option value="185">UGANDA</option>
                                    <option value="186">URUGUAY</option>
                                    <option value="187">UZBEKISTÁN</option>
                                    <option value="188">VANUATU</option>
                                    <option value="189">VENEZUELA</option>
                                    <option value="190">VIETNAM</option>
                                    <option value="191">YEMEN</option>
                                    <option value="192">YIBUTI</option>
                                    <option value="193">ZAMBIA</option>
                                    <option value="194">ZIMBABUE</option>
                                </select>
                            </div>
                            <div className='col-md-6' >
                                <label className="col-form-label-admin">Entidad Federativa</label>
                                <select className="form-select txtControl" id="entidad" value={companyData.entidad} onChange={handleChange} >
                                    <option value="6">AGUASCALIENTES</option>
                                    <option value="2">BAJA CALIFORNIA</option>
                                    <option value="10">BAJA CALIFORNIA SUR</option>
                                    <option value="86">CAMPECHE</option>
                                    <option value="83">CHIAPAS</option>
                                    <option value="3">CHIHUAHUA</option>
                                    <option value="91">CIUDAD DE MEXICO</option>
                                    <option value="58">COAHUILA DE ZARAGOZA</option>
                                    <option value="32">COLIMA</option>
                                    <option value="54">DURANGO</option>
                                    <option value="47">ESTADO DE MEXICO</option>
                                    <option value="36">GUANAJUATO</option>
                                    <option value="38">GUERRERO</option>
                                    <option value="42">HIDALGO</option>
                                    <option value="28">JALISCO</option>
                                    <option value="40">MICHOACAN DE OCAMPO</option>
                                    <option value="50">MORELOS</option>
                                    <option value="30">NAYARIT</option>
                                    <option value="60">NUEVO LEON</option>
                                    <option value="76">OAXACA</option>
                                    <option value="52">PUEBLA</option>
                                    <option value="44">QUERETARO</option>
                                    <option value="90">QUINTANA ROO</option>
                                    <option value="34">SAN LUIS POTOSI</option>
                                    <option value="18">SINALOA</option>
                                    <option value="9">SONORA</option>
                                    <option value="84">TABASCO</option>
                                    <option value="65">TAMAULIPAS</option>
                                    <option value="46">TLAXCALA</option>
                                    <option value="73">VERACRUZ DE IGNACIO DE LA LLAVE</option>
                                    <option value="88">YUCATAN</option>
                                    <option value="24">ZACATECAS</option>
                                    <option value="92">NACIONALIDAD EXTRANJERA</option>
                                </select>
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin' >
                                <label className="col-form-label-admin">Giro</label>
                                <select className="form-select txtControl" id="giro" onChange={handleChange} value={companyData.giro}>
                                    <option value="673">ABARROTE EN GENERAL</option>
                                    <option value="301">ABARROTES (TIENDA DE LA ESQUINA, MISCELANEA)</option>
                                    <option value="812">ACABADOS RESIDENCIALES Y COMERCIALES</option>
                                    <option value="579">ACARREO DE MATERIALES</option>
                                    <option value="2212">ACEITES Y GRASAS</option>
                                    <option value="103">ACTIVIDADES PECUARIAS (GANADERIA)</option>
                                    <option value="109">ACUACULTURA</option>
                                    <option value="4443">ADMINISTRACIÓN PÚBLICA ESTATAL EN GENERAL</option>
                                    <option value="453">AGENCIA DE PUBLICIDAD</option>
                                    <option value="1038">AGENCIA DE VIAJES</option>
                                    <option value="454">AGENCIAS ARRENDADORAS DE VEHICULOS</option>
                                    <option value="444">AGENCIAS DE BOLSA DE VALORES O CORREDOR DE BOLSA</option>
                                    <option value="426">AGENCIAS DE IMPORTACION Y EXPORTACION / ADUANALES</option>
                                    <option value="414">AGENCIAS DE VIAJES Y SERVICIOS TURISTICOS</option>
                                    <option value="427">AGENCIAS DE VIAJES</option>
                                    <option value="428">AGENCIAS INMOBILIARIAS (BIENES RAICES)</option>
                                    <option value="441">AGENCIAS Y DISTRIBUIDORES DE AUTOS NUEVOS</option>
                                    <option value="101">AGRICULTURA</option>
                                    <option value="4401">AGROPECUARIO</option>
                                    <option value="606">AHORROS </option>
                                    <option value="223">AHUMADORA</option>
                                    <option value="224">ALIMENTOS Y PREMEZCLAS</option>
                                    <option value="3301">ALMACENES Y BODEGAS</option>
                                    <option value="4459">ALQUILER DE AUTOMÓVILES SIN CHOFER</option>
                                    <option value="1168">ALQUILER DE EQUIPO PARA LEVANTAR,MOVER Y ACOMODAR MATERIALES</option>
                                    <option value="4408">ALQUILER DE MESAS,SILLAS,VAJILLAS Y SIMILARES</option>
                                    <option value="1132">ALQUILER DE OFICINAS Y LOCALES COMERCIALES</option>
                                    <option value="4442">ALQUILER DE OTROS INMUEBLES</option>
                                    <option value="4491">ALQUILER DE VIVIENDAS AMUEBLADAS</option>
                                    <option value="413">ARRENDATARIO DE INMUEBLES</option>
                                    <option value="1044">ARTICULOS DEPORTIVOS</option>
                                    <option value="1081">ARTISTAS  ESCRITORES Y TECNICOS INDEPENDIENTES</option>
                                    <option value="1022">ASADERO</option>
                                    <option value="814">ASESORIA</option>
                                    <option value="1100">ASILOS Y OTRAS RESIDENCIAS DEL SECTOR PRIVADO PARA EL CUIDADO DE ANCIANOS</option>
                                    <option value="875">ASISTENCIA SOCIAL CON REMUNERACION</option>
                                    <option value="233">ASTILLEROS Y VARADEROS</option>
                                    <option value="455">AUTO LAVADO</option>
                                    <option value="848">AUTOSERVICIO EN GENERAL</option>
                                    <option value="419">AUTOTRANSPORTE DE CARGA</option>
                                    <option value="4436">AUTOTRANSPORTE FORANEO CON REFRIGERACION</option>
                                    <option value="4415">AUTOTRANSPORTE LOCAL DE MATERIALES PARA LA CONSTRUCCIÓN CUYO RADIO DE ACCIÓN SE LIMITA AL ÁREA METROPOLITANA, MUNICIPIO O LOCALIDAD QUE OPERAN</option>
                                    <option value="1155">AUTOTRANSPORTE LOCAL DE PRODUCTOS AGROPECUARIOS SIN REFRIGERACION</option>
                                    <option value="456">AVIACION</option>
                                    <option value="108">AVICULTURA</option>
                                    <option value="891">BARBERIA</option>
                                    <option value="595">BLOQUERA</option>
                                    <option value="836">BOLERIA</option>
                                    <option value="834">BOMBERO</option>
                                    <option value="995">BOTANICA</option>
                                    <option value="443">BUFETES JURIDICOS</option>
                                    <option value="446">BUFETES O DESPACHOS CONTABLES</option>
                                    <option value="659">C/V DE ELOTES Y PLATANOS PREPARADOS</option>
                                    <option value="594">C/V MATERIALES P/CONSTRUCCION</option>
                                    <option value="688">C/VENTA DE TELAS</option>
                                    <option value="4489">CABAÑAS, VILLAS Y SIMILARES</option>
                                    <option value="1019">CAFETERIA</option>
                                    <option value="1087">CAFETERIAS FUENTES DE SODAS NEVERIAS REFRESQUERIAS Y SIMILARES</option>
                                    <option value="435">CAJAS DE AHORRO</option>
                                    <option value="853">CANTO</option>
                                    <option value="321">CARNICERIA</option>
                                    <option value="430">CASAS DE CAMBIO</option>
                                    <option value="432">CASAS DE EMPEÑO</option>
                                    <option value="816">CASETAS TELEFONICAS Y FUENTE DE SODAS</option>
                                    <option value="136">CASINOS, SALAS DE JUEGO, LOTERIA Y OTROS JUEGOS DE AZAR Y APUESTA</option>
                                    <option value="1004">CENADURIA</option>
                                    <option value="4482">CENTRO DE ACONDICIONAMIENTO FÍSICO PERTENECIENTE AL SECTOR PRÍVADO</option>
                                    <option value="1045">CENTRO DE RECREACION</option>
                                    <option value="4403">CENTRO DE TRABAJO</option>
                                    <option value="3302">CENTROS DE RECEPCION Y COMERCIALIZACION</option>
                                    <option value="330">CENTROS FOTOGRAFICOS (REVELADO/ IMPRESION Y VENTA DE EQUIPOS)</option>
                                    <option value="1175">CENTROS GENERALES DE ALQUILER</option>
                                    <option value="425">CENTROS NOCTURNOS, BARES</option>
                                    <option value="415">CENTROS RECREATIVOS/ ENTRETENIMIENTO (BALNEARIOS, PARQUES DE DIVERSIONES)</option>
                                    <option value="525">CERRAJERIA</option>
                                    <option value="2201">CERTIFICACION DE SEMILLAS</option>
                                    <option value="107">CHATARRERA</option>
                                    <option value="825">CLINICA DE BELLEZA</option>
                                    <option value="1056">CLINICAS Y SERVICIOS DE SALUD, CONSULTORIO MEDICO</option>
                                    <option value="1023">COLISIONES Y ACCESORIOS AUTOMOTRIZ</option>
                                    <option value="4481">COLOCACIÓN DE MUROS FALSOS Y AISLAMIENTO</option>
                                    <option value="1142">COLOCACION DE PISOS FLEXIBLES (COMO ALFOMBRAS, LINÓLEOS Y VINILOS) Y PISOS DE MADERA (PARQUET, DUELA Y TABLON COLONIAL)</option>
                                    <option value="300">COMERCIALIZACION</option>
                                    <option value="801">COMERCIALIZACION DE HORTALIZAS</option>
                                    <option value="333">COMERCIALIZACION DE INSTRUMENTOS MUSICALES</option>
                                    <option value="707">COMERCIALIZACION DE MALLAS Y REDES</option>
                                    <option value="318">COMERCIALIZACION Y DISTRIBUCION DE PRODUCTOS  DIVERSOS NO AGROINDUSTRIALES</option>
                                    <option value="501">COMERCIO</option>
                                    <option value="1116">COMERCIO AL POR MAYOR DE ABARROTES</option>
                                    <option value="1164">Comercio al por mayor de articulos de papeleria para uso escolar y de oficina</option>
                                    <option value="4427">COMERCIO AL POR MAYOR DE BOTANAS Y FRITURAS, COMO PAPAS FRITAS</option>
                                    <option value="1167">COMERCIO AL POR MAYOR DE CARNES ROJAS</option>
                                    <option value="4500">COMERCIO AL POR MAYOR DE CEMENTO, TABIQUE Y GRAVA</option>
                                    <option value="4448">COMERCIO AL POR MAYOR DE ELECTRODOMESTICOS MENORES Y APARATOS DE LINEA BLANCA</option>
                                    <option value="1095">COMERCIO AL POR MAYOR DE ENVASES EN GENERAL  PAPEL Y CARTON PARA LA INDUSTRIA</option>
                                    <option value="1111">COMERCIO AL POR MAYOR DE EQUIPO Y MATERIAL ELECTRICO</option>
                                    <option value="1169">COMERCIO AL POR MAYOR DE FERTILIZANTES,PLAGUICIDAS Y SEMILLAS PARA SIEMBRA</option>
                                    <option value="1074">comercio al por mayor de fr</option>
                                    <option value="1073">COMERCIO AL POR MAYOR DE FRUTAS Y VERDURAS FESCAS</option>
                                    <option value="1065">COMERCIO AL POR MAYOR DE MAQUINARIA Y EQUIPO AGROPECUARIO  FORESTAL Y PARA LA PESCA</option>
                                    <option value="4493">COMERCIO AL POR MAYOR DE MAQUINARIA Y EQUIPO PARA LA INDUSTRIA MANUFACTURERA</option>
                                    <option value="4503">COMERCIO AL POR MAYOR DE MATERIALES METALICOS</option>
                                    <option value="1112">COMERCIO AL POR MAYOR DE MATERIAS PRIMAS PARA REPOSTERIA</option>
                                    <option value="4441">COMERCIO AL POR MAYOR DE MEDICAMENTOS VETERINARIOS Y ALIMENTOS PARA ANIMALES.</option>
                                    <option value="1176">COMERCIO AL POR MAYOR DE MOBILIARIO , EQUIPO E INSTRUMENTAL MEDICO Y DE LABORATORIO</option>
                                    <option value="4444">COMERCIO AL POR MAYOR DE OTRA MAQUINARIA Y EQUIPO DE USO GENERAL</option>
                                    <option value="4460">COMERCIO AL POR MAYOR DE OTRAS MATERIAS PRIMAS PARA OTRAS INDUSTRIAS</option>
                                    <option value="4440">COMERCIO AL POR MAYOR DE OTROS MATERIALES PARA LA CONSTRUCCIÓN, EXCEPTO DE MADERA</option>
                                    <option value="4504">COMERCIO AL POR MAYOR DE PRODUCTOS FARMACEUTICOS</option>
                                    <option value="1118">COMERCIO AL POR MAYOR DE PRODUCTOS QUIMICOS PARA USO INDUSTRIAL</option>
                                    <option value="4457">COMERCIO AL POR MAYOR DE SEMILLAS Y GRANOS ALIMEN, FRUTAS SECAS,CHILES SECOS Y ESPECIAS</option>
                                    <option value="302">COMERCIO AL POR MENOR  DE ROPA Y CALZADO EN ESTABLECIMIENTO </option>
                                    <option value="1120">COMERCIO AL POR MENOR BISUTERIA Y ACCESORIOS DE VESTIR. COMO PAÑOLETAS, MASCADAS, ETC</option>
                                    <option value="4454">COMERCIO AL POR MENOR CALZADO, AGUJETAS, TINTAS, PLANTILLAS, ACCESORIOS DEL CALZADO</option>
                                    <option value="4406">COMERCIO AL POR MENOR DE ACEITES Y GRASAS LUBRICANTES DE USO INDUSTRIAL, ADITIVOS Y SIMILARES PARA VEHICULOS DE MOTOR</option>
                                    <option value="4438">COMERCIO AL POR MENOR DE AGUA PURIFICADA Y HIELO</option>
                                    <option value="1098">COMERCIO AL POR MENOR DE APARATOS ORTOPEDICOS</option>
                                    <option value="4495">COMERCIO AL POR MENOR DE ARTESANIAS</option>
                                    <option value="4420">COMERCIO AL POR MENOR DE ARTICULOS DE PERFUMERIA Y COSMETICOS</option>
                                    <option value="1066">COMERCIO AL POR MENOR DE ARTICULOS MISCELANEOS NO CLASIFICADOS EN OTRA PARTE</option>
                                    <option value="1139">COMERCIO AL POR MENOR DE ARTICULOS PARA LA LIMPIEZA</option>
                                    <option value="1141">COMERCIO AL POR MENOR DE ARTÍCULOS USADOS DE MUEBLES, ENSERES DOMÉSTICOS, LIBROS, REVISTAS, ROPA, CALZADO, JUGUETES</option>
                                    <option value="4486">COMERCIO AL POR MENOR DE BLANCOS</option>
                                    <option value="4432">COMERCIO AL POR MENOR DE CARNES ROJAS</option>
                                    <option value="4421">Comercio al por menor de computadoras y sus accesorios</option>
                                    <option value="1156">COMERCIO AL POR MENOR DE CRISTALERIA Y SUS PIEZAS SUELTAS, LOZA Y UTENSILIOS DE COCINA DE CERAMICA Y PASTICO</option>
                                    <option value="1109">COMERCIO AL POR MENOR DE DISCOS,DISCOS COMPACTOS,CASETES,CARTUCHOS,CINTAS DE AUDIO Y VIDEO</option>
                                    <option value="317">COMERCIO AL POR MENOR DE ENSERES DOMESTICOS/ COMPUTADORAS Y</option>
                                    <option value="4410">COMERCIO AL POR MENOR DE FRUTAS Y VERDURAS FRESCAS</option>
                                    <option value="1093">COMERCIO AL POR MENOR DE LECHE PROCESADA, OTROS PRODUCTOS LACTEOS Y EMBUTIDOS</option>
                                    <option value="4449">COMERCIO AL POR MENOR DE LENTES</option>
                                    <option value="1106">COMERCIO AL POR MENOR DE LLANTAS Y CAMARAS PARA AUTOMOVILES CAMIONETAS Y CAMIONES</option>
                                    <option value="1104">COMERCIO AL POR MENOR DE LLANTAS Y CAMARAS, CORBATAS, VALVULAS DE CAMARA Y TAPONES PARA AUTOMOVILES, CAMIONES Y CAMIONES DE MOTOR</option>
                                    <option value="1135">COMERCIO AL POR MENOR DE MASCOTAS,MEDICAMENTOS,ACCESORIOS Y OTROS PRODUCTOS</option>
                                    <option value="1102">COMERCIO AL POR MENOR DE MATERIAS PRIMAS PARA REPOSTERIA</option>
                                    <option value="1138">COMERCIO AL POR MENOR DE MUEBLES PARA EL HOGAR</option>
                                    <option value="1060">COMERCIO AL POR MENOR DE OTROS ALIMENTOS PREPARADOS PARA CONSUMO</option>
                                    <option value="4497">COMERCIO AL POR MENOR DE PARTES Y REFACCIONES NUEVAS PARA AUTOMOVILES, CAMIONETAS Y CAMIONES</option>
                                    <option value="1068">COMERCIO AL POR MENOR DE PESCADOS Y MARISCOS</option>
                                    <option value="1110">COMERCIO AL POR MENOR DE PINTURA , RECUBR,BARNICES,BROCHAS,MATERIALES Y ACCESORIOS PARA PINTURA NO ART</option>
                                    <option value="4501">COMERCIO AL POR MENOR DE PLANTAS Y FLORES NATURALES, ARREGLOS FLORALES Y FRUTALES, CORONAS FUNERARIAS, NATURALEZA MUERTA</option>
                                    <option value="4455">COMERCIO AL POR MENOR DE PRODUCTOS DE CONSUMO FINAL PROPIEDAD DE TERCEROS POR COMISIÓN Y CONSIGNACIÓN</option>
                                    <option value="1148">COMERCIO AL POR MENOR DE PRODUCTOS NATURISTAS Y DE COMPLEMENTOS ALIMENTICIOS SIN INCLUIR BOTANAS, PRODUCTOS DE CONFITERÍA, CHOCOLATES Y DEMÁS PRODUCTO</option>
                                    <option value="315">COMERCIO AL POR MENOR DE PRODUCTOS QUIMICOS Y DE LIMPIEZA</option>
                                    <option value="4414">COMERCIO AL POR MENOR DE ROPA NUEVA, DE TRAJES REGIONALES, DISFRACES, PIELES FINAS, VESTIDOS PARA NOVIA, UNIFORMES ESCOLARES, NO CONFECCIONADOS CON CU</option>
                                    <option value="4411">COMERCIO AL POR MENOR DE TELEFONOS DE OTROS APARATOS DE COMUNICACION REFACCIONES Y ACCESORIOS</option>
                                    <option value="4412">COMERCIO AL POR MENOR DE VIDRIOS Y ESPEJOS CANCELERIA DE ALUMUNIO,DOMOS DE MATERIAL ACRILICO Y POLICARBONATO, TRAGALUCES DE VIDRIO</option>
                                    <option value="1072">COMERCIO AL POR MENOR EN FERRETERIAS Y TLAPALERIAS</option>
                                    <option value="1080">COMERCIO AL POR MENOR EN TIENDAS DE ABARROTES  ULTRAMARINOS Y MISCELANEAS</option>
                                    <option value="1097">COMERCIO AL POR MENOR EN TIENDAS DE ARTESANIAS</option>
                                    <option value="326">COMERCIO AMBULANTE DE MERCANCIAS DIVERSAS (EXCEPTO ALIMENTOS)</option>
                                    <option value="4419">COMERCIO DE ALIMENTOS BASICOS CON ALTA DENSIDAD CALORICA</option>
                                    <option value="207">COMERCIO Y DISTRIBUCION DE OBRAS DE ARTE Y ANTIGUEDADES.</option>
                                    <option value="331">COMPRA  VENTA DE GANADO </option>
                                    <option value="334">COMPRA - VENTA DE AUTOMOVILES USADOS</option>
                                    <option value="311">COMPRA - VENTA DE SALDOS Y ARTICULOS DE SEGUNDA MANO</option>
                                    <option value="528">COMPRA VENTA  DE PRODUCTOS NATURISTAS</option>
                                    <option value="593">COMPRA VENTA DE ACCESORIOS PARA AUTOS</option>
                                    <option value="851">COMPRA VENTA DE CARNE Y POLLO</option>
                                    <option value="9">COMPRA VENTA DE CARTON</option>
                                    <option value="678">COMPRA VENTA DE COSMETICOS</option>
                                    <option value="991">COMPRA VENTA DE ELECTRONICA Y DESARROLLO</option>
                                    <option value="335">COMPRA VENTA DE EQUIPOS CELULARES, RADIOS Y ACCESORIOS</option>
                                    <option value="1042">COMPRA VENTA DE GANADO</option>
                                    <option value="587">COMPRA VENTA DE JOYERIA</option>
                                    <option value="591">COMPRA VENTA DE JUGUETES</option>
                                    <option value="844">COMPRA VENTA DE LAVADORAS</option>
                                    <option value="700">COMPRA VENTA DE METALES</option>
                                    <option value="884">COMPRA VENTA DE MOTOS</option>
                                    <option value="997">COMPRA VENTA DE MUEBLES RUSTICOS</option>
                                    <option value="4474">COMPRA VENTA DE ROPA Y ARTÍCULOS DE PUBLICIDAD</option>
                                    <option value="336">COMPRA VENTA DE TELAS Y RETAZOS</option>
                                    <option value="993">COMPRA VENTA,REPARACION DE E. DE COMPUTO</option>
                                    <option value="1032">COMPRA Y VENTA DE MUEBLES NUEVOS Y USADO</option>
                                    <option value="1031">COMPRA Y VENTA DE MUEBLES USADOS</option>
                                    <option value="500">COMPRA-VENTA DE ARTICULOS DE SEGUNDA.</option>
                                    <option value="660">COMPRA-VENTA DE CALZADO</option>
                                    <option value="337">COMPRA-VENTA DE CARNES Y DERIVADOS POR MAYOREO</option>
                                    <option value="1048">COMPRA-VENTA DE FERTILIZANTES Y PLAGUICIDAS</option>
                                    <option value="313">COMPRA-VENTA DE FRUTA Y VERDURA</option>
                                    <option value="681">COMPRA-VENTA DE MUEBLES METALICOS</option>
                                    <option value="338">COMPRA-VENTA DE PESCADO Y MARISCOS POR MAYOREO</option>
                                    <option value="503">COMPRA-VENTA DE ROPA EN PEQUEÑO</option>
                                    <option value="1115">CONFECCION DE ROPA SOBRE MEDIDA</option>
                                    <option value="3303">CONGELACION</option>
                                    <option value="4450">CONSERVACION DE FRUTAS Y VERDURAS POR PROCESOS DISTINTOS A LA CONGELACION Y LA DESHIDRATACION. INCLUYE ELABORACION DE JUGOS.</option>
                                    <option value="1146">CONSERVACION DE GUISOS POR PROCESOS DISTINTOS A LA CONGELACION</option>
                                    <option value="2202">CONSERVAS Y PURES</option>
                                    <option value="1137">CONSTRUCC DE CARRETERAS,AUTOPISTAS,TERRACERIAS,PUENTES,PASOS A DESNIVEL Y AEROPISTAS</option>
                                    <option value="13">CONSTRUCCION DE INMUEBLES</option>
                                    <option value="4431">CONSTRUCCION DE INMUEBLES COMERCIALES, INSTITUCIONALES Y DE SERVICIO</option>
                                    <option value="4483">CONSTRUCCIÓN DE NAVES Y PLANTAS INDUSTRIALES </option>
                                    <option value="1159">Construccion de Obras de Generacion y Conduccion de Energia Electrica</option>
                                    <option value="813">CONSTRUCCION DE PALAPA</option>
                                    <option value="4471">CONSTRUCCIÓN DE VIVIENDA UNIFAMILIAR</option>
                                    <option value="212">CONSTRUCCION Y EDIFICACION</option>
                                    <option value="819">CONSTRUCTORA</option>
                                    <option value="876">CONSULTORA MARY KAY</option>
                                    <option value="543">CONSULTORIA</option>
                                    <option value="706">CONSULTORIO DENTAL</option>
                                    <option value="4468">CONSULTORIOS DENTALES DEL SECTOR PRIVADO QUE CUENTEN CON TITULO DE MÉDICO CONFORME A LAS LEYES</option>
                                    <option value="4418">CONSUMO DE ALIMENTOS BASICOS CON ALTA DENSIDAD CALORICA</option>
                                    <option value="550">CONTADOR PUBLICO </option>
                                    <option value="810">COOPERATIVA ESCOLAR</option>
                                    <option value="860">COORDINADOR DE EVENTOS</option>
                                    <option value="445">CORREDORES PUBLICOS</option>
                                    <option value="513">CORTE Y CONFECCION </option>
                                    <option value="4496">CORTE Y PULIDO, FUERA DE LA MINA, DE OTRAS PIEDRAS DE CANTERA, COMO EL GRANITO, LA CANTERA Y EL ONIX, EXCEPTO MARMOL</option>
                                    <option value="225">CORTES FINOS</option>
                                    <option value="4417">CREACIONES Y DIFUSION DE CONTENIDO EXCLUSIVAMENTE A TRAVES DE INTERNET</option>
                                    <option value="4463">CRÍA Y ENGORDA DE GALLINAS PONEDORAS DE HUEVO PARA CONSUMO</option>
                                    <option value="1166">CRIA Y ENGORDA DE VACAS , RESES O NOVILLOS PARA LA VENTA DE RESES</option>
                                    <option value="1099">CRIA Y EXPLOTACION DE OTROS ANIMALES</option>
                                    <option value="4472">CRIA,FOMENTO Y CUIDADO DE CAMARONES</option>
                                    <option value="4404">CUENTA SIMPLIFICADA</option>
                                    <option value="787">CUIDADO DE NIÑOS EN ESTANCIA</option>
                                    <option value="1083">CULTIVO DE CEBOLLA</option>
                                    <option value="712">CULTURA DE BELLEZA</option>
                                    <option value="607">CUNDINAS</option>
                                    <option value="887">CURIOS</option>
                                    <option value="226">CURTIDO DE PIELES</option>
                                    <option value="458">CYBERCAFES</option>
                                    <option value="835">DECORACION</option>
                                    <option value="227">DERIVADOS LACTEOS</option>
                                    <option value="1017">DESARROLLO DE SOFTWARE Y SERVICIOS COMP.</option>
                                    <option value="2217">DESHIDRATACION Y SECADO</option>
                                    <option value="2203">DESPEPITE, DESFIBRADO O DESBORRADO</option>
                                    <option value="4435">DIRECCIÓN DE CORPORATIVOS Y EMPRESAS NO FINANCIERAS</option>
                                    <option value="852">DISEÑO DE INTERIORES</option>
                                    <option value="1008">DISEÑO GRAFICO</option>
                                    <option value="1140">DISEÑO Y DECORACIÓN DE INTERIORES</option>
                                    <option value="786">DIST. INDEPENDIENTE DE PRODUCTOS ARDYSS</option>
                                    <option value="537">DISTRIBUCION DE BOLSAS DE PLASTICO</option>
                                    <option value="4434">DISTRIBUCION DE MATERIAL PUBLICITARIO</option>
                                    <option value="716">DISTRIBUCION DE PRODUCTOS QUIMICOS</option>
                                    <option value="576">DISTRIBUCION Y PREPARACION DE GELATINAS</option>
                                    <option value="1028">DISTRIBUIDOR</option>
                                    <option value="714">DISTRIBUIDOR DE AGUA PURIFICADA</option>
                                    <option value="889">DISTRIBUIDOR DE ARTICULOS DE LIMPIEZA</option>
                                    <option value="531">DISTRIBUIDOR DE MARISCOS</option>
                                    <option value="541">DISTRIBUIDORA DE REFACCIONES Y AUTOPARTE</option>
                                    <option value="319">DULCERIAS, SEMILLAS, ESPECIAS Y DESECHABLES</option>
                                    <option value="2218">DULCES</option>
                                    <option value="239">EDICION, PRODUCCION Y DISTRIBUCION DE REVISTAS Y PERIODICOS (CASAS EDITORIALES DE PERIODICOS Y REVISTAS)</option>
                                    <option value="994">ELABORACION ANUNCIOS PUBLICITARIOS</option>
                                    <option value="1047">ELABORACION DE ALIMENTOS</option>
                                    <option value="1119">ELABORACION DE ALIMENTOS FRESCOS PARA CONSUMO INMEDIATO</option>
                                    <option value="4490">ELABORACIÓN DE GALLETAS, PASTAS PARA SOPA Y HARINAS PREMEZCLADAS</option>
                                    <option value="1126">ELABORACIÓN DE HARINA DE OTROS PRODUCTOS AGRÍCOLAS</option>
                                    <option value="1172">ELABORACION DE HELADOS Y PALETAS</option>
                                    <option value="4405">ELABORACION DE OTROS ALIMENTOS</option>
                                    <option value="683">ELABORACION DE PIÑATAS</option>
                                    <option value="1158">Elaboracion de Tortillas de Maiz y Molienda de Nixtamal</option>
                                    <option value="517">ELABORACION DE TORTILLAS HARINA - MAIZ </option>
                                    <option value="868">ELABORACION Y ENTA DE DONAS</option>
                                    <option value="828">ELABORACION Y VENTA DE BANQUETES Y TACOS</option>
                                    <option value="661">ELABORACION Y VENTA DE BURRITOS Y TORTAS</option>
                                    <option value="824">ELABORACION Y VENTA DE CARNE DE MACHACA</option>
                                    <option value="791">ELABORACION Y VENTA DE CHURROS</option>
                                    <option value="502">ELABORACION Y VENTA DE COMIDA </option>
                                    <option value="1037">ELABORACION Y VENTA DE COYOTAS</option>
                                    <option value="551">ELABORACION Y VENTA DE DULCE</option>
                                    <option value="566">ELABORACION Y VENTA DE ELOTES</option>
                                    <option value="552">ELABORACION Y VENTA DE HOT-DOGS</option>
                                    <option value="846">ELABORACION Y VENTA DE JUGOS NATURALES</option>
                                    <option value="998">ELABORACION Y VENTA DE JUGOS NATURALES</option>
                                    <option value="240">ELABORACION Y VENTA DE MANUALIDADES Y ARTESANIAS</option>
                                    <option value="511">ELABORACION Y VENTA DE MARISCOS</option>
                                    <option value="690">ELABORACION Y VENTA DE MIEL DE ABEJA</option>
                                    <option value="788">ELABORACION Y VENTA DE PAN</option>
                                    <option value="667">ELABORACION Y VENTA DE POLLOS ASADOS</option>
                                    <option value="216">ELABORACION Y VENTA DE PRODUCTOS LACTEOS (LECHE, YOGURTH, QUESOS)</option>
                                    <option value="882">ELABORACION Y VENTA DE QUESOS</option>
                                    <option value="793">ELABORACION Y VENTA DE QUIMICOS DE LIMPI</option>
                                    <option value="840">ELABORACION Y VENTA DE RASPADOS Y SNACK</option>
                                    <option value="783">ELABORACION Y VENTA DE REPOSTERIA</option>
                                    <option value="505">ELABORACION Y VENTA DE TAMALES </option>
                                    <option value="772">ELABORACION Y VENTA DE TEJUINO</option>
                                    <option value="855">ELABORACION Y VTA DE ARTICULOS PUBLICITA</option>
                                    <option value="774">ELABORACION Y VTA. DE BEBIDAS REFRESCANT</option>
                                    <option value="504">ELEABORACION  Y VENTA DE YOGURT</option>
                                    <option value="856">ELECTRICISTA</option>
                                    <option value="228">EMBUTIDOS Y CARNES FRIAS</option>
                                    <option value="439">EMISION Y COMERCIALIZACION DE TARJETAS PRE PAGADAS, CUPONES, CHEQUES DE VIAJERO, ETC. POR ENTIDADES NO FINANCIERAS.</option>
                                    <option value="438">EMPRESAS DE TRASLADOS DE VALORES, EXCEPTO DONDE INTERVENGA BANCO DE MEXICO E INSTITUCIONES DEDICADAS AL DEPOSITO DE VALORES.</option>
                                    <option value="1179">ENAJENACION DE ARENA, GRAVA ,PIEDRA,TIERRA Y OTROS BIENES MUEBLES PROVENIENTES DEL SUELO</option>
                                    <option value="3304">ENFRIAMIENTO Y REFRIGERACION</option>
                                    <option value="2220">ENLATADORAS Y ENVASADORAS</option>
                                    <option value="4492">EQUIPOS DEPORTIVOS PROFESIONALES Y SEMIPROFESIONALES</option>
                                    <option value="1016">ESCUELA DE NATACION</option>
                                    <option value="4502">ESCUELAS DE EDUCACIÓN PREESCOLAR PERTENECIENTES DEL SECTOR PRIVADO</option>
                                    <option value="4494">ESCUELAS DEL SECTOR PRIVADO DEDICADAS A LA ENSEÑANZA DE OFICIOS, QUE TENGAN AUTORIZACION O RECONOCIMIENTO DE VALIDEZ OFICIAL DE ESTUDIOS,</option>
                                    <option value="666">ESCUELAS E INSTITUTOS</option>
                                    <option value="339">ESTABLECIMIENTO VENTA DE UNIFORMES</option>
                                    <option value="583">ESTILISTA </option>
                                    <option value="1011">EXPENDIO BOTANERO</option>
                                    <option value="3305">EXPENDIOS Y LOCALES</option>
                                    <option value="1041">FABRICA DE HELADOS</option>
                                    <option value="221">FABRICA DE HIELO</option>
                                    <option value="1075">FABRICACION DE ARTICULOS DEPORTIVOS</option>
                                    <option value="10">FABRICACION DE CERAMICA</option>
                                    <option value="1107">FABRICACIÓN DE DESBASTES PRIMARIOS Y FERROALEACIONES</option>
                                    <option value="234">FABRICACION DE EMBALAJES Y ENVASES</option>
                                    <option value="1043">FABRICACION DE EQUIPO PARA TORTILLERIAS</option>
                                    <option value="1084">FABRICACION DE ESTRUCTURAS METALICAS</option>
                                    <option value="4461">FABRICACIÓN DE INSTRUMENTOS Y APARATOS ÓPTICOS DE USO OFTÁLMICO</option>
                                    <option value="222">FABRICACION DE JABON</option>
                                    <option value="4462">FABRICACIÓN DE JOYERÍA DE FANTASÍA HECHA DE METALES Y PIEDRAS NO PRECIOSAS, DE PLÁSTICO Y DE OTROS MATERIALES</option>
                                    <option value="866">FABRICACION DE MANUALIDADES</option>
                                    <option value="4424">FABRICACIÓN DE OTRA MAQUINARIA Y EQUIPO PARA LA INDUSTRIA EN GENERAL</option>
                                    <option value="1067">FABRICACION DE OTROS PRODUCTOS DE CEMENTO Y CONCRETO</option>
                                    <option value="4445">FABRICACIÓN DE OTROS PRODUCTOS DE HIERRO Y ACERO DE MATERIAL COMPRADO</option>
                                    <option value="1082">FABRICACION DE OTROS PRODUCTOS DE MADERA</option>
                                    <option value="586">FABRICACION DE PLASTICOS</option>
                                    <option value="4433">FABRICACION DE PRODUCTOS DE MADERA PARA LA CONSTRUCCION COMO PUERTAS VENTANAS</option>
                                    <option value="1170">FABRICACION DE PRODUCTOS METALICOS FORJADOS Y TROQUELADOS</option>
                                    <option value="867">FABRICACION DE QUESOS Y NATAS</option>
                                    <option value="5">FABRICACION Y VENTA DE MUEBLES</option>
                                    <option value="1130">FABRICACIÓN Y VENTA DE VAINILLA Y AZUCAR GLASS</option>
                                    <option value="789">FABRICACION Y VENTA DE VIDROS Y VENTANAS</option>
                                    <option value="799">FARMACIA</option>
                                    <option value="324">FARMACIA E INSUMOS HOSPITALARIOS</option>
                                    <option value="2204">FERMENTACION</option>
                                    <option value="523">FERRETERIA</option>
                                    <option value="581">FIBRAS Y PLASTICOS </option>
                                    <option value="323">FLORERIA PLANTAS DE ORNATO Y ARREGLOS FLORALES</option>
                                    <option value="557">FLORISTA</option>
                                    <option value="459">FONDAS / CENADURIAS</option>
                                    <option value="2205">FORMULACION DE AGROQUIMICOS</option>
                                    <option value="2206">FORMULACION DE FERTILIZANTES</option>
                                    <option value="592">FUENTE DE SODAS</option>
                                    <option value="410">FUENTE DE SODAS PALETERIA/ HELADOS Y JUGOS NATURALES</option>
                                    <option value="710">FUMIGACION</option>
                                    <option value="1015">FUNDIDORA</option>
                                    <option value="1171">GENERACION TRANSMISION Y DISTRIBUCION DE ENERGIA ELECTRICA</option>
                                    <option value="821">GIMNASIO</option>
                                    <option value="460">GIMNASIOS</option>
                                    <option value="861">GRABACION DE EVENTOS EN VIDEO</option>
                                    <option value="704">GUARDERIA</option>
                                    <option value="420">GUARDERIA Y ESTANCIAS INFANTILES.</option>
                                    <option value="1036">GUARDIA DE SEGURIDAD</option>
                                    <option value="603">HERENCIA</option>
                                    <option value="560">HERRERIA</option>
                                    <option value="214">HIELERA</option>
                                    <option value="4453">HOJALATERIA Y PINTURA DE AUTOMOVILES Y CAMIONES</option>
                                    <option value="424">HOTELES</option>
                                    <option value="4488">HOTELES CON OTROS SERVICIOS INTEGRADOS</option>
                                    <option value="218">IMPRENTA</option>
                                    <option value="1108">IMPRESIÓN DE FORMAS CONTINUAS Y OTROS IMPRESOS</option>
                                    <option value="229">INCUBACION</option>
                                    <option value="205">IND  AGROALIMENTARIA (ALIMENTOS PROCESADOS)</option>
                                    <option value="563">INDUSTRIA</option>
                                    <option value="204">INDUSTRIA QUIMICA</option>
                                    <option value="200">INDUSTRIALIZACION</option>
                                    <option value="886">INSTALACION DE EQ. Y SISTEMA SATELITAL</option>
                                    <option value="461">INSTALACION DE FUENTES DE ENERGIA ALTERNATIVAS</option>
                                    <option value="873">INSTALACIONES  ELECTRICAS</option>
                                    <option value="4487">INSTALACIONES ELECTRICAS EN CONSTRUCCIONES</option>
                                    <option value="4437">INSTALACIONES HIDROSANITARIAS Y DE GAS EN CONSTRUCCIONES</option>
                                    <option value="780">INSTRUCTOR DE AEROBICS</option>
                                    <option value="800">INSTRUCTOR DE ARTES MARCIALES</option>
                                    <option value="862">INSTRUCTOR DE BUCEO</option>
                                    <option value="1105">INTALACIONES ELECTRICAS EN CONSTRUCCIONES</option>
                                    <option value="689">INTERESES</option>
                                    <option value="437">INTERMEDIARIOS DE VENTA DE VALES, PAGARES Y OTROS SERVICIOS DE PRESTAMOS DE EFECTIVO (PRESTAMISTAS, AGIOTISTAS).</option>
                                    <option value="1034">JARDIN DE NIÑOS</option>
                                    <option value="1040">JARDINERIA</option>
                                    <option value="571">JOYERIA Y RELOJERIA</option>
                                    <option value="449">JOYERIAS</option>
                                    <option value="605">JUBILACION</option>
                                    <option value="892">JUEGOS DE AZAR</option>
                                    <option value="2207">JUGOS Y NÉCTARES</option>
                                    <option value="340">JUGUETERIA</option>
                                    <option value="1062">LABORATORIOS MEDICOS Y DIAGNOSTICOS PERTENECIENTES AL SECTOR PRIVADO</option>
                                    <option value="1005">LADRILLERO</option>
                                    <option value="869">LAVADO DE AUTOS</option>
                                    <option value="1091">LAVADO Y LUBRICADO DE AUTOMOVILES Y CAMIONES</option>
                                    <option value="412">LAVANDERIA, PLANCHADURIAS Y TINTORERIAS</option>
                                    <option value="879">LIMPIEZA DE FOSAS SEPTICAS</option>
                                    <option value="341">LLANTERA</option>
                                    <option value="197">LONCHERIA, TAQUERIA Y TORTERIA</option>
                                    <option value="1162">Manejo de Desechos No Peligrosos y Servicios De Remediacion a Zonas Dañadas por Desechos No Peligrosos</option>
                                    <option value="985">MANTENIMIENTO CASCOS DE BOTES</option>
                                    <option value="811">MANTENIMIENTO Y SERVICIO DE A/A</option>
                                    <option value="1000">MAQUILADO EN PINTURA DE LENTES</option>
                                    <option value="220">MAQUILADORAS, INDUSTRIAS TEXTILES, EMPRESAS DE MANUFACTURA O ELABORACION DE BEBIDAS O ALIMENTOS.</option>
                                    <option value="235">MAQUINARIA E IMPLEMENTOS</option>
                                    <option value="1046">MAQUINARIA EQUIPO E IMPLEMENTOS PARA LA AGRICULTUR</option>
                                    <option value="888">MARMOLERIA</option>
                                    <option value="877">MASAJISTA</option>
                                    <option value="325">MATERIALES PARA LA CONSTRUCCION, FERRETERIA/TLAPALERIA</option>
                                    <option value="883">MEDICINA GENERAL</option>
                                    <option value="241">MINERIA</option>
                                    <option value="1122">MINERÍA DE ARENA Y GRAVA PARA LA CONSTRUCCIÓN</option>
                                    <option value="342">MINISUPER (ABARROTES, SALCHICHONERIA, CARNICERIA, FRUTAS Y VERDURAS) </option>
                                    <option value="721">MISCELANEA</option>
                                    <option value="506">MISCELANEA EN GENERAL, VENTA DE DULCES</option>
                                    <option value="2208">MOLIENDA</option>
                                    <option value="328">MUEBLERIA, ELECTRONICA, ENSERES DOMESTICOS Y LINEA BLANCA</option>
                                    <option value="715">MUSICO</option>
                                    <option value="1001">NEGOCIO DE NIEVE</option>
                                    <option value="601">NEGOCIO PROPIO</option>
                                    <option value="516">NEVERIA Y PALETERIA</option>
                                    <option value="442">NOTARIOS PUBLICOS.</option>
                                    <option value="679">OPTICAS</option>
                                    <option value="416">ORGANIZACION DE ESPECTACULOS Y PROMOCION DE EVENTOS MASIVOS</option>
                                    <option value="448">ORGANIZACIONES RECEPTORAS DE DONATIVOS (SOCIEDADES Y ASOCIACIONES CIVILES).</option>
                                    <option value="1125">OTRAS CONSTRUCCIONES DE INGENIERIA CIVIL U OBRA PESADA</option>
                                    <option value="1090">OTRAS INSTALACIONES Y EQUIPAMIENTO EN CONSTRUCIONES</option>
                                    <option value="1127">OTRAS REPARACIONES MECÁNICAS Y ELÉCTRICAS DE AUTOMÓVILES Y CAMIONES</option>
                                    <option value="238">OTRAS SUB-ACTIVIDADES</option>
                                    <option value="3309">OTRAS SUB-ACTIVIDADES</option>
                                    <option value="1153">OTRO AUTOTRANSPORTE FORANEO DE CARGA GENERAL</option>
                                    <option value="1150">OTROS AUTOTRANSPORTES LOCAL DE CARGA GEENRAL</option>
                                    <option value="1145">OTROS INTERMEDIARIOS DE COMERCIO AL POR MAYOR</option>
                                    <option value="4413">OTROS INTERMEDIARIOS DE COMERCIO AL POR MENOR</option>
                                    <option value="236">OTROS QUIMICOS</option>
                                    <option value="4429">OTROS SERVICIOS COMO MAQUINAS FOTOGRAFICAS QUE FUNCIONAN CON MONEDAS, DE CASILLEROS QUE FUNCIONAN CON MONEDAS, DE GUARDA PAQUETES</option>
                                    <option value="1133">OTROS SERVICIOS DE APOYO A NEGOCIOS</option>
                                    <option value="4473">OTROS SERVICIOS DE CONSULTORÍA Y TÉCNICA </option>
                                    <option value="4469">OTROS SERVICIOS DE LIMPIEZA</option>
                                    <option value="4465">OTROS SERVICIOS DE REPARACIÓN Y MANTENIMIENTO DE AUTOMÓVILES Y CAMIONES</option>
                                    <option value="1117">OTROS SERVICIOS RECREATIVOS PRESTADOS POR EL SECTOR PRIVADO</option>
                                    <option value="1136">OTROS SERVICIOS RELACIONADOS CON LA AGRICULTURA</option>
                                    <option value="1174">OTROS TRABAJOS DE ACABADOS EN EDIFICACIONES</option>
                                    <option value="4452">OTROS TRABAJOS EN EXTERIORES NO CLASIFICADOS EN OTRA PARTE</option>
                                    <option value="1163">OTROS TRABAJOS ESPECIALIZADOS PARA LA CONSTRUCCIÓN</option>
                                    <option value="209">PANADERIA/PASTELERIA Y POSTRES</option>
                                    <option value="1157">PANIFICACION INDUSTRIAL</option>
                                    <option value="4407">PANIFICACIÓN TRADICIONAL</option>
                                    <option value="509">PANIFICADORA</option>
                                    <option value="670">PAPELERIA</option>
                                    <option value="307">PAPELERIAS Y MERCERIAS</option>
                                    <option value="4466">PARQUES DEL SECTOR PRIVADO CON INSTALACIONES RECREATIVAS</option>
                                    <option value="230">PASTEURIZACION</option>
                                    <option value="604">PENSION </option>
                                    <option value="451">PERIODISMO INFORMATIVO.</option>
                                    <option value="4402">PESCA</option>
                                    <option value="462">PESCA DEPORTIVA</option>
                                    <option value="102">PESCA/CAZA Y CAPTURA</option>
                                    <option value="532">PESCADERIA</option>
                                    <option value="450">PILOTO (AVIADOR).</option>
                                    <option value="1006">PIPERO</option>
                                    <option value="1035">PIZZERIA</option>
                                    <option value="988">PLOMERIA</option>
                                    <option value="539">POLLERIA Y CREMERIA </option>
                                    <option value="4439">PREPARACION TEJIDO Y ANUDADO DE FIBRAS DE IXTLE, PALMA Y LECHUGUILLA</option>
                                    <option value="4470">PRESTACIÓN DE SERVICIOS DE HOSPEDAJE A TRAVÉS DE INTERNET, APLICACIONES INFORMÁTICAS Y SIMILARES</option>
                                    <option value="100">PRIMARIA</option>
                                    <option value="4475">PROCESAMIENTO ELECTRÓNICO DE INFORMACIÓN, HOSPEDAJE DE PAGINAS WEB Y OTROS SERVICIOS RELACIONADOS</option>
                                    <option value="830">PROD., VENTA, ASES Y MANTE. DE ACUARIOS</option>
                                    <option value="211">PRODUCCION Y DISTRIBUCION DE ALIMENTOS PROCESADOS POR MAYOREO</option>
                                    <option value="440">PRODUCCION, COMERCIO Y DISTRIBUCION DE ARMAS, MUNICIONES Y OTROS PRODUCTOS MILITARES.</option>
                                    <option value="312">PRODUCTOS DERIVADOS DEL PETROLEO O CARBON</option>
                                    <option value="807">PRODUCTOS STANHOME</option>
                                    <option value="4430">PROVEEDORES DE ACCESO A INTERNET Y SERVICIOS DE BUSQUEDA EN LA RED</option>
                                    <option value="1053">PRUEBA</option>
                                    <option value="402">PUESTO AMBULANTE DE COMIDA</option>
                                    <option value="709">PURIFICACION DE AGUA(por filtracion,pasteurizacion,osmosis inversa etc)</option>
                                    <option value="310">PURIFICADORA DE AGUA, VENTA Y DISTRIBUCION</option>
                                    <option value="314">RECICLADORAS/COMERCIO AL POR MAYOR DE MATERIALES DE DESECHO (PLASTICOS, METAL, PAPEL, CHATARRA)</option>
                                    <option value="708">REFACCIONARIA</option>
                                    <option value="320">REFACCIONARIA, COMBUSTIBLES Y LUBRICANTES</option>
                                    <option value="1029">REFRESQUERIA</option>
                                    <option value="1007">RELLENO DE CATRUCHOS</option>
                                    <option value="8">REMODELACION DE CASAS</option>
                                    <option value="701">RENTA DE EQUIPO PARA CONSTRUCCION</option>
                                    <option value="1018">RENTA DE MOBILIARIO Y EQUIPO PARA FIESTA</option>
                                    <option value="832">RENTA DE PELICULAS</option>
                                    <option value="421">RENTA DE PELICULAS/ VIDEOS Y VIDEOJUEGOS</option>
                                    <option value="418">RENTA DE SALONES/  MOBILIARIO Y EQUIPO PARA FIESTAS Y EVENTOS</option>
                                    <option value="1033">RENTA DE SONIDO</option>
                                    <option value="585">RENTA DE VIDEOS</option>
                                    <option value="558">RENTA Y REPARACION DE LAVADORAS </option>
                                    <option value="1165">REPARACION  DE MOTOS Y BICICLETAS Y VENTA DE REFACCIONES PARA LAS MISMAS</option>
                                    <option value="837">REPARACION DE CALZADO</option>
                                    <option value="527">REPARACION DE LAVADORAS</option>
                                    <option value="1021">REPARACION DE MOTOCICLETAS</option>
                                    <option value="562">REPARACION DE RADIADORES </option>
                                    <option value="1061">REPARACION DE TAPICERIA DE MUEBLES PARA EL HOGAR</option>
                                    <option value="1144">REPARACIÓN MECANICA EN GENERAL DE AUTOMOVILES Y CAMIONES</option>
                                    <option value="1059">REPARACION Y MANTENIMIENTO DE MAQUINARIA Y EQUIPO</option>
                                    <option value="1143">REPARACIÓN Y MANTENIMIENTO DE MAQUINARIA Y EQUIPO COMERCIAL Y DE SERVICIOS</option>
                                    <option value="1124">REPARACION Y MANTENIMIENTO DE MAQUINARIA Y EQUIPO INDUSTRIAL</option>
                                    <option value="4428">REPARACION Y MANTENIMIENTO DE MOTOCICLETAS</option>
                                    <option value="1177">REPARACION Y MANTENIMIENTO DE OTRO EQUIPO ELECTRONICO Y DE OTRO EQUIPO DE PRECISION</option>
                                    <option value="559">REPARACION Y/O MANT. EQUIPOS DE COMPUTO</option>
                                    <option value="675">REPARTIDOR  DE AGUA PURIFICADA</option>
                                    <option value="401">RESTAURANT</option>
                                    <option value="1181">RESTAURANTES DE COMIDA PARA LLEVAR</option>
                                    <option value="4409">RESTAURANTES SIN BAR Y CON SERVICIO DE MESEROS</option>
                                    <option value="1003">RETACERIA</option>
                                    <option value="986">ROTULACION EN GENERAL</option>
                                    <option value="858">ROTULACION Y PUBLICIDAD</option>
                                    <option value="231">SACRIFICIO</option>
                                    <option value="403">SALON DE BELLEZA/PELUQUERIA</option>
                                    <option value="4484">SALONES Y CLINICAS DE BELLEZA Y PELUQUERIAS</option>
                                    <option value="1103">SANITARIOS PUBLICOS Y BOLERIAS</option>
                                    <option value="463">SASTRE/COSTURERO</option>
                                    <option value="2209">SECADO Y CRIBADO</option>
                                    <option value="232">SECADORA - SALADORA</option>
                                    <option value="3307">SELECCION Y EMPAQUE</option>
                                    <option value="838">SERIGRAFIA</option>
                                    <option value="596">SERV DE LIMPIEZA DE ALFOMBRAS Y MUEBLES</option>
                                    <option value="417">SERVICIO DE ALIMENTOS PARA EVENTOS (BANQUETES)</option>
                                    <option value="792">SERVICIO DE DIVERSIONES</option>
                                    <option value="547">SERVICIO DE INTERNET</option>
                                    <option value="833">SERVICIO DE PINTURA E IMPERMIABILIZACION</option>
                                    <option value="850">SERVICIO DE TAPISADO DE MUEBLES</option>
                                    <option value="705">SERVICIO DE TAXI</option>
                                    <option value="669">SERVICIO FOTOGRAFICO</option>
                                    <option value="702">SERVICIO TELEFONICO</option>
                                    <option value="400">SERVICIOS</option>
                                    <option value="671">SERVICIOS ARRENDATARIOS</option>
                                    <option value="4423">SERVICIOS COMBINADOS DE APOYO EN INSTALACIONES</option>
                                    <option value="685">SERVICIOS CONTABLES</option>
                                    <option value="4485">SERVICIOS DE APOYO PARA EFECTUAR TRAMITES LEGALES</option>
                                    <option value="429">SERVICIOS DE BLINDAJE</option>
                                    <option value="4447">SERVICIOS DE CONSULTORÍA EN ADMINISTRACIÓN</option>
                                    <option value="4467">SERVICIOS DE CONSULTORÍA EN COMPUTACIÓN</option>
                                    <option value="4480">SERVICIOS DE CONSULTORIA EN MEDIO AMBIENTE</option>
                                    <option value="464">SERVICIOS DE FUMIGACION</option>
                                    <option value="778">SERVICIOS DE IMPRENTA</option>
                                    <option value="4478">SERVICIOS DE INFORMATICA Y EQUIPO PARA OTROS SERVICIOS Y ACTIVIDADES COMERCIALES</option>
                                    <option value="4477">SERVICIOS DE INVESTIGACION Y DE PROTECCION Y CUSTODIA, EXCEPTO MEDIANTE MONITOREO</option>
                                    <option value="672">SERVICIOS DE LAVANDERIA</option>
                                    <option value="411">SERVICIOS DE LIMPIEZA (PARA EL HOGAR Y CENTROS DE TRABAJO)</option>
                                    <option value="465">SERVICIOS DE MENSAJERIA Y PAQUETERIA</option>
                                    <option value="1092">SERVICIOS DE PREPARACIÓN DE ALIMENTOS EN UNIDADES MOVILES</option>
                                    <option value="1096">SERVICIOS DE PREPARACION DE ALIMENTOS PARA OCASIONES ESPECIALES</option>
                                    <option value="466">SERVICIOS DE PRODUCCION DE AUDIO Y VIDEO</option>
                                    <option value="4416">SERVICIOS DE PROTECCION Y CUSTODIA MEDIANTE EL MONITOREO DE SISTEMAS DE SEGURIDAD</option>
                                    <option value="1180">SERVICIOS DE ROTULACION Y OTROS SERVICIOS DE PUBLICIDAD</option>
                                    <option value="467">SERVICIOS DE SEGURIDAD PRIVADA</option>
                                    <option value="409">SERVICIOS DE TELECOMUNICACIONES (TELMEX, TELNOR, CENTRO DE ATENCION A CLIENTES DE TELEFONIA CELULAR O RADIO) - NO DISTRIBUIDORES</option>
                                    <option value="407">SERVICIOS EDUCATIVOS (ESCUELAS EN GENERAL, ESCUELAS DE INGLES, OTROS CURSOS)</option>
                                    <option value="468">SERVICIOS FOTOGRÁFICOS ESTUDIO Y EVENTOS</option>
                                    <option value="880">SERVICIOS FUNERARIOS</option>
                                    <option value="1089">SERVICIOS FUNERARIOS</option>
                                    <option value="522">SERVICIOS MULTIPLES</option>
                                    <option value="422">SERVICIOS PERSONALES (CONSULTORES, PROFESIONISTAS)</option>
                                    <option value="423">SERVICIOS PROFESIONALES/ CIENTIFICOS Y TECNICOS</option>
                                    <option value="1063">Servicios veterinarios para mascotas prestado por el sector privado que requieran de titulo de medico conforme las leyes</option>
                                    <option value="4464">SIEMBRA, CULTIVO Y COSECHA DE JITOMATE O TOMATE ROJO</option>
                                    <option value="4458">SIEMBRA, CULTIVO Y COSECHA DE MAÍZ GRANO</option>
                                    <option value="4451">SIEMBRA, CULTIVO Y COSECHA DE MELON O SANDIA</option>
                                    <option value="4499">SIEMBRA, CULTIVO Y COSECHA DE OTRAS HORTALIZAS Y SEMILLAS DE HORTALIZAS</option>
                                    <option value="4456">SIEMBRA, CULTIVO Y COSECHA DE TRIGO</option>
                                    <option value="4498">SIEMBRA, CULTIVO Y COSECHA DE UVA</option>
                                    <option value="4446">SIEMBRA,CULTIVO Y COSECHA DE ALFALFA</option>
                                    <option value="4479">SIEMBRA,CULTIVO Y COSECHA DE SOYA</option>
                                    <option value="104">SILVICULTURA</option>
                                    <option value="1013">SISTEMA DE SEGURIDAD</option>
                                    <option value="433">SOCIEDADES COOPERATIVAS DE AHORRO Y PRESTAMOS</option>
                                    <option value="434">SOCIEDADES FINANCIERAS POPULARES</option>
                                    <option value="538">SOLDADURA ELECTRICA</option>
                                    <option value="469">SPA</option>
                                    <option value="865">SUBMAQUILADO DE ROPA</option>
                                    <option value="1071">SUMINISTRO DE GAS POR DUCTOS AL CONSUMIDOR FINAL</option>
                                    <option value="1129">SUMINISTRO DE PERSONAL PERMANENTE</option>
                                    <option value="529">TALLER DE CARPINTERIA</option>
                                    <option value="570">TALLER DE CARROCERIA Y PINTURA</option>
                                    <option value="664">TALLER DE COSTURA</option>
                                    <option value="677">TALLER DE HERRERIA</option>
                                    <option value="210">TALLER DE HERRERIA Y SOLDADURA</option>
                                    <option value="881">TALLER DE MANUALIDADES</option>
                                    <option value="404">TALLER DE MECANICA-ELECTRICA-CARROCERIA AUTOMOTRIZ</option>
                                    <option value="839">TALLER DE MOFLES</option>
                                    <option value="599">TALLER DE RADIADORES</option>
                                    <option value="565">TALLER DE REPARACION DE BICILETAS</option>
                                    <option value="408">TALLER DE REPARACION DE EQUIPOS Y APARATOS (ELECTRONICOS, ELECTRODOMESTICOS)</option>
                                    <option value="544">TALLER DE REPARACION ELECTROMECANICA</option>
                                    <option value="999">TALLER DE TORNO</option>
                                    <option value="514">TALLER MECANICO</option>
                                    <option value="470">TALLERES DE POLARIZADO EN GENERAL (PARABRISAS, VENTANAS)</option>
                                    <option value="237">TALLERES DE REPARACION</option>
                                    <option value="806">TALLES DE MANUALIDADES</option>
                                    <option value="521">TAQUERO</option>
                                    <option value="405">TAXISTA</option>
                                    <option value="1026">TELAS Y ARTICULOS DE COSTURA</option>
                                    <option value="820">TEXTILES</option>
                                    <option value="306">TIENDA DE ACCESORIOS (CINTURONES, BOLSAS Y BISUTERIA)</option>
                                    <option value="471">TIENDA DE PINTURA</option>
                                    <option value="1085">TIENDA DE PRODUCTOS Y SERV. PARA ANIMALES</option>
                                    <option value="309">TIENDA DE REGALOS, CURIOSIDADES Y NOVEDADES</option>
                                    <option value="1054">TIENDA DE REGALOS, CURIOSIDADES Y NOVEDADES</option>
                                    <option value="327">TIENDA NATURISTA O FARMACIA HOMEOPATA</option>
                                    <option value="893">TOPOGRAFO</option>
                                    <option value="713">TORTILLERIA</option>
                                    <option value="472">TORTILLERIA HARINA Y MAIZ PRODUCCION Y DISTRIBUCION POR MAYOREO</option>
                                    <option value="202">TORTILLERIA HARINA Y MAIZ VENTA DIRECTA EN ESTABLECIMIENTO</option>
                                    <option value="1002">TOSTICENTRO</option>
                                    <option value="602">TRABAJO </option>
                                    <option value="213">TRABAJOS DE  MANTENIMIENTO EN GRAL  Y ALBAÑILERIA (PLOMERIA, ELECTRICIDAD, ETC.)</option>
                                    <option value="843">TRABAJOS DE CONSTRUCCION EN GENERAL</option>
                                    <option value="1094">TRABAJOS DE PINTURA Y OTROS CUBRIMIENTOS DE PAREDES</option>
                                    <option value="776">TRABAJOS GENERALES DE MANTENIMIENTO</option>
                                    <option value="794">TRAMITES DE IMPORTACION</option>
                                    <option value="431">TRANSMISORES Y/O DISPERSORES DE FONDOS</option>
                                    <option value="473">TRANSMISORES Y/O DISPERSORES DE FONDOS</option>
                                    <option value="3308">TRANSPORTE</option>
                                    <option value="597">TRANSPORTE DE MAT. DE CARGA P/ CONSTRUC.</option>
                                    <option value="549">TRANSPORTE DE MERCANCIA </option>
                                    <option value="510">TRANSPORTE DE PERSONAL</option>
                                    <option value="474">TRANSPORTE ESCOLAR</option>
                                    <option value="663">TRANSPORTE ESCOLAR</option>
                                    <option value="1161">TRANSPORTE ESCOLAR Y DE PERSONAL</option>
                                    <option value="475">TRANSPORTE TERRESTRE DE PASAJEROS FORÁNEO</option>
                                    <option value="406">TRANSPORTE TERRESTRE DE PASAJEROS URBANO</option>
                                    <option value="796">TRASPORTISTA URBANO</option>
                                    <option value="829">UÑAS ACRILICAS</option>
                                    <option value="436">UNIONES DE CREDITO</option>
                                    <option value="815">VENDEDOR DE ESPACIOS PUBLICITARIOS</option>
                                    <option value="802">VENTA  Y REPARACION DE EQUIPO DE COPIADO</option>
                                    <option value="1113">VENTA AL POR MAYOR POR COMISIÓN Y CONSIGNACIÓN</option>
                                    <option value="515">VENTA DE  BATERIAS DE COCINA</option>
                                    <option value="665">VENTA DE ABARROTES EN GRAL.</option>
                                    <option value="507">VENTA DE ABARROTES EN PEQUEÑO</option>
                                    <option value="797">VENTA DE AGUA PURIFICADA</option>
                                    <option value="1014">VENTA DE ALFOMBRAS Y PERSIANAS</option>
                                    <option value="1039">VENTA DE ARDYSS</option>
                                    <option value="809">VENTA DE ART. DE REGALO</option>
                                    <option value="512">VENTA DE ARTESANIAS</option>
                                    <option value="508">VENTA DE ARTICULOS  EN AREA RURAL</option>
                                    <option value="870">VENTA DE ARTICULOS DE LIMIEZA</option>
                                    <option value="857">VENTA DE ARTICULOS DE PIEL</option>
                                    <option value="803">VENTA DE ARTICULOS DE SALDOS</option>
                                    <option value="580">VENTA DE ARTICULOS DE SEGUNDA</option>
                                    <option value="520">VENTA DE AUTOPARTES USADAS</option>
                                    <option value="775">VENTA DE AVON</option>
                                    <option value="831">VENTA DE BIENES RAICES</option>
                                    <option value="804">VENTA DE BILLETES LOTERIA</option>
                                    <option value="534">VENTA DE BLANQUILLOS </option>
                                    <option value="798">VENTA DE BOLSAS Y ACCESORIOS</option>
                                    <option value="568">VENTA DE CALZADO</option>
                                    <option value="533">VENTA DE CARBON </option>
                                    <option value="717">VENTA DE CARNITAS</option>
                                    <option value="582">VENTA DE CARROS </option>
                                    <option value="545">VENTA DE CASETES Y CDS</option>
                                    <option value="578">VENTA DE CERAMICA</option>
                                    <option value="548">VENTA DE CERVEZA Y ABARROTES</option>
                                    <option value="536">VENTA DE CHORIZO </option>
                                    <option value="1101">VENTA DE COCINAS INTEGRALES</option>
                                    <option value="574">VENTA DE COLCHAS Y EDREDONES</option>
                                    <option value="308">VENTA DE COSMETICOS Y PRODUCTOS DE BELLEZA EN ESTABLECIMIENTO</option>
                                    <option value="476">VENTA DE COSMETICOS Y PRODUCTOS DE BELLEZA POR CATÁLOGO</option>
                                    <option value="711">VENTA DE ENVOLTURAS PARA REGALO </option>
                                    <option value="849">VENTA DE ESPECIAS</option>
                                    <option value="546">VENTA DE FRUTA Y VERDURA</option>
                                    <option value="573">VENTA DE GRANOS</option>
                                    <option value="996">VENTA DE HERBAX</option>
                                    <option value="1009">VENTA DE INSUMOS PARA PANADERIA</option>
                                    <option value="668">VENTA DE JOYERIA</option>
                                    <option value="589">VENTA DE JUGUETES Y NOVEDADES</option>
                                    <option value="519">VENTA DE LENCERIA Y CORCETERIA </option>
                                    <option value="827">VENTA DE LENTES EN PEQUEÑO</option>
                                    <option value="859">VENTA DE LIBROS</option>
                                    <option value="329">VENTA DE LIBROS, DISCOS Y REVISTAS (LIBRERIAS, TIENDAS DE MUSICA)</option>
                                    <option value="526">VENTA DE LLANTAS USADAS Y NUEVAS</option>
                                    <option value="1025">VENTA DE MATERIAL ELECTROMECANICO</option>
                                    <option value="777">VENTA DE MEGA HEALTH</option>
                                    <option value="564">VENTA DE MUEBLES DE OFICINA </option>
                                    <option value="540">VENTA DE NOVEDADES </option>
                                    <option value="553">VENTA DE NOVEDADES Y PLASTICOS </option>
                                    <option value="575">VENTA DE PERFUMES</option>
                                    <option value="569">VENTA DE PESCADO</option>
                                    <option value="890">VENTA DE PINTURAS</option>
                                    <option value="477">VENTA DE PIROTECNIA/FUEGOS ARTIFICIALES</option>
                                    <option value="555">VENTA DE PLANTAS ARTIFICIALES </option>
                                    <option value="782">VENTA DE PLANTAS DE ORNATO</option>
                                    <option value="556">VENTA DE PLATA EN PEQUEÑO</option>
                                    <option value="11">VENTA DE POLLO FRESCO</option>
                                    <option value="542">VENTA DE PRODUC. DE BELLEZA</option>
                                    <option value="864">VENTA DE PRODUCTOS AMWAY</option>
                                    <option value="847">VENTA DE PRODUCTOS DE ORIGEN NATURAL</option>
                                    <option value="332">VENTA DE PRODUCTOS E INSUMOS PARA EL SECTOR PRIMARIO</option>
                                    <option value="773">VENTA DE PRODUCTOS HERBALIFE</option>
                                    <option value="805">VENTA DE PRODUCTOS INFATILES PARA RIFAS</option>
                                    <option value="808">VENTA DE PRODUCTOS MADAME CHANTAL</option>
                                    <option value="524">VENTA DE PRODUCTOS NATURALES</option>
                                    <option value="305">VENTA DE PRODUCTOS POR CATALOGO</option>
                                    <option value="795">VENTA DE PRODUCTOS VARIOS POR CATALOGO</option>
                                    <option value="874">VENTA DE REFACCIONES AUTOMOTRIZES</option>
                                    <option value="530">VENTA DE ROPA </option>
                                    <option value="7">VENTA DE ROPA Y CALZADO</option>
                                    <option value="303">VENTA DE ROPA Y CALZADO EN PUESTO AMBULANTE</option>
                                    <option value="784">VENTA DE ROPA Y DESPENSAS</option>
                                    <option value="304">VENTA DE ROPA, CALZADO Y ACCESORIOS EN EL HOGAR </option>
                                    <option value="1010">VENTA DE SOMBREROS</option>
                                    <option value="703">VENTA DE TELEFONIA CELULAR Y ACCESORIOS</option>
                                    <option value="817">VENTA DE VEHICULOS</option>
                                    <option value="1024">VENTA DE VESTIDOS DE NOVIA Y ACCESORIOS</option>
                                    <option value="478">VENTA E INSTALACION DE EQUIPO DE SONIDO</option>
                                    <option value="479">VENTA E INSTALACION DE SISTEMAS DE SEGURIDAD</option>
                                    <option value="88">VENTA HOME INTERIORS</option>
                                    <option value="316">VENTA O RENTA DE MAQUINARIA Y EQUIPO</option>
                                    <option value="577">VENTA OMNILIFE</option>
                                    <option value="1012">VENTA TUPPERWARE</option>
                                    <option value="480">VENTA Y ALQUILER DE DISFRACES Y VESTUARIO ARTISTICO</option>
                                    <option value="790">VENTA Y COMPUSTORA DE RADIOCOMUNICADORES</option>
                                    <option value="990">VENTA Y ELABORACION DE TEJUINO Y HAMBURGUESAS</option>
                                    <option value="984">VENTA Y REP DE VIDRIOS</option>
                                    <option value="871">VERNTA DE VIDEO</option>
                                    <option value="992">VETERINARIO</option>
                                    <option value="822">VIDEO JUEGOS</option>
                                    <option value="590">VIDRIERA</option>
                                    <option value="206">VIDRIERA, PERFILES DE ALUMINIO Y CANCELERIA </option>
                                    <option value="2211">VINICOLAS Y DESTILERIAS</option>
                                    <option value="785">VTA  EDREDONES Y ART. ELECTRODOMESTICOS</option>
                                    <option value="561">VTA DE EQUIPOS CONTRA INCENDIOS</option>
                                    <option value="818">VTA DE PRODUCT. VICTORIA´S SECRET</option>
                                    <option value="554">VTA DE ROPA Y NOVEDADES </option>
                                    <option value="987">VTA Y REP DE CAJAS REGISTRADORAS</option>
                                    <option value="885">VTA Y REPARACION DE APARATOS ELECTRICOS</option>
                                    <option value="518">VTA. DE ACCESORIOS Y EQUIPO DE COMPUTO </option>
                                    <option value="12">YONNKE</option>
                                    <option value="343">ZAPATERIA</option>
                                </select>
                            </div>
                            <div className="col-lg-6 ">
                                <label className="col-form-label-admin">Nivel de Cuenta</label>
                                <select className="form-select txtControl" id="nivelCuenta" value={companyData.nivelCuenta} onChange={handleChange}>
                                    <option value="4">Nivel 4</option>
                                    <option value="1">Nivel 1</option>
                                    <option value="2">Nivel 2</option>
                                    <option value="3">Nivel 3</option>
                                </select>
                            </div>
                        </div>
                        <div className="row row-admin">
                            <div className="col-lg-6 col-admin">
                                <label className="col-form-label-admin">Comisión por costo fijo SPEI In</label>
                                <input type="text" className="form-control txtControl" id='costoFijoSpeiIn' value={companyData.costoFijoSpeiIn} onChange={handleChange} placeholder="Comisión con Costo Fijo para Spei IN" />
                            </div>
                            <div className="col-lg-6 ">
                                <label className="col-form-label-admin">Comisión por porcentaje SPEI In</label>
                                <input type="text" className="form-control txtControl" id='costoPctSpeiIn' value={companyData.costoPctSpeiIn} onChange={handleChange} placeholder="Comisión con por porcentaje para Spei IN" />
                            </div>
                        </div>
                        <div className="row row-admin">
                            <div className="col-lg-6 col-admin">
                                <label className="col-form-label-admin">Comisión por costo fijo SPEI Out</label>
                                <input type="text" className="form-control txtControl" id='costoFijoSpeiOut' value={companyData.costoFijoSpeiOut} onChange={handleChange} placeholder="Comisión con Costo Fijo para Spei OUT" />
                            </div>
                            <div className="col-lg-6 ">
                                <label className="col-form-label-admin">Comisión por porcentaje SPEI Out</label>
                                <input type="text" className="form-control txtControl" id='costoPctSpeiOut' value={companyData.costoPctSpeiOut} onChange={handleChange} placeholder="Comisión con por porcentaje para Spei OUT" />
                            </div>
                        </div>

                        <div className="row row-admin">
                            <div className="col-lg-6 col-admin">
                                <label className="col-form-label-admin">Límite de crédito</label>
                                <input type="text" className="form-control txtControl" id="limiteCredito"  value={companyData.limiteCredito} onChange={handleChange} placeholder="" />
                            </div>
                            <div className="col-lg-6 ">
                            
                                <label className="col-form-label-admin">Comisión por recarga de tarjetas SitesPay</label>
                                <input type="text" className="form-control txtControl" id="comisionTarjeta"  value={companyData.comisionTarjeta} onChange={handleChange} placeholder="" />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='container-admin'>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Calle Principal</label>
                                <input type="text" className="form-control txtControl" id="callePrincipal" value={companyData.callePrincipal} onChange={handleChange} />
                            </div>
                            <div className='col-md-3'>
                                <label className="col-form-label-admin">Número Exterior</label>
                                <input type="text" className="form-control txtControl" id="numExterior" value={companyData.numExterior} onChange={handleChange} />
                            </div>
                            <div className='col-md-3'>
                                <label className="col-form-label-admin">Número Interior</label>
                                <input type="text" className="form-control txtControl" id="numInterior" value={companyData.numInterior} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Colonia</label>
                                <input type="text" className="form-control txtControl" id="colonia" value={companyData.colonia} onChange={handleChange} />
                            </div>
                            <div className='col-md-3'>
                                <label className="col-form-label-admin">Ciudad</label>
                                <input type="text" className="form-control txtControl" id="ciudad" value={companyData.ciudad} onChange={handleChange} />
                            </div>
                            <div className='col-md-3'>
                                <label className="col-form-label-admin">Código Postal</label>
                                <input type="text" className="form-control txtControl" id="codPostal" value={companyData.codPostal} onChange={handleChange} />
                            </div>
                        </div>
                        <div className='row row-admin'>
                            <div className='col-md-6 col-admin'>
                                <label className="col-form-label-admin">Entre calle</label>
                                <input type="text" className="form-control txtControl" id="calleSecundaria2" value={companyData.calleSecundaria2} onChange={handleChange} />
                            </div>
                            <div className='col-md-6'>
                                <label className="col-form-label-admin">Y calle</label>
                                <input type="text" className="form-control txtControl" id="calleSecundaria3" value={companyData.calleSecundaria3} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Company
