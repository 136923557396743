import React, { useState, useEffect } from 'react'
import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';

import '../../components/Table/Table.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

import MostUsedKeys from '../../components/Modals/MostUsedKeys';
import ConfirmTransferCard from '../../components/Modals/ConfirmTransferCard';

function CradTransfer() {
    const isLogin = true;
    const title = "tarjeta a la que desea transferir";

    const navigate = useNavigate();
    const [isSelect, setIsSelect] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [otherAccounts, setOtherAccounts] = useState([]);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [confirmDisabled, setConfirmDisabled] = useState(false);

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setAccounts(response.cuentas)
                } else if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                }
            });
    }, [navigate]);

    const [transferData, setTransferData] = useState({
        origin: '',
        destination: '',
        amount: '',
        reference: '',
        opsPass: '',
        email: ''
    });
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => { setShowModal(false); setConfirmDisabled(false); }
    const handleShowModal = () => setShowModal(true);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        if (id === 'amount' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = transferData[id] || '';
            return;
        }

        if (id === 'destination' && value !== '' && errorIn.integer(value)) {
            e.target.value = transferData[id] || '';
            return;
        }
        if (id === 'destination' && value.length === 16) {
            if (errorIn.luhnCheck(value)) {
                setAlert({ tipo: 'danger', msn: 'Número de tarjeta inválido', show: true });
            }
        }

        if (id === 'reference' && value !== '' && errorIn.integer(value)) {
            e.target.value = transferData[id] || '';
            return;
        }

        setTransferData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };
    const handleChangeOrigin = (e) => {
        const value = e.target.value;
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        setOtherAccounts([]);

        setTransferData({
            ...transferData,
            origin: value,
        });

        fetchData(process.env.REACT_APP_ACCOUNT_CARDS + value + "/?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setOtherAccounts(response)
                    if (response.length > 0) {
                        setIsSelect(true);
                    } else {
                        setIsSelect(false);
                    }
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }
            });
    };

    const handleRowSelect = (row) => {
        setTransferData(prevData => ({
            ...prevData,
            destination: row.cuentaTercero
        }));
    }
    const closeAlert = (alert) => {
        setConfirmDisabled(false);
        setAlert(alert);
    };
    const resetTranferData = () => {
        setTransferData({
            origin: '',
            destination: '',
            email: '',
            amount: '',
            reference: ''
        });
    };

    const validateTransactionData = () => {
        const errors = [];
        if (errorIn.length(transferData.origin, 16, 18, "Cuenta origen inválida")) errors.push("Cuenta origen inválida");
        if (errorIn.length(transferData.destination, 16, 16, "Número de tarjeta inválido"))
            errors.push("Número de tarjeta inválido");
        else
            if (errorIn.luhnCheck(transferData.destination))
                errors.push("Número de tarjeta inválido");
        if (transferData.email.trim() !== '' && errorIn.email(transferData.email, "Email inválido")) errors.push("Email inválido");
        if (errorIn.money(transferData.amount, "Monto inválido")) errors.push("Monto inválido");
        if (transferData.reference === '' || errorIn.numeric(transferData.reference, "Referencia inválida")) errors.push("Referencia inválida");

        if (errors.length > 0) {
            setAlert({ tipo: 'danger', msn: errors.join('\n'), show: true });
        } else {
            setConfirmDisabled(true);
            setAlert({ tipo: '', msn: '', show: false });
            handleShowModal();
        }
    };

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className="container-fluid2 position-absolute space-acconts">
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-body-container-bg'>
                                    <div className="row contentTitle">
                                        <div className="col-sm-9">
                                            <h4 className="subTitle2">Recarga a Tarjeta</h4>
                                        </div>
                                        <div className="col-sm-3 divContainerBtn">
                                            <div className="d-grid">
                                                <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body-card">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <div className="row containerRowCard">
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos de tarjetas</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <label className="form-label">Cuenta origen</label>
                                                            <select className="form-select" id="origin" value={transferData.origin} onChange={handleChangeOrigin}>
                                                                <option value='0'>Seleccione la cuenta origen</option>
                                                                {accounts.map((account) => (
                                                                    <option
                                                                        key={account.id}
                                                                        value={account.accountNumber}>
                                                                        {account.accountNumber} - {account.description}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {isSelect ? (
                                                                <div className="contentCenter">
                                                                    <p className="txtMas" data-bs-toggle="modal" data-bs-target="#clavesModal">Mostar tarjetas más utilizadas</p>
                                                                </div>
                                                            ) : (null)}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de tarjeta a depositar</label>
                                                            <input type="text" id='destination' value={transferData.destination} onChange={handleChange} maxLength='16' className="form-control" placeholder="Número de tarjeta" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Correo electrónico (opcional)</label>
                                                            <input type="email" id='email' value={transferData.email} onChange={handleChange} className="form-control" placeholder="ejemplo@gmail.com" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos de transferencia</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <label className="form-label">Monto a Transferir</label>
                                                            <input type="text" id='amount' value={transferData.amount} onChange={handleChange} className="form-control" placeholder="$0.00" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de referencia</label>
                                                            <input type="text" id='reference' value={transferData.reference} onChange={handleChange} maxLength='7' className="form-control" placeholder="Número de referencia" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid contentBtn2">
                                        <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmTransferCard transferData={transferData} show={showModal} handleClose={handleCloseModal} resetTranferData={resetTranferData} transferType={3} isSPCard={false} />
            <MostUsedKeys tipo={2} otherAccounts={otherAccounts} onSelect={handleRowSelect} title={title} />
            <Footer />
        </div>
    )
}

export default CradTransfer
