import React from 'react';
import Modal from 'react-bootstrap/Modal';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import * as errorIn from '../../utils/validations';
import Message from '../../components/Message/Message';

function ImproperChargeClaim({ show, handleClose, data }) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const [claimForm, setClaimForm] = useState({
        claimNotes: '',
        authorizationNotes: ''
    });
    const handleClick = () => {
        if( !claimForm.claimNotes || !claimForm.authorizationNotes ) {
            setAlert({tipo: 'warning', msn: 'Debe ingresar la razón del reclamo y autorización correspondiente', show: true});
            return;
        }
        //console.log(data, claimForm);
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }
        const strPayload = JSON.stringify( claimForm );
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        postData(process.env.REACT_APP_ADMIN_CARDS + "transactionreturn/"+ data.numTransaction +"/?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                if( error.response.status === 401 ) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else {
                    const responseErrors = error.response.data;
                    if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
            }

            if(response) {
                setAlert({tipo: 'success', msn: 'Se ha realizado la devolución a la Tarjeta correspondiente', show: true});
                setClaimForm({
                    claimNotes: '',
                    authorizationNotes: ''
                });
            }
        });


    }

    const handleChange = (e) => {
        const { id, value } = e.target;

        if( errorIn.maxLength(value, 250, 'error') ) {
            e.target.value = claimForm[id] || '';
            return;
        }
 
        setClaimForm((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Cancelación y Devolución por Cargo</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula space-detail">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <div className="row row-form-text">
                            <div className="col-lg-6 content-label">
                                <label className="col-form-label">Notas del Reclamo:</label>
                            </div>
                            <div className="col-lg-6 content-input">
                                <textarea type="text" className="form-control txtControl" id="claimNotes" maxLength={200} value={claimForm.claimNotes} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row row-form-text">
                            <div className="col-lg-6 content-label">
                                <label className="col-form-label">Notas de la Autorización de Cancelación:</label>
                            </div>
                            <div className="col-lg-6 content-input">
                                <textarea type="text" className="form-control txtControl" id="authorizationNotes" maxLength={200} value={claimForm.authorizationNotes} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary2 btnModal" onClick={handleClick} >Confirmar Devolución</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ImproperChargeClaim