import React, { useState, useEffect } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import Table from '../../components/Table/Table'
//import search from '../../assets/icons/search-svgrepo-com.svg'
//import SearchModal from '../../components/Modals/SearchModal'

import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import { useParams } from 'react-router-dom';

//import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
//import NewAccount from '../../components/Modals/NewAccount'

import '../../css/body.css'

function CompanyAccount() {
    const { id } = useParams();
    const isLogin = true;
    //const isAdmin = true;

    const empresa = "";
    const [pagination, setPagination] = useState({
        itemsPerPage: 15,
        totalItems: 1,
        currentPage: 1
    });

    const tableHeaders = ['Cuenta Clabe', 'Nombre', ''];
    const tableStyles = ['accountStyle', 'textStyle'];
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const isMenu = true;

    //const link = '';
    const selectedOption = "2";
    //const tipo = "udn";

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const menuIcon = [
       // { icon: Edit, url: '', modal: () => handleShowAdd(true), clave: '' },
      //  { icon: Delete, url: '', modal: 'modal', clave: '#editarUdn' },
    ]

    //Busqueda
    // <button className='btnSearchModal' type="button" onClick={handleShow}>
    //     <img src={search} alt=""/>
    // </button>

    //            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData}/>
    // const [searchData, setSearchData] = useState({
    //     byCompanyId: '',
    //     byNameCompany: ''
    // });

    function loadAccounts(currentPage) {
        if(currentPage===undefined)
            currentPage = pagination.currentPage;

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        fetchData(process.env.REACT_APP_ACCOUNTS_COMPANYALL + id + "?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                const acctResponse = response.map(acct => ({
                    clabe: acct.accountNumber,
                    nombre: acct.description,
                    empresa: acct.beneficiary,
                    enRenta: acct.enRenta ? 'Si' : '',
                    cuentaCobro: acct.cuentaCobro,
                    id: acct.id
                }));
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setTableData(acctResponse);
            }
        });
    }

    useEffect( () => {
        loadAccounts(pagination.currentPage);
    },[pagination.currentPage])

    
    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
    }


    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption}/>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-11 containerTitleAdmin">
                                            <p className='tilteAdmin'>Cuentas de la Empresa: {empresa} </p>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={tableData} styles={tableStyles} itemsPerPage={10} menuIcon={menuIcon} isMenu={isMenu} /*onSelect={handleRowSelect}*/ tipo='admin' from='admin' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default CompanyAccount
