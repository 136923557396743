import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import Table from '../../components/Table/Table'

import NewCompanyB from '../../components/Modals/NewCompanyB'

// import NewCompany from '../../components/Modals/NewCompany'
// import AddressCompany from '../../components/Modals/AddressCompany'
// import LegalRepresentative from '../../components/Modals/LegalRepresentative'
import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
import SearchModal from '../../components/Modals/SearchModal'
//import CompanyProfile from '../../components/Modals/CompanyProfile'

import '../../css/body.css'
//import NewUdns from '../../components/Modals/NewUdns'

import { useNavigate } from 'react-router-dom';
import { fetchData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
//import * as catalogs from '../../utils/catalogs';

function Empresas() {
    const isLogin = true;

    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }
    const [updateData, setUpdateData] = useState(1);
    const reloadCompanyData = () => {
        setUpdateData(updateData + 1);
    }

    const [searchData, setSearchData] = useState({
        byCompanyId: '',
        byNameCompany: ''
    });

    const getCompanies = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        if(searchData.byCompanyId)
            searchQuery = '&byrfc=' + searchData.byCompanyId;
        if(searchData.byNameCompany)
            searchQuery = searchQuery + '&bycompanyname=' + searchData.byNameCompany;

        fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + "?usepm=1&token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                const empresasToSet = response.empresas.map(item => ({
                    rfc: item.rfc,
                    nombre: item.nombre,
                    razonSocial: item.razonSocial,
                    speiInPct: item.speiInPct,
                    speiInCost: item.speiInCosto,
                    speiOutPct: item.speiOutPct,
                    speiOutCost: item.speiOutCosto,
                    id: item.idEmpresa,
                    idClient: item.idCliente
                }));
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setCompanies(empresasToSet);
            }
        });
    };

    useEffect(() => {
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
        if(updateData) 
            getCompanies();

    }, [updateData, pagination.currentPage]);


    const tableHeaders = ['RFC Empresa', 'Nombre', 'Razón Social','Spei In %','Spei in $','Spei Out %','Spei out $','Acciones'];
    const tableStyles = ['textStyle', 'textStyle', 'textStyle','amountStyle','amountStyle','amountStyle','amountStyle','centeredStyle'];

    const link = '/cuentasEmpresas/';
    const selectedOption = "2";

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        getCompanies();
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    const [selected, setSelectedRow] = useState(null);
    const handleRowSelect = (row) => {
        setSelectedRow(row);
    }

    const tipo = "empresa";

    //Modal Buscar 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Modal NewEmpresa
    const [showFirstModal, setShowFirstModal] = useState(false);
    const handleCloseFirstModal = () => {
        setShowFirstModal(false);
    }

    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const isMenu = true;
    const menuIcon = [
        { icon: Edit, url: '/actualizarEmpresa', modal: '', clave: '' }
    ]

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption}/>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-7 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Empresas</p>
                                        </div>
                                        <div className="col-sm-4 contenBtnAdmin">
                                            <button className="btn btn-primary" type="button" onClick={() => setShowFirstModal(true)}>Nueva Empresa</button>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={companies} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} link={link} isMenu={isMenu} onSelect={handleRowSelect} from='admin'
                                            currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems/pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <NewCompanyB /*handleChanges={saveCompany}*/ showModal={showFirstModal} handleCloseModal={handleCloseFirstModal} reload={reloadCompanyData} />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData}/>
        </div>
    )
}

export default Empresas
