import React, { useEffect, useState } from 'react'
import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';

import { useParams } from 'react-router-dom';


function UpdateUser() {
    const { id } = useParams();
    const isLogin = true;
    const navigate = useNavigate();
    const selectedOption = "3";

    const [isSaveDataDisabled, setIsSaveDataDisabled] = useState(false);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    function Options({ options }) {
        //console.log(options);
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }

    const [newUserData, setNewUserData] = useState({
        id: 0,
        nombre: '',
        correo: '',
        loginPass: '',
        opsPass: '',
        roleId: 0,
        clienteId: 0,
        empresaId: 0,
        cuentaId: 0,
        cuentaCobroId: 0,
        porcentajeCobro: 0,
        multicuentas: []
    });

    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN'
    }]);
    const [rolesToSelect, setRolesToSelect] = useState([{
        id: 0,
        value: 'Seleccione el role del usuario'
    }]);

    const [companiesList, setCompaniesList] = useState([]);
    const loadCompanies = (localClientId, localRoleId) => {
        setCompaniesList([]);
        localRoleId = parseInt(localRoleId);
        if(localRoleId === 30 || localRoleId === 50 || localRoleId === 60 || localRoleId === 80 || localRoleId === 90 || localRoleId === 100) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            var useLocalId = newUserData.clienteId;
            if(localClientId)
                useLocalId = localClientId;
            if(useLocalId) {
                //console.log('udn: ', useLocalId);
                fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + useLocalId + "/?token=" + userData.token, headers)
                .then(({ response, error}) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                        } else {
                            console.log(error);
                            setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                        }
                    }

                    if (response) {
                        //console.log('udns: ', response);
                        const udns = response.empresas.map( item => ({
                            id: item.idEmpresa, //idUdn,
                            value: item.razonSocial,
                            //clientId: item.id
                        }));
                        setCompaniesList(udns);
                        if(udns[0]) {
                            setNewUserData((prevData) => ({
                                ...prevData,
                                'empresaId': udns[0].id
                            }));
                            //console.log(udns);
                            loadAccounts(udns[0].id);
                        }
                    }
                });
            } 
        }
    }

    const [multiAccounts, setMultiAccounts] = useState([]);
    const [cuentaCobroOps, setCuentaCobroOps] = useState([]);
    const [accountsList, setAccountsList] = useState([]);
    const loadAccounts = (localCompanyId) => {
        setAccountsList([]);
        // if((newUserData.empresaId>0) && (newUserData.roleId === '60' || newUserData.roleId === '80')) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            var useLocalId = newUserData.empresaId;
            if(localCompanyId)
                useLocalId = localCompanyId;

            if(localCompanyId) {
                fetchData(process.env.REACT_APP_ACCOUNTS_COMPANY + useLocalId + "/?token=" + userData.token, headers)
                .then(({ response, error}) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                        } else {
                            console.log(error);
                            setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                        }
                    }

                    if (response) {
                        const cuentas = response.map( item => ({
                            id: item.id, //idUdn,
                            value: item.accountNumber,
                            //clientId: item.id
                        }));
                        setAccountsList(cuentas);
                        //console.log(udns);
                        if(cuentas[0])
                            setNewUserData((prevData) => ({
                                ...prevData,
                                'cuentaId': cuentas[0].id,
                                //'cuentaCobroId': cuentas[0].id //la asigno por si acaso fuera el rol, sino, de todas maneras ni se consideraría
                            }));
                    }
                });
            }
        // }
    }

    const saveData = () => {
        setIsSaveDataDisabled(true);
        setAlert({tipo: '', msn: '', show: false});
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const dataToUpdate = {
            id: newUserData.id,
            username: newUserData.nombre,
            email: newUserData.correo,
            pwdAccess: newUserData.loginPass,
            opsPwd: newUserData.opsPass,
            idClient: parseInt(newUserData.clienteId),
            idCompany: newUserData.empresaId ? parseInt(newUserData.empresaId) : null,
            idAccount: newUserData.cuentaId ? parseInt(newUserData.cuentaId) : null,
            idRole: newUserData.roleId.toString(),
            multiAccounts: newUserData.multicuentas,
            depositCost: parseFloat(newUserData.porcentajeCobro),
            chargeAccount: parseInt(newUserData.cuentaCobroId)
        }
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {userdata: cryptPayload};

        postData(process.env.REACT_APP_AUTH_USERS + newUserData.id + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                setIsSaveDataDisabled(false);
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                //console.log(response);
                setAlert({tipo: 'success', msn: 'Datos actualizados correctamente', show: true});
            }
        });
    }
    
    const handleChangeRole = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        //console.log('changing', value, newUserData.roleId);
        if(parseInt(value) === 80) {
            //console.log(newUserData.roleId);
            if((parseInt(value) !== parseInt(newUserData.roleId)) && (parseInt(newUserData.roleId) !== 80)) {
                setAlert({tipo: 'warning', msn: 'No puede cambiar un usuario actual a rol de renta', show: true});
                return;
            }
        }
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
        setUI(value);
        loadCompanies(newUserData.clienteId, value);
        loadAccounts(newUserData.empresaId);
    }
    const handleChangeUDN = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        setUI(newUserData.roleId);
        loadCompanies(value, newUserData.roleId);
        loadAccounts(newUserData.empresaId);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }
    const handleChangeCompany = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});

        loadAccounts(value);
        setNewUserData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }
    const handleChanges = (e) => {
        const { id, value } = e.target;
        setAlert({tipo: '', msn: '', show: false});
        
        setNewUserData((prevData) => ({
			...prevData,
			[id]: value
		}));
    }
    const handleChangeMultiAccounts = (e) => {
        let value = Array.from(e.target.selectedOptions, option => option.value);
       
        setNewUserData((prevData) => ({
			...prevData,
			'multicuentas': value
		}));
    }

    const [showCompany, setShowCompany] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [showMultiAccounts, setShowMultiAccounts] = useState(false);
    const [blockDeepChanges, setBlockDeepChanges] = useState(false);

    useEffect( () => {
        //cargamos datos base (udns, roles), y datos del usuario... 
        function getRoles() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_AUTH_ROLES + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const roles = response.roles.map( item => ({
                        id: item.id,
                        value: item.nombre
                    }));
                    setRolesToSelect(roles);
                }
            });
        }
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const udns = response.udns.map( item => ({
                        id: item.idUdn,
                        value: item.descripcion
                    }));
                    setUdnsToSelect(udns);
                }
             });
        }
        function getUser() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_AUTH_USERS + id + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    setNewUserData((prevData) => ({
                        ...prevData,
                        id: response.idUsuario,
                        nombre: response.nombre ? response.nombre : '',
                        correo: response.email ? response.email : '',
                        loginPass: '',
                        opsPass: '',
                        roleId: response.idRole ? response.idRole : 0,
                        clienteId: response.idUdn ? response.idUdn : 0,
                        empresaId: response.idEmpresa ? response.idEmpresa : 0,
                        cuentaId: response.idCuenta ? response.idCuenta : 0,
                    }));
                    setUI(response.idRole);
                    loadCompanies(response.idUdn, response.idRole);
                    if(response.idRole === 80) {
                        //traemos datos de las multi cuentas
                        fetchData(process.env.REACT_APP_ACCOUNT_MULTIACCOUNT + id + "?token=" + userData.token, headers)
                        .then(({ response, error}) => {
                            if (error) {
                                const responseErrors = error.response.data.errors.errors;
                                if (error.response.status === 401) {
                                    localStorage.removeItem('userData');
                                    navigate('/');
                                    return;
                                } else if (responseErrors !== undefined) {
                                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                                } else {
                                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                                }
                            }
                
                            if (response) {
                                const multiCuentasOps = response.map( item => ({
                                    id: item.idCuentaAsignada,
                                    value: item.clabeCuenta
                                }));
                                setMultiAccounts(multiCuentasOps);
                                var multiCuentas = []
                                response.map( item => (
                                    multiCuentas.push(item.idCuentaAsignada)
                                ));
                                setNewUserData((prevData) => ({
                                    ...prevData,
                                    'multicuentas': multiCuentas
                                }));
                                const opsCuentaCobro = response.map( item => ({
                                    id: item.idCuentaAsignada,
                                    value: item.clabe
                                }));
                                setCuentaCobroOps(opsCuentaCobro);
                                setNewUserData((prevData) => ({
                                    ...prevData,
                                    'cuentaCobroId': response[0].idCuentaCobroComision,
                                    'porcentajeCobro': response[0].comisionAbono
                                }));
                            }
                        });
                    } else {
                        if(response.idEmpresa)
                        loadAccounts(response.idEmpresa);
                    }
                }
            });
        }
        getUdns();

        getRoles();
        getUser();
    }, [id, navigate]);

    const setUI = (roleId) => {
        switch (parseInt(roleId)) {
            case 30:
            case 50:
            case 90:
            case 100:
                setBlockDeepChanges(false);
                setShowCompany(true);
                setShowAccount(false);
                setShowMultiAccounts(false);
                return;
            case 60:
            case 98:
                setBlockDeepChanges(false);
                setShowCompany(true);
                setShowAccount(true);
                setShowMultiAccounts(false);
                return;
            case 80:
                setBlockDeepChanges(true);
                setShowCompany(true);
                setShowAccount(false);
                setShowMultiAccounts(true);
                return;
            default:
                setBlockDeepChanges(false);
                setShowCompany(false);
                setShowAccount(false);
                setShowMultiAccounts(false);
                return;
        }
    }

    
    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-8 containerTitleAdmin">
                                            <p className='tilteAdmin'>Actualizar Usuario - {newUserData.nombre}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-admin-card">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <div className="row-form-text5">
                                        <div className="content-label3">
                                            <label className="col-form-label">Seleccione el rol del usuario</label>
                                            <select className="form-select" id="roleId" onChange={handleChangeRole} value={newUserData.roleId} disabled={blockDeepChanges} >
                                                <Options options={rolesToSelect} />
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row-form-text5">
                                        <label className="col-form-label">Seleccione la UDN sobre la que hará operaciones el usuario</label>
                                        <select className="form-select" id="clienteId" onChange={handleChangeUDN} value={newUserData.clienteId} disabled >
                                            <Options options={udnsToSelect} />
                                        </select>
                                    </div>
                                    { showCompany && 
                                        <div className="row-form-text5">
                                            <label className="col-form-label">Seleccione la Empresa de la cual dependerá el nuevo usuario</label>
                                            <select className="form-select" id="empresaId" onChange={handleChangeCompany} value={newUserData.empresaId} disabled={blockDeepChanges} >
                                                <Options options={companiesList} />
                                            </select>
                                        </div>
                                    }
                                    { showAccount && 
                                        <div className="row-form-text5">
                                            <label className="col-form-label">Seleccione la cuenta a operar por parte del nuevo usuario</label>
                                            <select className="form-select" id="cuentaId" onChange={handleChanges} value={newUserData.cuentaId} disabled={blockDeepChanges} >
                                                <Options options={accountsList} />
                                            </select>
                                        </div>
                                    }
                                    { showMultiAccounts && 
                                        <div>
                                            <div className="row-form-text5">
                                                <label className="col-form-label">Datos de la(s) cuenta(s) que operá el usuario</label>
                                                <select className="form-select" id="multicuentas" multiple aria-label="Multiple select example" value={newUserData.multicuentas} onChange={handleChangeMultiAccounts} disabled={blockDeepChanges} >
                                                    <Options options={multiAccounts} />
                                                </select>
                                            </div>

                                            <div className="row-form-text5">
                                                <label className="col-form-label">Cuenta a la cual se realizan los abonos de las comisiones cobradas</label>
                                                <select className="form-select" id="cuentaCobroId" onChange={handleChanges} value={newUserData.cuentaCobroId} disabled={blockDeepChanges} >
                                                    <Options options={cuentaCobroOps} />
                                                </select>
                                            </div>
                                            
                                            <div className="row row-form-text">
                                                <div className="col-lg-7">
                                                    <label className="col-form-label">Porcentaje de comisión</label>
                                                    <input type="text" id="porcentajeCobro" onChange={handleChanges} value={newUserData.porcentajeCobro} className="form-control txtControl" /> 
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row row-form-text">
                                        <div className="col-lg-7">
                                            <label className="col-form-label">Nombre del usuario</label>
                                        </div>
                                        <div className="col-lg-5 content-input2">
                                            <input type="text" className="form-control txtControl" id="nombre" onChange={handleChanges} value={newUserData.nombre} />
                                        </div>
                                    </div>
                                    <div className="row row-form-text">
                                        <div className="col-lg-7">
                                            <label className="col-form-label">Correo electrónico</label>
                                        </div>
                                        <div className="col-lg-5 content-input2">
                                            <input type="text" className="form-control txtControl" id="correo" onChange={handleChanges} value={newUserData.correo} />
                                        </div>
                                    </div>
                                    <div className="row row-form-text">
                                        <div className="col-lg-7">
                                            <label className="col-form-label">Ingrese la contraseña de acceso</label>
                                        </div>
                                        <div className="col-lg-5 content-input2">
                                            <input type="password" className="form-control txtControl" id="loginPass" onChange={handleChanges} value={newUserData.loginPass} placeholder='<Actualizar contraseña>' />
                                        </div>
                                    </div>
                                    <div className="row row-form-text2">
                                        <div className="col-lg-7">
                                            <label className="col-form-label">Ingrese la contraseña de operaciones</label>
                                        </div>
                                        <div className="col-lg-5 content-input2">
                                            <input type="password" className="form-control txtControl" id="opsPass" onChange={handleChanges} value={newUserData.opsPass} placeholder='<Actualizar contraseña>' />
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button disabled={isSaveDataDisabled} type="button" className="btn btn-primary2 btnModal" onClick={saveData}>Guardar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default UpdateUser
