import React from "react";

import imgFondo from "../../assets/img/Edificios2.png";
import imac from "../../assets/img/laptop-sitespay.png";
import iconTesoreria from "../../assets/img/tesoreria.png";
import iconNomina from "../../assets/img/nomina.png";
import iconAtm from "../../assets/img/pos_atms.png";

function ComponenteSolutions() {
  return (
    <div className="overlay2">
      <div className="row space">
        <div className="col-primary">
          <div className="row bg-primary-p">
            <div className="col-sm-6 col-img-mac">
              <img className="imgImac" src={imac} alt="" />
            </div>
            <div className="col-sm-6">
              <div className="bg-text-terminal">
                <p className="text-soluciones">
                  {" "}
                  SOLUCIONES <br />
                  <span className="resaltar-login">
                    {" "}
                    FINANCIERAS INTEGRALES
                  </span>{" "}
                  <br /> PARA LAS
                  <span className="resaltar-login"> NECESIDADES</span> DE <br />{" "}
                  NUESTROS
                  <span className="resaltar-login"> CLIENTES </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-succundar">
          <div className="row space bg-secondary-p">
            <div className="col-4 col-productos">
              <div className="col-content-p text-center">
                <h3 className="form-label-p">
                  CUENTA EMPRESARIAL <br /> PARA MANEJO DE TESORERIA
                </h3>
                <img className="iconEmpresa" src={iconTesoreria} alt="" />
              </div>
            </div>
            <div className="col-4 col-productos text-center">
              <div className="col-content-p">
                <h3 className="form-label-p">
                  {" "}
                  MEDIOS DE PAGOS PARA <br /> NÓMINA
                </h3>
                <img className="iconEmpresa" src={iconNomina} alt="" />
              </div>
            </div>
            <div className="col-4 col-productos text-center">
              <div className="col-content-p">
                <h3 className="form-label-p">
                  SOLUCIONES FINANCIERAS <br /> CON POS / ATMS
                </h3>
                <img className="iconEmpresa" src={iconAtm} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComponenteSolutions;
