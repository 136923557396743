import React, { useState, useEffect } from 'react'
import img from '../../assets/img/logo.jpeg';
import { getInstitutionName } from '../../services/institution';

function TransferAccountsTickets({ data, referenceNumber, transferType, fromAccountName, toAccountName }) {
    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000); // Actualizar cada segundo

        return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
    }, []); // El efecto se ejecuta solo una vez al montar el componente

    const fecha = currentDate.toLocaleDateString();
    const hora = currentDate.toLocaleTimeString();
    const realDestination = data.last_four ? data.last_four : data.destination;

    return (
        <html>
            <head>
                <title>Comprobante de trasferencia SitesPay</title>
                <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous" />
                <link href="./Tickets.css" rel="stylesheet" />
                <style>
                    {`
                    body {
                        font-family: 'Open Sans', sans-serif;
                    }

                    .imgicon{
                        width: 220px;
                    }

                    .card{
                        background-color: #E6E6E6;
                    }

                    .card-ticket {
                        background-color: #fff;
                    }

                    .title-ticket{
                        color: #4184EC;
                    }

                    .card-header {
                        border-radius: 16px;
                        background: #f8f9fa;
                        border-bottom: none;
                        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
                    }

                    .ticket-header{
                        height: 50px;
                        position: relative;
                        z-index: 1;
                        margin-left: -40px; /* Ajustado a margin-left en lugar de padding-left */
                        margin-right: -40px;
                        border-radius: 8px;
                        background: #f8f9fa;
                        box-shadow: -5px 5px 5px rgba(1, 0, 0, 0.15); /* Larger shadow */
                        padding-top: 10px;
                        margin-bottom: 20px;
                    }

                    .text-header-title{
                        margin-left: 40px;
                    }

                    .card-body{
                        margin-left: 60px;
                        margin-right: 60px;
                        margin-top: -2px;
                        border-bottom-right-radius: 16px;
                        border-bottom-left-radius: 16px;
                        box-shadow: -8px 8px 8px rgba(1, 0, 0, 0.25);
                        margin-bottom: 40px;
                    }

                    .card-body h5{
                        color: #4184EC;
                    }

                    .ticket-content p {
                        font-size: 16px;
                        color: #000;
                    }

                    .card-footer h6 {
                        font-size: 22px;
                        color: #4184EC;
                    }

                    .card-footer{
                        height: 70px;
                        display: block;
                        border-top: none;
                        background: #f8f9fa;
                        align-items: center;
                        text-align: center;
                        padding-top: 20px;
                        box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.25);
                    }

                    .text-start{
                        margin-left: 5px;
                    }

                    .row {
                        flex-wrap: nowrap;
                        margin: 0px;
                        padding: 0px;
                    }
                `}
                </style>
            </head>
            <body>
                <div className="card card-ticket">
                    <div className="card-header">
                        <div className="clearfix">
                            <div className='float-center'>
                                <img className="imgicon" src={img} alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="ticket" id='ticket'>
                            {(() => {
                                switch (transferType) {
                                    case 1: return <h5 className='text-center'> Transferencia realizada</h5>;
                                    case 2: return <h5 className='text-center'> Transferencia realizada</h5>;
                                    case 3: return <h5 className='text-center'> Recarga a tarjeta realizada</h5>;
                                    case 4: return <h5 className='text-center'> Transferencia realizada</h5>;
                                    case 5: return <h5 className='text-center'> Transferencia realizada</h5>;
                                    case 6: return <h5 className='text-center'> Transferencia realizada</h5>;
                                    default: return null; // Add a default case to handle other scenarios
                                }
                            })()}
                            <hr></hr>
                            <div className='row'>
                                <div className='col-6'><p className='text-end'>Fecha de operación:</p></div>
                                <div className='col-6'> <p className='text-start'>{fecha}</p> </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'><p className='text-end'>Hora de operación:</p></div>
                                <div className='col-6'> <p className='text-start'>{hora}</p> </div>
                            </div>
                            <div className='row'>
                                <div className='col-6'><p className='text-end'>De la cuenta origen:</p></div>
                                <div className='col-6'><p className='text-start'>{data.origin}</p></div>
                            </div>
                            <div className='row'>
                                <div className='col-6'><p className='text-end'>De la institución bancaria:</p></div>
                                <div className='col-6'><p className='text-start'>{getInstitutionName(data.origin?.substring(0, 3))}</p></div>
                            </div>
                            <div className='row'>
                                <div className='col-6'><p className='text-end'>A nombre de :</p></div>
                                <div className='col-6'>  <p className='text-start'> {fromAccountName} </p> </div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-end'><p className='text-end'>A la cuenta destino:</p></div>
                                <div className='col-6 text-start'><p className='text-start'>{realDestination}</p></div>
                            </div>
                            {(transferType < 3) &&
                                <div>
                                    <div className='row'>
                                        <div className='col-6 text-end'><p className='text-end'>De la institución bancaria:</p></div>
                                        <div className='col-6 text-start'><p className='text-start'>{getInstitutionName(data.destination?.substring(0, 3))}</p></div>
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-6 text-end'><p className='text-end'>A nombre de:</p></div>
                                <div className='col-6 text-start'><p className='text-start'>{toAccountName}</p></div>
                            </div>
                            <div className='row'>
                                <div className='col-6 text-end'><p className='text-end'>Monto a transferir:</p></div>
                                <div className='col-6 text-start'><p className='text-start'>{dollarUS.format(data.amount)}</p></div>
                            </div>
                            {(transferType < 3) &&
                                <div>
                                    <div className='row'>
                                        <div className='col-6 text-end'><p className='text-end'>Concepto:</p></div>
                                        <div className='col-6 text-start'><p className='text-start'>{data.concept}</p></div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6 text-end'><p className='text-end'>Número de referencia:</p></div>
                                        <div className='col-6 text-start'><p className='text-start'>{data.reference} </p></div>
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-6 text-end'><p className='text-end'>Clave de rastreo:</p> </div>
                                <div className='col-6 text-start'><p className='text-start'>{referenceNumber}</p> </div>
                            </div>
                            <hr></hr>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <h6>SitesCapital S.A. DE C.V., SOFOM, E.N.R</h6>
                </div>
                <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>
            </body>
        </html>
    )
}

export default TransferAccountsTickets
