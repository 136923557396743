import React, { useState, useEffect } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import SearchModal from '../../components/Modals/SearchModal'
import Table from '../../components/Table/Table'

import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';

function AccountList() {
    const { id } = useParams();
    const isLogin = true;
    const tipo = "cuentas empresa";

    const [companies, setCompanies] = useState([]);

    const tableHeaders = ['Cuenta Clabe', 'Nombre', 'Empresa','¿En renta?','Cuenta de Cobro'];
    const tableStyles = ['accountStyle', 'textStyle', 'textStyle','textStyle','textStyle','centeredStyle'];

    const selectedOption = "4";

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const navigate = useNavigate();

    const [pagination, setPagination] = useState({
        itemsPerPage: 15,
        totalItems: 1,
        currentPage: 1
    });

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const getCompanies = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + id + "/?token=" + userData.token + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                console.log(response);
                const empresasToSet = response.empresas.map(item => ({
                    rfc: item.rfc,
                    nombre: item.nombre,
                    razonSocial: item.razonSocial,
                    speiInPct: item.speiInPct,
                    speiInCost: item.speiInCosto,
                    speiOutPct: item.speiOutPct,
                    speiOutCost: item.speiOutCosto,
                    id: item.idEmpresa,
                    idClient: item.idCliente
                }));
                setCompanies(empresasToSet);
            }
         });
    };

    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const [selected, setSelectedRow] = useState(null);
    const handleRowSelect = (row) => {
        setSelectedRow(row);
    }

    //Busqueda
    const [searchData, setSearchData] = useState({
        byNumberAccount: '',
        byNameAccount: '',
        byNameCompany: ''
    });

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        //tuve que dejarlo así en lugar de sin parametro y que siempre leyera el estado, porque pareciera que no se actualiza tan rápido el estado
        //además que, en useEffect, si necesitamos el parametro, para que cada vez que se cambie, se busque y se rendere de nuevo
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption}/>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-11 containerTitleAdmin">
                                            <p className='tilteAdmin'>Cuentas dentro de la UDN: {id} </p>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={companies} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} onSelect={handleRowSelect} tipo='admin' from='admin'
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems/pagination.itemsPerPage)}  /> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData}/>
        </div>
    )
}

export default AccountList
