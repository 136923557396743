import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { putData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations'

function Representative({ id, loadData, reload, isPF, tipo }) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [isAddress, setIsAddress] = useState(false);

    const showAddress = () => {
        setIsAddress(true);
    }

    const closeAddress = () => {
        setIsAddress(false);
    }

    const [companyData, setCompanyData] = useState({
        repLegalId: 0,
        personaId: 0,
        domicilioId: 0,
        apellidoPaterno: '',
        apellidoMaterno: '',
        nombre: '',
        celular: '',
        correo: '',
        curp: '',
        entidadNacimiento: 6,
        fechaNacimiento: '',
        idNacionalidad: 1,
        idOcupacion: 115,
        numIdentificacionOf: '',
        rfc: '',
        serieFirmaElect: '',
        telefono: '',
        sexo: 'F',
        tipoIdentificacionOf: '1',

        callePrincipal: '',
        calleSecundaria2: '',
        calleSecundaria3: '',
        ciudad: '',
        codPostal: '',
        colonia: '',
        numExterior: '',
        numInterior: ''
    });

    useEffect( () => {
        if(loadData) {
            var personaObj = {};
            var domicilioObj = {};
            var repLegalIdLoc = 0;
            var personaIdLoc = 0;
            var domicilioIdLoc = 0;
            if(isPF) {
                personaObj = loadData.persona;
                domicilioObj = loadData.domicilio;
                //repLegalIdLoc = loadData.repLegalId;
                personaIdLoc = loadData.personaId;
                domicilioIdLoc = loadData.domicilioId;
            } else {
                personaObj = loadData.repLegal.persona;
                domicilioObj = loadData.repLegal.domicilio;
                repLegalIdLoc = loadData.repLegalId;
                personaIdLoc = loadData.repLegal.personaId;
                domicilioIdLoc = loadData.repLegal.domicilioId;    
            }
            var pattern = /(\d{4})(\d{2})(\d{2})/;
            var newDate = '';
            if(personaObj.fechaNacimiento)
                newDate = personaObj.fechaNacimiento.replace(pattern,'$1-$2-$3');

            setCompanyData({
                repLegalId: repLegalIdLoc,
                personaId: personaIdLoc,
                domicilioId: domicilioIdLoc,

                apellidoPaterno: personaObj.apellidoPaterno ? personaObj.apellidoPaterno : '',
                apellidoMaterno: personaObj.apellidoMaterno ? personaObj.apellidoMaterno : '',
                nombre: personaObj.nombre ? personaObj.nombre : '',
                celular: personaObj.celular ? personaObj.celular : '',
                correo: personaObj.correo ? personaObj.correo : '',
                curp: personaObj.curp ? personaObj.curp : '',
                entidadNacimiento: personaObj.entidadNacimiento ? personaObj.entidadNacimiento : 6,
                fechaNacimiento: newDate, 
                idNacionalidad: personaObj.idNacionalidad ? personaObj.idNacionalidad : 1,
                idOcupacion: personaObj.idOcupacion ? personaObj.idOcupacion : 115,
                numIdentificacionOf: personaObj.numIdentificacionOf ? personaObj.numIdentificacionOf : '',
                rfc: personaObj.rfc ? personaObj.rfc : '',
                serieFirmaElect: personaObj.serieFirmaElect ? personaObj.serieFirmaElect : '',
                telefono: personaObj.telefono ? personaObj.telefono : '',
                sexo: personaObj.sexo ? personaObj.sexo : 'F',
                tipoIdentificacionOf: personaObj.tipoIdentificacionOf ? personaObj.tipoIdentificacionOf : '1',

                callePrincipal: domicilioObj.callePrincipal ? domicilioObj.callePrincipal : '',
                calleSecundaria2: domicilioObj.calleSecundaria2 ? domicilioObj.calleSecundaria2 : '',
                calleSecundaria3: domicilioObj.calleSecundaria3 ? domicilioObj.calleSecundaria3 : '',
                ciudad: domicilioObj.ciudad ? domicilioObj.ciudad : '',
                codPostal: domicilioObj.codPostal ? domicilioObj.codPostal : '',
                colonia: domicilioObj.colonia ? domicilioObj.colonia : '',
                numExterior: domicilioObj.numExterior ? domicilioObj.numExterior : '',
                numInterior: domicilioObj.numInterior ? domicilioObj.numInterior : ''
            });
        }
    }, [isPF, loadData]);

    const handleOnChange = (e) => {
		const { id, value } = e.target;

        setCompanyData((prevData) => ({
			...prevData,
			[id]: value
		}));
	};
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const saveData = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
        var dataToUpdate = {};
        var urlToPut = '';
        var update = 'Representante Legal';
        if(isPF)
            update = 'Persona Física';
        var idToUpdate = 0;
        if(isAddress) {
            if(errorIn.lengthTrim(companyData.callePrincipal, 1, 100, 'Error en calle principal')) {
                setAlert({tipo: 'danger', msn: 'Error en calle principal', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.ciudad, 1, 100, 'Error en ciudad')) {
                setAlert({tipo: 'danger', msn: 'Error en ciudad', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.codPostal, 1, 5, 'Error en codigo postal')) {
                setAlert({tipo: 'danger', msn: 'Error en código postal', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.colonia, 1, 400, 'Error en colonia')) {
                setAlert({tipo: 'danger', msn: 'Error en colonia', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.numExterior, 1, 30, 'Error en número exterior')) {
                setAlert({tipo: 'danger', msn: 'Error en número exterior', show: true});
                return;
            }
            dataToUpdate = {
                id: companyData.domicilioId,
                callePrincipal: companyData.callePrincipal,
                calleSecundaria2: companyData.calleSecundaria2,
                calleSecundaria3: companyData.calleSecundaria3,
                ciudad: companyData.ciudad,
                codPostal: companyData.codPostal,
                colonia: companyData.colonia,
                numExterior: companyData.numExterior,
                numInterior: companyData.numInterior
            }
            urlToPut = process.env.REACT_APP_ADMIN_ADDRESS;
            idToUpdate = companyData.domicilioId;
            update = 'Domicilio';
        } else {
            if(errorIn.lengthTrim(companyData.apellidoPaterno, 1, 45, 'Error en ApellidoPaterno')) {
                setAlert({tipo: 'danger', msn: 'Error en ApellidoPaterno', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.apellidoMaterno, 1, 45, 'Error en ApellidoMaterno')) {
                setAlert({tipo: 'danger', msn: 'Error en ApellidoPaterno', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.nombre, 1, 45, 'Error en nombre')) {
                setAlert({tipo: 'danger', msn: 'Error en nombre', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.celular, 8, 16, 'Error en celular')) {
                setAlert({tipo: 'danger', msn: 'Error en celular', show: true});
                return;
            }
            if(errorIn.email(companyData.correo, 'Error en correo')) {
                setAlert({tipo: 'danger', msn: 'Error en correo', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.curp, 1, 18, 'Error en curp')) {
                setAlert({tipo: 'danger', msn: 'Error en curp', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.fechaNacimiento, 8, 10, 'Error en fechaNacimiento')) {
                setAlert({tipo: 'danger', msn: 'Error en fechaNacimiento', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.numIdentificacionOf, 1, 50, 'Error en numIdentificacionOf')) {
                setAlert({tipo: 'danger', msn: 'Error en identificación oficial', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.rfc, 12, 13, 'Error en rfc')) {
                setAlert({tipo: 'danger', msn: 'Error en rfc', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.telefono, 8, 16, 'Error en telefono')) {
                setAlert({tipo: 'danger', msn: 'Error en teléfono', show: true});
                return;
            }
            if(errorIn.lengthTrim(companyData.serieFirmaElect, 1, 50, 'Error en serieFirmaElect')) {
                setAlert({tipo: 'danger', msn: 'Error en serieFirmaElect', show: true});
                return;
            }

            dataToUpdate = {
                id: companyData.personaId,
                apellidoPaterno: companyData.apellidoPaterno,
                apellidoMaterno: companyData.apellidoMaterno,
                nombre: companyData.nombre,
                celular: companyData.celular,
                correo: companyData.correo,
                curp: companyData.curp,
                entidadNacimiento: companyData.entidadNacimiento,
                fechaNacimiento: companyData.fechaNacimiento,
                idNacionalidad: companyData.idNacionalidad,
                idOcupacion: companyData.idOcupacion,
                numIdentificacionOf: companyData.numIdentificacionOf,
                rfc: companyData.rfc,
                serieFirmaElect: companyData.serieFirmaElect,
                telefono: companyData.telefono,
                sexo: companyData.sexo,
                tipoIdentificacionOf: companyData.tipoIdentificacionOf,
            }
            urlToPut = process.env.REACT_APP_ADMIN_PERSONS;
            idToUpdate = companyData.personaId;

        }
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        putData(urlToPut + idToUpdate + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                setAlert({tipo: 'success', msn: update + ' actualizado correctamente', show: true});
                reload();
            }
        });
    }

    return (
        <div>
            <div className='row bg-title-admin'>
                <div className='col-sm-5'>
                    {isAddress ? (
                        <h5 className="title-admin">Dirección de la {tipo} </h5>
                    ):(
                        <h5 className="title-admin">Datos de la  {tipo}</h5>
                    )}
                </div>
                <div className='col-sm-7'>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        {!isAddress ? (
                            <button className="btn btn-secondary" type="button" onClick={showAddress}>Dirección</button>
                        ) : (
                            <button className="btn btn-secondary" type="button" onClick={closeAddress}> {tipo} </button>
                        )}
                        <button className="btn btn-success" onClick={saveData} type="button">Guardar</button>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className='container-admin'>
                <Message alert={alert} closeAlert={closeAlert} />
                {!isAddress ?
                    (
                        <div>
                            <div className='row row-admin'>
                                <div className='col-md-4 col-admin'>
                                    <label className="col-form-label-admin">Nombre</label>
                                    <input type="text" className="form-control txtControl" id="nombre" value={companyData.nombre} onChange={handleOnChange} />
                                </div>
                                <div className='col-md-4'>
                                    <label className="col-form-label-admin">Apellido Paterno</label>
                                    <input type="text" className="form-control txtControl" id="apellidoPaterno" value={companyData.apellidoPaterno} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-4'>
                                    <label className="col-form-label-admin">Apellido Materno</label>
                                    <input type="text" className="form-control txtControl" id="apellidoMaterno" value={companyData.apellidoMaterno} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-4 col-admin'>
                                    <label className="col-form-label-admin">Fecha de nacimiento</label>
                                    <input type="date" className="form-control txtControl" id="fechaNacimiento" value={companyData.fechaNacimiento} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-4'>
                                    <label className="col-form-label-admin">Teléfono</label>
                                    <input type="text" className="form-control txtControl" id="telefono" value={companyData.telefono} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-4'>
                                    <label className="col-form-label-admin">Correo electrónico</label>
                                    <input type="text" className="form-control txtControl" id="correo" value={companyData.correo} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">RFC</label>
                                    <input type="text" className="form-control txtControl" id="rfc" value={companyData.rfc} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">CURP</label>
                                    <input type="text" className="form-control txtControl" id="curp" value={companyData.curp} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Celular</label>
                                    <input type="text" className="form-control txtControl" id="celular" value={companyData.celular} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">Género</label>
                                    <select className="form-select txtControl" id="sexo" value={companyData.sexo} onChange={handleOnChange}>
                                        <option value="F">FEMENINO</option>
                                        <option value="M">MASCULINO</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Nacionalidad</label>
                                    <select className="form-select txtControl" id="idNacionalidad" value={companyData.idNacionalidad} onChange={handleOnChange}>
                                        <option value="1">MEXICANO</option>
                                        <option value="14">ARABE</option>
                                        <option value="22">ARGENTINO</option>
                                        <option value="42">BOLIVIANO</option>
                                        <option value="28">BRASILEÑO</option>
                                        <option value="36">BULGARA</option>
                                        <option value="34">CANADIENSE</option>
                                        <option value="21">CHILENO</option>
                                        <option value="31">CHINA</option>
                                        <option value="24">COLOMBIANA</option>
                                        <option value="8">COSTARRICENSE</option>
                                        <option value="12">CUBANO</option>
                                        <option value="29">DOMINICANO</option>
                                        <option value="33">ECUATORIANO</option>
                                        <option value="13">ESPAÑOL</option>
                                        <option value="5">ESTADOUNIDENSE</option>
                                        <option value="20">FRANCESA</option>
                                        <option value="16">GUATEMALA</option>
                                        <option value="11">GUATEMALTECO</option>
                                        <option value="10">HONDUREÑO</option>
                                        <option value="19">IRAQUI</option>
                                        <option value="37">ISRAELI</option>
                                        <option value="18">ITALIANO</option>
                                        <option value="35">JAPONES</option>
                                        <option value="30">NICARAGUENSE</option>
                                        <option value="3">PALESTINO</option>
                                        <option value="26">PANAMEÑO</option>
                                        <option value="15">PERUANO</option>
                                        <option value="25">PORTORRIQUEÑO</option>
                                        <option value="32">REPUBLICA DE COREA</option>
                                        <option value="2">SALVADOREÑO</option>
                                        <option value="23">SUIZO</option>
                                        <option value="9">URUGUAYO</option>
                                        <option value="27">VENEZOLANO</option>
                                    </select>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">Entidad de nacimiento</label>
                                    <select className="form-select txtControl" id="entidadNacimiento" value={companyData.entidadNacimiento} onChange={handleOnChange} >
                                        <option value="6">AGUASCALIENTES</option>
                                        <option value="2">BAJA CALIFORNIA</option>
                                        <option value="10">BAJA CALIFORNIA SUR</option>
                                        <option value="86">CAMPECHE</option>
                                        <option value="83">CHIAPAS</option>
                                        <option value="3">CHIHUAHUA</option>
                                        <option value="91">CIUDAD DE MEXICO</option>
                                        <option value="58">COAHUILA DE ZARAGOZA</option>
                                        <option value="32">COLIMA</option>
                                        <option value="54">DURANGO</option>
                                        <option value="47">ESTADO DE MEXICO</option>
                                        <option value="36">GUANAJUATO</option>
                                        <option value="38">GUERRERO</option>
                                        <option value="42">HIDALGO</option>
                                        <option value="28">JALISCO</option>
                                        <option value="40">MICHOACAN DE OCAMPO</option>
                                        <option value="50">MORELOS</option>
                                        <option value="30">NAYARIT</option>
                                        <option value="60">NUEVO LEON</option>
                                        <option value="76">OAXACA</option>
                                        <option value="52">PUEBLA</option>
                                        <option value="44">QUERETARO</option>
                                        <option value="90">QUINTANA ROO</option>
                                        <option value="34">SAN LUIS POTOSI</option>
                                        <option value="18">SINALOA</option>
                                        <option value="9">SONORA</option>
                                        <option value="84">TABASCO</option>
                                        <option value="65">TAMAULIPAS</option>
                                        <option value="46">TLAXCALA</option>
                                        <option value="73">VERACRUZ DE IGNACIO DE LA LLAVE</option>
                                        <option value="88">YUCATAN</option>
                                        <option value="24">ZACATECAS</option>
                                        <option value="92">NACIONALIDAD EXTRANJERA</option>
                                    </select>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Tipo de Identificación</label>
                                    <select className="form-select txtControl" id="tipoIdentificacionOf" value={companyData.tipoIdentificacionOf} onChange={handleOnChange}>
                                        <option value="1">INE</option>
                                        <option value="3">CARTILLA MILITAR</option>
                                        <option value="4">CEDULA PROFESIONAL</option>
                                        <option value="8">DOCUMENTO MIGRATORIO</option>
                                        <option value="7">INAPAM</option>
                                        <option value="6">LICENCIA DE CONDUCIR</option>
                                        <option value="5">OTRO</option>
                                        <option value="2">PASAPORTE</option>
                                    </select>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">Número de Identificación</label>
                                    <input type="text" className="form-control txtControl" id="numIdentificacionOf" value={companyData.numIdentificacionOf} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Ocupación</label>
                                    <select className="form-select txtControl" id="idOcupacion" value={companyData.idOcupacion} onChange={handleOnChange}>
                                        <option value="115">ABARROTERO</option>
                                        <option value="265">ABOGADO</option>
                                        <option value="159">ACCS AUTOMOTROCICES</option>
                                        <option value="259">ACUACULTOR</option>
                                        <option value="80">ADMINISTRADOR</option>
                                        <option value="254">AGENCIAS DE SEGURIDAD</option>
                                        <option value="262">AGENTE ADUANAL</option>
                                        <option value="266">AGENTE DE BOLSA</option>
                                        <option value="263">AGENTE INMOBILIARIO</option>
                                        <option value="131">AGRICULTOR</option>
                                        <option value="3">ALBAÑIL</option>
                                        <option value="202">ALMACENISTA</option>
                                        <option value="4">AMA DE CASA</option>
                                        <option value="192">ANUNCIOS PUBLICITARIOS</option>
                                        <option value="5">APICULTURISTA</option>
                                        <option value="135">ARQUITECTO</option>
                                        <option value="6">ARRENDATARIO</option>
                                        <option value="7">ARTESANO</option>
                                        <option value="177">ARTICULOS D LIMPIEZA</option>
                                        <option value="8">ASESOR</option>
                                        <option value="172">AUTO DETALLADO </option>
                                        <option value="91">AVIADOR</option>
                                        <option value="136">BIENES RAICES</option>
                                        <option value="36">BLOQUERO</option>
                                        <option value="138">BOLERO</option>
                                        <option value="245">BOLSAS DE PLASTICO</option>
                                        <option value="137">BOMBERO</option>
                                        <option value="193">BOTANICA</option>
                                        <option value="150">CANTO</option>
                                        <option value="211">CARGADOR DE MATERIAL</option>
                                        <option value="9">CARNICERO</option>
                                        <option value="219">CARPINTERIA </option>
                                        <option value="11">CARROCERO</option>
                                        <option value="12">CERRAJERO</option>
                                        <option value="20">CHOFER</option>
                                        <option value="18">COCINERA</option>
                                        <option value="221">COM Y VTA MARISCOS</option>
                                        <option value="147">COMERCIANTE</option>
                                        <option value="1">COMERCIANTE</option>
                                        <option value="13">CONFECCIONISTA</option>
                                        <option value="14">CONSECIONARIO</option>
                                        <option value="196">CONSTRUCCION</option>
                                        <option value="15">CONSULTOR</option>
                                        <option value="120">CONSULTORA MARY KAY</option>
                                        <option value="16">CONTADOR</option>
                                        <option value="17">CONTRATISTA</option>
                                        <option value="157">COODRINADOR  EVENTOS</option>
                                        <option value="129">COOPERATIVA ESCOLAR</option>
                                        <option value="267">CORREDOR PUBLICO</option>
                                        <option value="133">COSMETOLOGA</option>
                                        <option value="19">COSTURERA</option>
                                        <option value="253">CRIADOR</option>
                                        <option value="132">CULTORA DE BELLEZA</option>
                                        <option value="142">DECORADOR</option>
                                        <option value="21">DENTISTA</option>
                                        <option value="22">DIBUJANTE</option>
                                        <option value="203">DISEÑADOR</option>
                                        <option value="187">DIST  LINAZA</option>
                                        <option value="101">DIST INDEP ARDYSS</option>
                                        <option value="88">DISTRIBUIDOR</option>
                                        <option value="77">ECONOMISTA</option>
                                        <option value="146">EDITOR</option>
                                        <option value="104">EDUCADORA</option>
                                        <option value="200">ELAB DE PIÑATAS</option>
                                        <option value="151">ELECTRICISTA</option>
                                        <option value="23">ELECTRICO</option>
                                        <option value="226">ELECTRODOMESTICOS</option>
                                        <option value="206">ELECTROMECANICO</option>
                                        <option value="24">ELOTERO</option>
                                        <option value="25">EMPLEADO</option>
                                        <option value="26">EMPRESARIA</option>
                                        <option value="42">EMPRESARIO</option>
                                        <option value="210">ENCARG  LIMPIADURIA</option>
                                        <option value="154">ENFERMERA</option>
                                        <option value="27">ENTRENADOR</option>
                                        <option value="82">ESC DE PERROS</option>
                                        <option value="222">ESTANCIA INFANTIL</option>
                                        <option value="28">ESTILISTA</option>
                                        <option value="29">ESTUDIANTE</option>
                                        <option value="118">EXPORTADOR</option>
                                        <option value="239">FABRI EQU TORTILLAS</option>
                                        <option value="122">FARMACEUTICA</option>
                                        <option value="30">FERRETERO</option>
                                        <option value="31">FLORISTA</option>
                                        <option value="32">FOTOGRAFA</option>
                                        <option value="100">FOTOGRAFO</option>
                                        <option value="191">FRUTERO</option>
                                        <option value="33">FUMIGADOR</option>
                                        <option value="207">FUNDICION EN GENERAL</option>
                                        <option value="163">GANADERO</option>
                                        <option value="158">GRABACION DE VIDEOS</option>
                                        <option value="228">GUARDIA DE SEGURIDAD</option>
                                        <option value="34">HERRERO</option>
                                        <option value="111">IMPORTADOR</option>
                                        <option value="35">IMPRESOR</option>
                                        <option value="230">INDUSTRIA</option>
                                        <option value="37">INGENIERO</option>
                                        <option value="38">INSTRUCTOR</option>
                                        <option value="117">INSTRUCTOR DE KARAT</option>E
                                        <option value="215">INSTRUCTORA</option>
                                        <option value="251">JARDIN DE NIÑOS</option>
                                        <option value="39">JARDINERO</option>
                                        <option value="213">JOYERIA</option>
                                        <option value="40">JOYERO</option>
                                        <option value="41">JUBILADO</option>
                                        <option value="103">JUGUETES Y ART BEBES</option>
                                        <option value="162">LACTEOS</option>
                                        <option value="201">LADRILLERO</option>
                                        <option value="165">LAVADO DE AUTOS </option>
                                        <option value="241">LIBRERIA</option>
                                        <option value="185">LIMPIEZA EN GENERAL</option>
                                        <option value="44">LLANTERO</option>
                                        <option value="46">MAESTRA</option>
                                        <option value="47">MAESTRO</option>
                                        <option value="183">MANT CASCOS BOTES</option>
                                        <option value="234">MANTENIMIENTO</option>
                                        <option value="55">MANUALIDADES</option>
                                        <option value="252">MAQUEADOR</option>
                                        <option value="93">MARINO</option>
                                        <option value="83">MARISQUERO</option>
                                        <option value="175">MARMOLERO</option>
                                        <option value="212">MASAJISTA</option>
                                        <option value="45">MECANICO</option>
                                        <option value="255">MECANICO DE AVIACION</option>
                                        <option value="49">MEDICO</option>
                                        <option value="81">MILITAR</option>
                                        <option value="50">MODISTA</option>
                                        <option value="140">MOFLERO</option>
                                        <option value="51">MUSICO</option>
                                        <option value="197">NEGOCIO DE  NIEVE </option>
                                        <option value="52">NIÑERA</option>
                                        <option value="264">NOTARIO PUBLICO</option>
                                        <option value="79">OBRERO</option>
                                        <option value="53">OFTALMICO</option>
                                        <option value="54">OFTOMETRISTA</option>
                                        <option value="188">OMNI LIFE</option>
                                        <option value="126">PALAPERO</option>
                                        <option value="258">PALETERO</option>
                                        <option value="56">PANADERO</option>
                                        <option value="144">PAPELERIA Y REGALOS</option>
                                        <option value="57">PASTELERO</option>
                                        <option value="58">PEDICURISTA</option>
                                        <option value="59">PELUQUERO</option>
                                        <option value="271">PENSIONADO</option>
                                        <option value="270">PERIODISTA</option>
                                        <option value="240">PESCA DEPORTIVA</option>
                                        <option value="60">PESCADOR</option>
                                        <option value="269">PILOTO</option>
                                        <option value="48">PINTOR</option>
                                        <option value="61">PINTORA</option>
                                        <option value="216">PLANCHADO DE ROPA</option>
                                        <option value="62">PLOMERO</option>
                                        <option value="99">POLICIA</option>
                                        <option value="76">PRESTADOR DE SERV.</option>
                                        <option value="123">PRODUCTOS SYANHOME </option>
                                        <option value="64">PROFESOR</option>
                                        <option value="128">PUBLICISTA</option>
                                        <option value="273">PUBLICO GENERAL</option>
                                        <option value="143">QUIMICO</option>
                                        <option value="248">RECICLAJE</option>
                                        <option value="233">RECOLECTOR DE DESECH</option>
                                        <option value="246">REMODELADOR DE CASAS</option>
                                        <option value="220">RENTA DE DISCO MOVIL</option>
                                        <option value="166">RENTA DE VIDEOS</option>
                                        <option value="176">REP DE ART MUSICALES</option>
                                        <option value="125">REP DE COMPUTADORAS</option>
                                        <option value="139">REPARACION D CALZADO</option>
                                        <option value="65">REPOSTERO</option>
                                        <option value="66">RESTAURANTERO</option>
                                        <option value="167">ROPA EN TIANGUIS</option>
                                        <option value="184">ROTULOS</option>
                                        <option value="145">SECRETARIA</option>
                                        <option value="78">SEGURIDAD CANINA</option>
                                        <option value="156">SERIGRAFISTA</option>
                                        <option value="43">SERV. LIMPIEZA</option>
                                        <option value="67">SERV.LAVADERIA</option>
                                        <option value="63">SERVICIO DE COMIDA</option>
                                        <option value="102">SERVICIO DE INTERNET</option>
                                        <option value="110">SERVICIO DE VIDEOJUE</option>
                                        <option value="68">SERVICIOS</option>
                                        <option value="134">SERVICIOS A ACUARIOS</option>
                                        <option value="169">SERVICIOS FUNERARIOS</option>
                                        <option value="148">SERVICIOS TELEFONICO</option>
                                        <option value="261">SILVICULTOR</option>
                                        <option value="69">SOLDADOR</option>
                                        <option value="107">SUPERVISOR DE OBRA</option>
                                        <option value="272">SUPERVISOR FLOTILLA</option>
                                        <option value="231">TACOS VARIOS</option>
                                        <option value="250">TALLER DE BICICLETAS</option>
                                        <option value="70">TAPICERO</option>
                                        <option value="71">TAQUERO</option>
                                        <option value="72">TAXISTA</option>
                                        <option value="113">TEC EN INFORMATICA</option>
                                        <option value="73">TECNICO</option>
                                        <option value="204">TECNICO ELECTRONICA</option>
                                        <option value="182">TOPOGRAFO</option>
                                        <option value="214">TUPPERWARE</option>
                                        <option value="92">TORNERO</option>
                                        <option value="74">TORTILLERO</option>
                                        <option value="2">TRANSPORTISTA</option>
                                        <option value="89">VENDEDOR AMBULANTE</option>
                                        <option value="116">VENDEDOR DE COMIDA</option>
                                        <option value="90">VENDEDOR ESTABLECIDO</option>
                                        <option value="174">VENDEDOR ONMILIFE</option>
                                        <option value="112">VENDEDORA DE JOYERIA</option>
                                        <option value="161">VENTA DE ALARMAS </option>
                                        <option value="186">VENTA DE AUTOS USADOS</option>
                                        <option value="209">VENTA DE AVON</option>
                                        <option value="217">VENTA DE BIRRIA</option>
                                        <option value="98">VENTA DE CALZADO</option>
                                        <option value="238">VENTA DE CHATARRA</option>
                                        <option value="229">VENTA DE COYOTAS</option>
                                        <option value="164">VENTA DE DONAS </option>
                                        <option value="198">VENTA DE DULCES</option>
                                        <option value="94">VENTA DE HERBALIFE</option>
                                        <option value="194">VENTA DE HERBAX</option>
                                        <option value="249">VENTA DE HUEVOS</option>
                                        <option value="227">VENTA DE LLANTAS</option>
                                        <option value="256">VENTA DE LONAS</option>
                                        <option value="121">VENTA DE LOTERIA</option>
                                        <option value="179">VENTA DE PAÑALES</option>
                                        <option value="108">VENTA DE PERFUMERIA</option>
                                        <option value="247">VENTA DE PESCADO</option>
                                        <option value="180">VENTA DE PINTURAS</option>
                                        <option value="224">VENTA DE PIZZAS</option>
                                        <option value="257">VENTA DE TIERRA</option>
                                        <option value="105">VENTA DE TORTAS</option>
                                        <option value="218">VENTA DE UNIFORMES</option>
                                        <option value="181">VENTA DE VITALITY</option>
                                        <option value="97">VENTA POR CATALOGO</option>
                                        <option value="232">VENTA TACOS VARIOS</option>
                                        <option value="190">VETERINARIO</option>
                                        <option value="260">VITIVINICULTOR</option>
                                        <option value="106">VTA  VIDRIOS Y VNAS</option>
                                        <option value="242">VTA ACC PARA PASTEL</option>
                                        <option value="130">VTA COMIDA ESCUELA</option>
                                        <option value="149">VTA DE JUGOS NATURAL</option>
                                        <option value="223">VTA DE PIZZAS</option>
                                        <option value="205">VTA DE PROD PLASTICO</option>
                                        <option value="171">VTA DE ROPA</option>
                                        <option value="114">VTA DE ROPA EN CASA</option>
                                        <option value="124">VTA MADAME CHANTAL</option>
                                        <option value="235">VTABATERIAS D COCINA</option>
                                        <option value="75">ZAPATERO</option>
                                    </select>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">Serie Firma Electrónica</label>
                                    <input type="text" className="form-control txtControl" id="serieFirmaElect" value={companyData.serieFirmaElect} onChange={handleOnChange}/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Calle Principal</label>
                                    <input type="text" className="form-control txtControl" id="callePrincipal" value={companyData.callePrincipal} onChange={handleOnChange} />
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label-admin">Número Exterior</label>
                                    <input type="text" className="form-control txtControl" id="numExterior" value={companyData.numExterior} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label-admin">Número Interior</label>
                                    <input type="text" className="form-control txtControl" id="numInterior" value={companyData.numInterior} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Colonia</label>
                                    <input type="text" className="form-control txtControl" id="colonia" value={companyData.colonia} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label-admin">Ciudad</label>
                                    <input type="text" className="form-control txtControl" id="ciudad" value={companyData.ciudad} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-3'>
                                    <label className="col-form-label-admin">Código Postal</label>
                                    <input type="text" className="form-control txtControl" id="codPostal" value={companyData.codPostal} onChange={handleOnChange}/>
                                </div>
                            </div>
                            <div className='row row-admin'>
                                <div className='col-md-6 col-admin'>
                                    <label className="col-form-label-admin">Entre calle</label>
                                    <input type="text" className="form-control txtControl" id="calleSecundaria2" value={companyData.calleSecundaria2} onChange={handleOnChange}/>
                                </div>
                                <div className='col-md-6'>
                                    <label className="col-form-label-admin">Y calle</label>
                                    <input type="text" className="form-control txtControl" id="calleSecundaria3" value={companyData.calleSecundaria3} onChange={handleOnChange}/>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Representative
