import React, { useState } from 'react'
import '../../css/forms.css'

import Modal from 'react-bootstrap/Modal';
import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter'
import { useNavigate } from 'react-router-dom';
import * as errorIn from '../../utils/validations';
import Message from '../../components/Message/Message'

function NewUdns({ showAdd, handleCloseAdd, reload }) {
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const navigate = useNavigate();

    const [udnData, setUdnData] = useState({
        udnDescripcion: '',
        rfc: '',
        nombreCorto: '',
        razonSocial: '',
        costoFijoSpeiIn: '',
        costoPctSpeiIn: '',
        costoFijoSpeiOut: '',
        costoPctSpeiOut: '',
        esDistribuidor: 1,
        nombrePromotor: '',
        nombreResponsable: '',
        telResponsable: '',
        emailResponsable: '',
        nota: ''
    });

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'costoFijoSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            if (value !== '' && errorIn.integer(value)) {
                e.target.value = udnData[id] || '';
                return;
            }
        }

        if (id === 'costoPctSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = udnData[id] || '';
            return;
        }

        if (id === 'costoPctSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = udnData[id] || '';
            return;
        }

        if (id === 'costoFijoSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = udnData[id] || '';
            return;
        }

        setUdnData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    };

    const validateTransactionData = () => {
        const errors = [];
        if (errorIn.length(udnData.rfc, 12, 13, "RFC inválido")) errors.push("RFC inválido");
        if (errorIn.length(udnData.nombreCorto, 2, 100, "Nombre Comercial inválido")) errors.push("Nombre comercial inválido");
        if (errorIn.length(udnData.razonSocial, 2, 255, "Razón Social inválida")) errors.push("Razón Social inválida");
        if (errorIn.money(udnData.costoFijoSpeiIn, "Costo Fijo Spei In inválido")) errors.push("Error en campo: Costo Fijo Spei In");
        if (errorIn.money(udnData.costoPctSpeiIn, "Costo Porcentual Spei In inválido")) errors.push("Error en campo: Costo Porcentual Spei In");
        if (errorIn.money(udnData.costoFijoSpeiOut, "Costo Fijo Spei Out inválido")) errors.push("Error en campo: Costo Fijo Spei Out");
        if (errorIn.money(udnData.costoPctSpeiOut, "Costo Porcentual Spei Out inválido")) errors.push("Error en campo: Costo Porcentual Spei Out");

        if (errorIn.length(udnData.nombrePromotor, 2, 100, "Nombre del Promotor inválido")) errors.push("Nombre del Promotor inválido");
        if (errorIn.length(udnData.nombreResponsable, 2, 100, "Nombre del Responsable inválido")) errors.push("Nombre del Responsable inválido");
        if (errorIn.lengthTrim(udnData.telResponsable, 1, 16, "Numero de Teléfono inválido")) errors.push("Numero de Teléfono inválido");
        if (errorIn.length(udnData.emailResponsable, 2, 250, "Correo invalido")) errors.push("Correo invalido");
        if (errorIn.length(udnData.nota, 1, 2048, "Nota inválida")) errors.push("Nota inválida");

        if (errors.length > 0) {
            setAlert({ tipo: 'danger', msn: errors.join('\n'), show: true });
            return true;
        } else {
            setAlert({ tipo: '', msn: '', show: false });
            return false;
        }
    };

    const resetData = () => {
        setUdnData({
            udnDescripcion: '',
            rfc: '',
            nombreCorto: '',
            razonSocial: '',
            costoFijoSpeiIn: '',
            costoPctSpeiIn: '',
            costoFijoSpeiOut: '',
            costoPctSpeiOut: '',
            esDistribuidor: 1,
            nombrePromotor: '',
            nombreResponsable: '',
            telResponsable: '',
            emailResponsable: '',
            nota: '',
        });
    };

    const handleAddUdn = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        const data = {
            udnDescripcion: udnData.udnDescripcion,
            rfc: udnData.rfc,
            nombreCorto: udnData.nombreCorto,
            razonSocial: udnData.razonSocial,
            costoFijoSpeiIn: udnData.costoFijoSpeiIn,
            costoPctSpeiIn: udnData.costoPctSpeiIn,
            costoFijoSpeiOut: udnData.costoFijoSpeiOut,
            costoPctSpeiOut: udnData.costoPctSpeiOut,
            esDistribuidor: udnData.esDistribuidor, // ó 1 
            nombrePromotor: udnData.nombrePromotor,
            nombreResponsable: udnData.nombreResponsable,
            telResponsable: udnData.telResponsable,
            emailResponsable: udnData.emailResponsable,
            nota: udnData.nota
        };

        //console.log(data);

        if (validateTransactionData())
            return;

        const payObjStr = JSON.stringify(data);
        const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, payObjStr);
        const body = { data: payment };

        postData(process.env.REACT_APP_ADMIN_CLIENTS + "?token=" + userData.token, body, headers)
            .then(({ response, error }) => {
                if (response) {
                    resetData();
                    handleCloseAdd();
                    closeAlert();
                    reload();
                }
                if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (error.response.status === 406) {
                        setAlert({ tipo: 'danger', msn: 'Contraseña de operación o datos incorrectos', show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: error.response.data.errors.errorMessage, show: true });
                    }
                    return;
                }
            });
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const handleExitButtonClick = () => {
        resetData();
        handleCloseAdd();
        closeAlert();
    };

    return (
        <div>
            <Modal show={showAdd} onHide={() => { handleExitButtonClick(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Unidad de Negocio</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula2">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <br />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Datos generales</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre UDN</label>
                                <input type="text" id='udnDescripcion' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre del Cliente</label>
                                <input type="text" id='nombreCorto' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">RFC Empresa</label>
                                <input type="text" id='rfc' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Razón social Empresa</label>
                                <input type="text" id='razonSocial' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                        <hr />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Datos del responsable</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre del promotor</label>
                                <input type="text" id='nombrePromotor' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre del responsable</label>
                                <input type="text" id='nombreResponsable' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Teléfono del responsable</label>
                                <input type="text" id='telResponsable' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Email del responsable</label>
                                <input type="text" id='emailResponsable' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="content-inputs2">
                            <label className="col-form-label">Notas</label>
                            <textarea className="form-control txtControl" placeholder="Escribir una nota" id="nota" onChange={handleChange}></textarea>

                        </div>
                        <hr />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Comisiones generales</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Costo de SPEI IN</label>
                                <input type="text" id='costoFijoSpeiIn' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Porcentaje de SPEI IN</label>
                                <input type="text" id='costoPctSpeiIn' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="row row-form-texts2">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Costo de SPEI OUT</label>
                                <input type="text" id='costoFijoSpeiOut' className="form-control txtControl" onChange={handleChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Porcentaje de SPEI OUT</label>
                                <input type="text" id='costoPctSpeiOut' className="form-control txtControl" onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={handleAddUdn}>Confirmar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NewUdns
