import React, { useEffect, useState/* , useRef */ } from 'react'
import { postData } from '../../services/Request';
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';

function AdditionalInformation({ data, onChange, show, handleClose }) {
    const navigate = useNavigate();
    const [idExterno, setIdExterno] = useState('');
    const [comentarios, setComentarios] = useState('');

    useEffect(() => {
        if (data && data.info) {
            setIdExterno(data.info.idExterno);
            setComentarios(data.info.comentarios);
        }
    }, [data]);

    const handleSaveAddInfo = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } };

        const addInfo = {
            claveRastreo: data.info.claveRastreo,
            operacion: data.info.operacion,
            idExterno: idExterno,
            comentarios: comentarios
        };

        const addInfoStr = JSON.stringify(addInfo);
        const body = { data: Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, addInfoStr) }

        postData(process.env.REACT_APP_ACCOUNT_EXTRAS + "?token=" + userData.token, body, headers)
            .then(({ response, error }) => {
                if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                    return;
                }

                if (response) {
                    onChange(data.index, { claveRastreo: data.info.claveRastreo, comentarios: comentarios, idExterno: idExterno, operacion: data.info.operacion });
                    handleClose();
                }
            });
    };

    const handleDismiss = () => {
        setIdExterno('');
        setComentarios('');
    };

    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); handleDismiss(); }} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Ingresar datos adicionales (opcional)</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className="row row-form-text">
                            <div className="col-4 content-label">
                                <label className="col-form-label">ID Externo:</label>
                            </div>
                            <div className="col-8 content-input">
                                <input type="text" className="form-control txtControl" value={idExterno} onChange={(e) => setIdExterno(e.target.value)} maxLength='50' />
                            </div>
                        </div>

                        <div className="row row-form-text">
                            <div className="col-4 content-label">
                                <label className="col-form-label">Comentarios:</label>
                            </div>
                            <div className="col-8 content-input ">
                                <input type="text" className="form-control txtControl" value={comentarios} onChange={(e) => setComentarios(e.target.value)} maxLength='250' />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleSaveAddInfo} type="button" className="btn btn-primary2 btnModal">Confirmar</button>
                </Modal.Footer>
            </Modal>    
        </div>
    )
}

export default AdditionalInformation;