import React from "react";
import "../../Headers/Header.css";
import avatar from "../../../assets/icons/Avatar.svg";

function MenuOut() {
  return (
    <div>
      <div className="navbarScrollOut">
        <div className="collapse navbar-collapse" id="navbarScrollOut">
          <nav className="nav-out">
            <ul className="navbar-nav navbar-nav-out">
              <li className="nav-item">
                <a className="nav-link" href="#productos">
                  Productos
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#soluciones">
                  Soluciones
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#casos">
                  Casos de Exito
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link pos-img" href="#login">
                  <img src={avatar} alt="" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default MenuOut;
