import React, { useState, useRef, useEffect } from 'react'
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';

import '../../css/body.css'
import '../../css/forms.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Table from '../../components/Table/Table';
import Message from '../../components/Message/Message'
import { postData } from '../../services/Request';

function Conciliation() {
    const fileInputRef = useRef();
    const isLogin = true;
    const isMenu = false;
    const num = 5;

    const [selectedFile, setSelectedFile] = useState(null);
    const [isBtnReconcileDisabled, setIsBtnReconcileDisabled] = useState(true);
    const [date, setDate] = useState('');
    const [speiType, setSpeiType] = useState('A');
    const navigate = useNavigate();
    const [sitesOps, setSitesOps] = useState(0);
    const [sitesAmt, setSitesAmt] = useState(0);
    const [aspOps, setAspOps] = useState(0);
    const [aspAmt, setAspAmt] = useState(0);
    const [opsFromFile, setOpsFromFile] = useState([]);
    const [opsFromDB, setOpsFromDB] = useState([]);
    const [reconcileResult, setReconcileResult] = useState('');
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const fileReader = new FileReader();

    const [doneWithFile, setDoneFile] = useState(false);
    const [doneWithDB, setDoneDB] = useState(false);

    // useEffect(() => {
    //     if (!aspOps && !sitesOps && !aspAmt && !sitesAmt) return;

    //     if((aspOps === sitesOps) && (aspAmt === sitesAmt)) {
    //         setReconcileResult("Conciliación exitosa");
    //     } else {
    //         setReconcileResult("Errores de conciliación; favor de revisar");
    //         compareOpsArrays();
    //     }
    // }, [aspOps, sitesOps, aspAmt, sitesAmt]);

    useEffect( () => {
        if(doneWithDB && doneWithFile) {
            compareOpsArrays(opsFromFile, opsFromDB);
            if((aspOps === sitesOps) && (aspAmt === sitesAmt)) {
                setReconcileResult("Conciliación exitosa");
            } else {
                setReconcileResult("Errores de conciliación; favor de revisar");
            }
        }

    }, [doneWithDB, doneWithFile]);

    const handleSelectedFile = (event) => {
        handleReset();
        const file = event.target.files[0];
        setSelectedFile(file);
        setIsBtnReconcileDisabled(false);
    };

    const handleReconcile = async () => {
        if (!date) {
            setAlert({tipo: 'warning', msn: 'Favor de ingresar la fecha de operación', show: true});
            return
        }

        await loadData();

        if (selectedFile){
            fileReader.onload = function (event) {
                const text = event.target.result;
                loadCsvJSON(text);
            };

            fileReader.readAsText(selectedFile);
        }
    };

    const loadData = async () => {
        const data = {
            tipoSpei: speiType,
            fechaReconciliar: date
        };

        const dataCipher = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(data));
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        postData(process.env.REACT_APP_ACCOUNT_RECONCILE + '?token=' + userData.token, {data : dataCipher}, headers)
        .then(({ response, error }) => {

            if (error) {
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                }
                console.log("E reconcile: " + error);
                return;
            }
            const parsedResponse = JSON.parse(response);

            setSitesOps(0);
            setSitesAmt(0);

            const opsFromDBResult = parsedResponse.map(item => {

                if (item.estado_operacion === 'Confirmada') {
                    setSitesOps(prevSitesOps => prevSitesOps + 1);
                    setSitesAmt(prevSitesAmt => prevSitesAmt + parseFloat(speiType === 'A' ? item.monto_abono : item.monto_cargo));
                }

                return [
                    item.clave_rastreo,
                    item.cuenta_origen,
                    item.cuenta_destino,
                    dollarUS.format(speiType === 'A' ? item.monto_abono : speiType === 'P' ? item.monto_cargo : ''),
                    item.estado_operacion
                ];
            });

            setOpsFromDB(opsFromDBResult);
            setDoneDB(true);
        });
    };

    function loadCsvJSON(csv) {
        if (csv === '') {
            return;
        }

        var lines=csv.split("\n");

        if (lines[lines.length-1] === '') {
            lines.pop();
        }

        var result = [];
        var headers=lines[0].split(",");

        for (var i = 1; i < lines.length; i++) {
            var obj = {};
            var myLine = removeInnerCommas(lines[i]);
            var currentline=myLine.split(",");

            for (var j = 0; j < headers.length; j++) {
                const header = headers[j].replace(/['"]/g, "");
                const value = currentline[j].replace(/['"]/g, "");
                obj[header] = value;
            }

            result.push(obj);
        }
        setAspOps(0);
        setAspAmt(0);

        const opsFromFileResult = result.map(item => {

            if ((speiType === 'A' && item.estado_operacion === 'Abonado') || (speiType !== 'A' && item.estado_operacion === 'Liquidado')) {
                setAspOps(prevAspOps => prevAspOps + 1);
                setAspAmt(prevAspAmt => prevAspAmt + parseFloat(speiType === 'A' ? item.monto_abono : item.monto_cargo));
            }
            return [
                item.clave_rastreo,
                item.cuenta_origen,
                item.cuenta_destino,
                dollarUS.format(speiType === 'A' ? item.monto_abono : speiType === 'P' ? item.monto_cargo : ''),
                item.estado_operacion
            ];
        });

        setOpsFromFile(opsFromFileResult);
        setDoneFile(true);
    }

    function removeInnerCommas(lineText) {
        var isQuoted = false;
        var returnedTextLine = lineText;
        var commaPositions = [];

        for (var position = 0; position < lineText.length; position++ ) {

            if (lineText[position] === '"') {
                isQuoted = !isQuoted;
            } else if ( (lineText[position] === ',') && isQuoted ) {
                commaPositions.push(position);
            }
        }

        const toRemove = commaPositions.length;

        for ( var i = 0; i < toRemove; i++) {
            var pos = commaPositions.pop();
            returnedTextLine = returnedTextLine.slice(0, pos) + returnedTextLine.slice(pos+1);
        }

        return returnedTextLine;
    }

    async function compareOpsArrays(arrFromFile, arrFromDB) {
        const opsNotInFile = [];
        const opsNotInDB = [];

        //recorro ambos arreglos para encontrar las diferencias
        arrFromDB.forEach( e => {
            var notExists = true;
            for(var countFile = 0; countFile<arrFromFile.length; countFile++) {
                if( e.clave_rastreo === arrFromFile[countFile][0] )
                    notExists = false;
            }
            if(notExists && (e.estado_operacion === 'Confirmada')) {
                opsNotInFile.push(e);
            }
        });
        
        arrFromFile.forEach( e => {
            var notExists = true;
            for(var countFile = 0; countFile<arrFromDB.length; countFile++) {
                if( e[0] === arrFromDB[countFile][0] )
                    notExists = false;
            }
            if(notExists) {
                opsNotInDB.push(e);
            }
        });
        setOpsFromFile(opsNotInDB);
        setOpsFromDB(opsNotInFile);
    }

    const tableHeaders = ['Clave de Rastreo','Cuenta Origen','Cuenta Destino','Monto','Estatus'];
    const tableStyles = ['', '', '','',''];

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const handleReset = () => {
        setAspAmt(0);
        setAspOps(0);
        setSitesAmt(0);
        setSitesOps(0);
        setOpsFromDB([]);
        setOpsFromFile([]);
        setReconcileResult('');
        setSelectedFile(null);
    };

    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    return (
        <div className='body'>
            <Header isLogin={isLogin}/>
            <div className="container-fluid2 position-absolute space-acconts">
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <h4 className="subTitle5">Conciliación de operaciones</h4>
                            </div>
                            <div className="bg-container-body">
                                <hr/>
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="row contentDivRow">
                                    <div className="col-sm-6">
                                        <div className="row file-upload-container">
                                            <div className="col-sm-4">
                                                <label className="file-upload-button" htmlFor="fileInput">
                                                    <span className='choose-file'>Choose file</span>
                                                </label>
                                            </div>
                                            <div className="col-sm-8">
                                                <input
                                                    type="file"
                                                    id="fileInput"
                                                    accept=".csv"
                                                    style={{ display: 'none' }}
                                                    onChange={handleSelectedFile}
                                                    ref={fileInputRef}
                                                />
                                                <input type="text" className="form-control txtControl" readOnly
                                                value={selectedFile ? selectedFile.name : "No file chose"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="row contentData">
                                            <div className="col-sm-6">
                                                <input value={date} onChange={(e) => setDate(e.target.value)} type="date" className="form-control txtControl"/>
                                            </div>
                                            <div className="col-sm-6">
                                                <select value={speiType} onChange={(e) => setSpeiType(e.target.value)} className="form-select select-option">
                                                    <option value="A">SPEI IN</option>
                                                    <option value="P">SPEI OUT</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2 justify-content-md-end contentBtn">
                                    <button disabled={isBtnReconcileDisabled} onClick={handleReconcile} className="btnLogin" type="button">Conciliar archivo seleccionado</button>
                                </div>
                                <div className="row contentDivRow">
                                    <div className="col-sm-4 content-rows">
                                        <div className="mb-2">
                                            <label className="form-label2">Resultados de la conciliación:</label>
                                            <div className="">
                                                <input type="text" value={reconcileResult} className="form-control border2" placeholder="" readOnly/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 content-rows">
                                        <div className="mb-2">
                                            <label className="form-label2">Resumen archivo ASP: </label>
                                            <div className="row border">
                                                <div className="col-sm-6">
                                                    <label className="form-label4">Total de operaciones:</label>
                                                    <label className="form-label4">Monto total operado:</label>
                                                </div>
                                                <div className="col-sm-6 valueR">
                                                    <label className="form-label4">{aspOps}</label>
                                                    <label className="form-label4">{dollarUS.format(aspAmt)}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 content-rows">
                                        <div className="mb-2">
                                            <label className="form-label2">Resumen operaciones SitesPay:</label>
                                            <div className="row border">
                                                <div className="col-sm-6">
                                                    <label className="form-label4">Total de operaciones:</label>
                                                    <label className="form-label4">Monto total operado:</label>
                                                </div>
                                                <div className="col-sm-6 valueR">
                                                    <label className="form-label4">{sitesOps}</label>
                                                    <label className="form-label4">{dollarUS.format(sitesAmt)}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row contentDivRow">
                                    <div className="col-sm-6">
                                        {opsFromFile.length === 0 ? (
                                            <>
                                                <hr />
                                                <p className='copy'>Copyright</p>
                                            </>
                                        ) : (
                                            <>
                                                <label className="form-label2">Operaciones ASP no en SitesPay</label>
                                                <hr />
                                                <Table headers={tableHeaders} data={opsFromFile} styles={tableStyles} itemsPerPage={num} menuIcon='' isMenu={isMenu} />
                                            </>
                                        )}
                                    </div>

                                    <div className="col-sm-6">
                                        {opsFromDB.length === 0 ? (
                                            <>
                                                <hr />
                                                <p className='fecha'>Fecha/Hora</p>
                                            </>
                                        ) : (
                                            <>
                                                <label className="form-label2">Operaciones SitesPay no en ASP</label>
                                                <hr/>
                                                <Table headers={tableHeaders} data={opsFromDB} styles={tableStyles} itemsPerPage={num} menuIcon='' isMenu={isMenu} />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Conciliation