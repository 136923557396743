import React, { useEffect, useState } from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Message from '../../components/Message/Message';

import Encrypter from '../../services/Encrypter';
import { fetchData, postData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';

import * as errorIn from '../../utils/validations';

function InitActivation() {
    const isLogin = true;

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const navigate = useNavigate();

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [cardHolder, setCardHolder] = useState({
        rfc: '',
        email: '',
        country: 'MEX',
        name: '',
        surname: '',
        phone : '',
        birthdate : '',
        identificationType : 'INE',
        identificationValue : '',
        gender : 'F',
        streetName : '',
        streetNumber : '',
        zipCode : '',
        city : '',
        region : 'AGUASCALIENTES'
    });

    const handleOnChange = (e) => {
		var { id, value } = e.target;

        setAlert({tipo: '', msn: '', show: false});

        // if( id === 'rfc' ) {
        //     if( value !== '' && errorIn.maxLength( value, 13, 'rfc') ) {
        //         e.target.value = cardHolder[id] || '';
        //         return;
        //     } else {
        //         value = errorIn.sanitizeTextNoSpace( value );
        //     }
        // }
        if( id === 'name' ) {
            if( value !== '' && errorIn.maxLength( value, 100, 'name') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeTextLettersOnly( value );
            }
        }
        if( id === 'surname' ) {
            if( value !== '' && errorIn.maxLength( value, 100, 'surname') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeTextLettersOnly( value );
            }
        }
        if( id === 'identificationValue' ) {
            if( value !== '' && errorIn.maxLength( value, 50, 'identificationValue') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeText( value );
            }
        }
        
        if( id === 'email' && value !== '' && ( errorIn.maxLength( value, 255, 'email') )) {
            e.target.value = cardHolder[id] || '';
            return;
        }
        if( id === 'phone' && value !== '' && ( errorIn.integer(value) || errorIn.maxLength( value, 10, 'phone') )) {
            e.target.value = cardHolder[id] || '';
            return;
        }

        if( id === 'streetName' ) {
            if( value !== '' && errorIn.maxLength( value, 100, 'streetName') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeText( value );
            }
        }
        if( id === 'streetNumber' ) {
            if( value !== '' && errorIn.maxLength( value, 40, 'streetNumber') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeText( value );
            }
        }
        if( id === 'city' ) {
            if( value !== '' && errorIn.maxLength( value, 100, 'city') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeText( value );
            }
        }
        if( id === 'region' ) {
            if( value !== '' && errorIn.maxLength( value, 100, 'region') ) {
                e.target.value = cardHolder[id] || '';
                return;
            } else {
                value = errorIn.sanitizeText( value );
            }
        }
        if( id === 'zipCode' && value !== '' && ( errorIn.integer(value) || errorIn.maxLength( value, 10, 'zipCode') )) {
            e.target.value = cardHolder[id] || '';
            return;
        }
        setCardHolder((prevData) => ({
			...prevData,
			[id]: value
		}));
	};

    const handleNext = () => {

        // if(errorIn.lengthTrim(cardHolder.rfc, 13, 13, 'Error en rfc')) {
        //     setAlert({tipo: 'danger', msn: 'Error en RFC', show: true});
        //     return;
        // }
        if( errorIn.lengthTrim(cardHolder.email, 5, 255, 'Error en email') || errorIn.email(cardHolder.email, 'Error en email') ) {
            setAlert({tipo: 'danger', msn: 'Error en email', show: true});
            return;
        }
        if(errorIn.lengthTrim(cardHolder.name, 1, 100, 'Error en nombre')) {
            setAlert({tipo: 'danger', msn: 'Error en nombre', show: true});
            return;
        }
        if(errorIn.lengthTrim(cardHolder.surname, 1, 100, 'Error en el apellido')) {
            setAlert({tipo: 'danger', msn: 'Error en el apellido', show: true});
            return;
        }
        if(errorIn.lengthTrim(cardHolder.phone, 10, 10, 'Error en telefono')) {
            setAlert({tipo: 'danger', msn: 'Error en el número de teléfono', show: true});
            return;
        }

        if(!cardHolder.birthdate) {
            setAlert({tipo: 'danger', msn: 'Error en la fecha de nacimiento', show: true});
            return;
        }
        if(!cardHolder.identificationType) {
            setAlert({tipo: 'danger', msn: 'Error en el tipo de identificación', show: true});
            return;
        }
        if(!cardHolder.identificationValue) {
            setAlert({tipo: 'danger', msn: 'Error en el número de la identificación', show: true});
            return;
        }
        if(!cardHolder.streetName) {
            setAlert({tipo: 'danger', msn: 'Error en calle', show: true});
            return;
        }
        if(!cardHolder.streetNumber) {
            setAlert({tipo: 'danger', msn: 'Error en el número de la calle', show: true});
            return;
        }
        if(errorIn.lengthTrim(cardHolder.zipCode, 5, 5, 'Error en código postal')) {
            setAlert({tipo: 'danger', msn: 'Error en código postal', show: true});
            return;
        }
        if(errorIn.numeric(cardHolder.zipCode, 'Error en código postal')) {
            setAlert({tipo: 'danger', msn: 'Código postal debe ser numérico', show: true});
            return;
        }
        if(!cardHolder.city) {
            setAlert({tipo: 'danger', msn: 'Error en ciudad', show: true});
            return;
        }

        //console.log(cardHolder);
        //validamos que todos los datos estén completos, en caso afirmativo, guardamos, mandamos a pomelo, y si todo ok, mandamos a siguiente paso.
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const strPayload = JSON.stringify(cardHolder);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = { data: cryptPayload };


        postData(process.env.REACT_APP_ADMIN_CARDS + "user/?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                const responseErrors = error.response.data;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors, show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    console.log(error);
                }
            }

            if(response) {
                //console.log(response);
                const rspObj = JSON.parse(response);
                //console.log(rspObj);
                if(rspObj.id) {
                    localStorage.setItem('activationCode', rspObj.id);
                    navigate('/tarjetaLista');
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el proceso de registro del tarjetahabiente.', show: true});
                }
            }
        });

    }

    useEffect( () => {
        //traemos datos del usuario loggeado para ver si ya tiene datos creados como "Tarjetahabiente"... si los tiene, lo mandamos al siguiente paso, sino, que lo llene primero
        function getCardHolder() {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            fetchData(process.env.REACT_APP_ADMIN_CARDS + "user/?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }

                if( response.length && response[0].pomeloUserId ) {
                    localStorage.setItem('activationCode', response[0].pomeloUserId);
                    navigate('/tarjetaLista');
                }
            });
        }
        getCardHolder();
    }, [navigate]);

/*
quito esto del HTML
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">RFC</label>
                                                <input type="text" className="form-control txtControl" id="rfc" value={cardHolder.rfc} onChange={handleOnChange} placeholder="RFC" maxLength={13} />
                                            </div>

*/

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <div className="row">
                                    <div className="col-sm-9">
                                        <h4 className="tilteAdmin">Activación de una nueva tarjeta</h4>
                                    </div>
                                    <div className="col-sm-3 contenBtnAdmin">
                                        <button className="btn-primary" type="button" onClick={handleNext} >Siguiente paso</button>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-body-card2">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="card card-caratula containerCard">
                                    <div className="card-body">
                                        <div className='row row-form-text0'>
                                            <div className='col-sm-6' >
                                                <label className="col-form-label">Nombre(s)</label>
                                                <input type="text" className="form-control txtControl" id="name" value={cardHolder.name} onChange={handleOnChange} placeholder="Nombre(s)" />
                                            </div>
                                            <div className='col-sm-6' >
                                                <label className="col-form-label">Apellido(s)</label>
                                                <input type="text" className="form-control txtControl" id="surname" value={cardHolder.surname} onChange={handleOnChange} placeholder="Apellido(s)" />
                                            </div>
                                        </div>
                                        <div className='row row-form-text0'>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Fecha de Nacimiento</label>
                                                <input type="date" className="form-control txtControl" id="birthdate" value={cardHolder.birthdate} onChange={handleOnChange}/>
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Genero</label>
                                                <select className="form-select txtControl" id="gender" value={cardHolder.gender} onChange={handleOnChange}>
                                                    <option value="F">FEMENINO</option>
                                                    <option value="M">MASCULINO</option>
                                                </select>
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Correo Electronico</label>
                                                <input type="email" className="form-control txtControl" id="email" value={cardHolder.email} onChange={handleOnChange} placeholder="correo" />
                                            </div>
                                        </div>
                                        <div className='row row-form-text0'>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Tipo de identificación</label>
                                                <select className="form-select txtControl" id="identificationType" value={cardHolder.identificationType} onChange={handleOnChange}>
                                                    <option value="INE">INE</option>
                                                    <option value="PASSPORT">PASAPORTE</option>
                                                </select>
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Número de identificación</label>
                                                <input type="text" className="form-control txtControl" id="identificationValue" value={cardHolder.identificationValue} onChange={handleOnChange} placeholder="Número de la identificación" />
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Teléfono</label>
                                                <input type="text" className="form-control txtControl" id="phone" value={cardHolder.phone} onChange={handleOnChange} placeholder="Teléfono" />
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='row row-form-text0'>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Calle</label>
                                                <input type="text" className="form-control txtControl" id="streetName" value={cardHolder.streetName} onChange={handleOnChange} placeholder="Calle"/>
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Número Ext/Int</label>
                                                <input type="text" className="form-control txtControl" id="streetNumber" value={cardHolder.streetNumber} onChange={handleOnChange} placeholder="Número Ext/Int" />
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Código Postal</label>
                                                <input type="text" maxLength={5} className="form-control txtControl" id="zipCode" value={cardHolder.zipCode} onChange={handleOnChange} placeholder="Código Postal" />
                                            </div>
                                        </div>
                                        <div className='row row-form-text0'>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Ciudad/Mpio</label>
                                                <input type="text" className="form-control txtControl" id="city" value={cardHolder.city} onChange={handleOnChange} placeholder="Ciudad/Mpioo" />
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">Estado</label>
                                                <select className="form-select txtControl" id="region" value={cardHolder.region} onChange={handleOnChange} >
                                                    <option value="AGUASCALIENTES">AGUASCALIENTES</option>
                                                    <option value="BAJA CALIFORNIA NORTE">BAJA CALIFORNIA NORTE</option>
                                                    <option value="BAJA CALIFORNIA SUR">BAJA CALIFORNIA SUR</option>
                                                    <option value="CAMPECHE">CAMPECHE</option>
                                                    <option value="CHIAPAS">CHIAPAS</option>
                                                    <option value="CHIHUAHUA">CHIHUAHUA</option>
                                                    <option value="CIUDAD DE MEXICO">CIUDAD DE MEXICO</option>
                                                    <option value="COAHUILA DE ZARAGOZA">COAHUILA DE ZARAGOZA</option>
                                                    <option value="COLIMA">COLIMA</option>
                                                    <option value="DURANGO">DURANGO</option>
                                                    <option value="ESTADO DE MEXICO">ESTADO DE MEXICO</option>
                                                    <option value="GUANAJUATO">GUANAJUATO</option>
                                                    <option value="GUERRERO">GUERRERO</option>
                                                    <option value="HIDALGO">HIDALGO</option>
                                                    <option value="JALISCO">JALISCO</option>
                                                    <option value="MICHOACAN DE OCAMPO">MICHOACAN DE OCAMPO</option>
                                                    <option value="MORELOS">MORELOS</option>
                                                    <option value="NAYARIT">NAYARIT</option>
                                                    <option value="NUEVO LEON">NUEVO LEON</option>
                                                    <option value="OAXACA">OAXACA</option>
                                                    <option value="PUEBLA">PUEBLA</option>
                                                    <option value="QUERETARO">QUERETARO</option>
                                                    <option value="QUINTANA ROO">QUINTANA ROO</option>
                                                    <option value="SAN LUIS POTOSI">SAN LUIS POTOSI</option>
                                                    <option value="SINALOA">SINALOA</option>
                                                    <option value="SONORA">SONORA</option>
                                                    <option value="TABASCO">TABASCO</option>
                                                    <option value="TAMAULIPAS">TAMAULIPAS</option>
                                                    <option value="TLAXCALA">TLAXCALA</option>
                                                    <option value="VERACRUZ">VERACRUZ</option>
                                                    <option value="YUCATAN">YUCATAN</option>
                                                    <option value="ZACATECAS">ZACATECAS</option>
                                                </select>
                                            </div>
                                            <div className='col-sm-4' >
                                                <label className="col-form-label">País</label>
                                                <select className="form-select txtControl" id="country" value={cardHolder.country} onChange={handleOnChange}>
                                                    <option value="MEX">MEXICO</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default InitActivation
