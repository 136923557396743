import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';

function EditUdns({ data, show, updateInfo, handleClose }) {

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [updateData, setUpdateData] = useState({
        idUdn: 0,
        nombre: '',
        razonSocial: '',
        rfc: '',
        speiInCosto: 0,
        speiInPct: 0,
        speiOutCosto: 0,
        speiOutPct: 0,
        id: 0,
        nombrePromotor: '',
        nombreResponsable: '',
        telResponsable: '',
        emailResponsable: '',
        nota: ''
    });

    const onChange = (e) => {
        const { id, value } = e.target;
        setAlert({ tipo: '', msn: '', show: false });

        if (id === 'costoFijoSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ')) {
            e.target.value = updateData[id] || '';
            return;
        }
        if (id === 'costoPctSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ')) {
            e.target.value = updateData[id] || '';
            return;
        }
        if (id === 'costoFijoSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ')) {
            e.target.value = updateData[id] || '';
            return;
        }
        if (id === 'costoPctSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ')) {
            e.target.value = updateData[id] || '';
            return;
        }

        if( id === 'nombrePromotor' && errorIn.maxLength(value, 100, 'error en nombrePromotor') ) {
            e.target.value = updateData[id] || '';
            return;
        }
        if( id === 'nombreResponsable' && errorIn.maxLength(value, 100, 'error en nombreResponsable') ) {
            e.target.value = updateData[id] || '';
            return;
        }
        if( id === 'telResponsable' && errorIn.maxLength(value, 16, 'error en telResponsable') ) {
            e.target.value = updateData[id] || '';
            return;
        }
        if( id === 'emailResponsable' && errorIn.maxLength(value, 250, 'error en emailResponsable') ) {
            e.target.value = updateData[id] || '';
            return;
        }
        if( id === 'nota' && errorIn.maxLength(value, 2048, 'error en nota') ) {
            e.target.value = updateData[id] || '';
            return;
        }
        
        setUpdateData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
    }

    useEffect(() => {
        if (data)
            setUpdateData(data);
    }, [data]);

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Editar Unidad de Negocio</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula2">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <br />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Datos generales</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-2 content-inputs">
                                <label className="col-form-label">UDN ID</label>
                                <input type="text" id='udnDescripcion' className="form-control txtControl" value={updateData.idUdn} disabled />
                            </div>
                            <div className="col-lg-5 content-inputs">
                                <label className="col-form-label">Nombre UDN</label>
                                <input id="nombreCorto" type="text" className="form-control txtControl" value={updateData.nombreCorto} onChange={onChange} />
                            </div>
                            <div className="col-lg-5 content-inputs">
                                <label className="col-form-label">Descripción</label>
                                <input type="text" className="form-control txtControl" value={updateData.udnDescripcion} disabled />
                            </div>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">RFC Empresa</label>
                                <input id='rfc' type="text" className="form-control txtControl" value={updateData.rfc} onChange={onChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Razón social Empresa</label>
                                <input id='razon_social' type="text" className="form-control txtControl" value={updateData.razon_social} onChange={onChange} />
                            </div>
                        </div>
                        <hr />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Datos del responsable</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre del promotor</label>
                                <input type="text" id='nombrePromotor' className="form-control txtControl" value={updateData.nombrePromotor} onChange={onChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Nombre del responsable</label>
                                <input type="text" id='nombreResponsable' className="form-control txtControl" value={updateData.nombreResponsable} onChange={onChange} />
                            </div>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Teléfono del responsable</label>
                                <input type="text" id='telResponsable' className="form-control txtControl" value={updateData.telResponsable} onChange={onChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Email del responsable</label>
                                <input type="text" id='emailResponsable' className="form-control txtControl" value={updateData.emailResponsable} onChange={onChange} />
                            </div>
                        </div>
                        <div className="content-inputs2">
                            <label className="col-form-label">Notas</label>
                            <textarea className="form-control txtControl" placeholder="Escribir una nota" id="nota" value={updateData.nota} onChange={onChange}></textarea>

                        </div>
                        <hr />
                        <div className="row-form-text1 text-center">
                            <label className="form-label-admin">Comisiones generales</label>
                        </div>
                        <div className="row row-form-texts">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Porcentaje de SPEI IN</label>
                                <input type="text" id='costoFijoSpeiIn' className="form-control txtControl" value={updateData.costoFijoSpeiIn} onChange={onChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Costo de SPEI IN</label>
                                <input type="text" id='costoPctSpeiIn' className="form-control txtControl" value={updateData.costoPctSpeiIn} onChange={onChange} />
                            </div>
                        </div>
                        <div className="row row-form-texts2">
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Porcentaje de SPEI OUT</label>
                                <input type="text" id='costoFijoSpeiOut' className="form-control txtControl" value={updateData.costoFijoSpeiOut} onChange={onChange} />
                            </div>
                            <div className="col-lg-6 content-inputs">
                                <label className="col-form-label">Costo de SPEI OUT</label>
                                <input type="text" id='costoPctSpeiOut' className="form-control txtControl" value={updateData.costoPctSpeiOut} onChange={onChange} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={() => updateInfo(updateData)}>Confirmar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditUdns
