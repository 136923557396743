import React from 'react';
import './Spinner.css';

function Spinner({ texto }) {
    return (
        <div className="d-flex align-items-center">
            <strong className="text-primary text-spinner" role="status"> {texto} </strong>
            <div className="spinner-border text-primary" aria-hidden="true"></div>
        </div>
    )
}

export default Spinner
