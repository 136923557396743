import React, { useEffect, useState } from 'react'
import Encrypter from '../../services/Encrypter';
import { fetchData, postData } from '../../services/Request';
import { useNavigate } from 'react-router-dom';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
//import RecoverPassModal from '../../components/Modals/RecoverPassModal'
import Message from '../../components/Message/Message'


function Profile() {

    const isLogin = true;
    const navigate = useNavigate();
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [ userData, setUserData ] = useState({
        username: '',
        email: '',
        currentLoginPass: '',
        newLoginPass: '',
        newLoginPassConfirm: '',
        currentOpsPass: '',
        newOpsPass: '',
        newOpsPassConfirm: ''
    });
    useEffect( () => {
        //traemos los datos del usuario actual (via token)
        function getUserData() {
            const user = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, user.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            fetchData(process.env.REACT_APP_AUTH_USER_DATA + "/?token=" + user.token, headers)
            .then(({ response, error}) => {
                if (response) {
                    //console.log(response);
                    setUserData((prevData) => ({
                        ...prevData,
                        username: response.name,
                        email: response.email
                    }));
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors!==undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
            });
        }

        getUserData();

    }, [navigate]);

    const handleChange = (e) => {
        const { id, value } = e.target;

        // guardamos las contraseñas a cambiar
		setUserData((prevData) => ({
			...prevData,
			[id]: value,
		}));

    }

    const updateLoginPass = () => {
        // actualizamos contraseñas de login
        if(userData.newLoginPass.length < 8) {
            setAlert({tipo: 'warning', msn: 'La nueva contraseña debe contener al menos 8 caracteres', show: true});
            return;
        }
        if(userData.newLoginPass !== userData.newLoginPassConfirm) {
            setAlert({tipo: 'warning', msn: 'La nueva contraseña debe coincidir con la confirmación', show: true});
            return;
        }

        const user = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, user.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const data = {
            currentPass: userData.currentLoginPass,
            newPass: userData.newLoginPass,
            confirmPass: userData.newLoginPassConfirm
        };
        const changePwd = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(data));
        //console.log(data);

        postData(process.env.REACT_APP_AUTH_CHANGE_PASS + "/?token=" + user.token + '&pw=2', {data: changePwd}, headers)
        .then(({ response, error }) => {
            if (response){
                setAlert({tipo: 'success', msn: response, show: true});
            } else if (error) {
                const responseErrors = error.response.data.errors.errors;
                //console.log('Errors: ', responseErrors);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                } else if (responseErrors!==undefined) {
                    ///console.log(error);
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en comunicación', show: true});
                }
                return;
            } 
    
            console.log("R RESETPASS: " + response);
        });

    }
    const updateOpsPass = () => {
        // actualizamos contraseñas de ops
        if(userData.newOpsPass.length < 8) {
            setAlert({tipo: 'warning', msn: 'La nueva contraseña debe contener al menos 8 caracteres', show: true});
            return;
        }
        if(userData.newOpsPass !== userData.newOpsPassConfirm) {
            setAlert({tipo: 'warning', msn: 'La nueva contraseña debe coincidir con la confirmación', show: true});
            return;
        }


        const user = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, user.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const data = {
            currentPass: userData.currentOpsPass,
            newPass: userData.newOpsPass,
            confirmPass: userData.newOpsPassConfirm
        };
        const changePwd = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(data));
        //console.log(data);

        postData(process.env.REACT_APP_AUTH_CHANGE_PASS + "/?token=" + user.token + '&pw=1', {data: changePwd}, headers)
        .then(({ response, error }) => {
            if (response){
                setAlert({tipo: 'success', msn: response, show: true});
            } else if (error) {
                const responseErrors = error.response.data.errors.errors;
                console.log('Errors: ', responseErrors);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    
                } else if (responseErrors!==undefined) {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el systema', show: true});
                }
                return;
            } 
    
            console.log("R RESETPASS: " + response);
        });
    }

    return (
        <div>
            <Header isLogin={isLogin}/>
            <div className='body'>
                <div className="container-fluid2 position-absolute space-acconts">
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-sub-title-bg'>
                                    <h4 className="subTitle">Administrar Cuenta</h4>
                                    <Message alert={alert} closeAlert={closeAlert} />
                                </div>
                                <div className="bg-container-body-card2">
                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Datos de cuentas</h5>
                                            <hr></hr>
                                            <div className="row row-form-text0">
                                                <div className="col-md-6">
                                                    <label className="col-form-label">Nombre del usuario</label>
                                                    <input type="text" className="form-control txtControl" id="nombre" disabled value={userData.username} />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="col-form-label">Cuenta de correo electrónico</label>
                                                    <input type="text" className="form-control txtControl" id="email" disabled value={userData.email} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Cambio de contraseña de acceso</h5>
                                            <hr></hr>
                                            <div className="row row-form-text0">
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Ingrese su contraseña actual</label>
                                                    <input type="password" className="form-control txtControl" id="currentLoginPass" onChange={handleChange} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Ingrese su nueva contraseña</label>
                                                    <input type="password" className="form-control txtControl" id="newLoginPass" onChange={handleChange} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Confirme su nueva contraseña</label>
                                                    <input type="password" className="form-control txtControl" id="newLoginPassConfirm" onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="row row-botton">
                                                <div className="col-lg-8"></div>
                                                <div className="col-lg-4">
                                                    <div className="d-grid gap-2 mx-auto buttonAdmin">
                                                        <button className="btnLogin" type="button" onClick={updateLoginPass}>Cambiar Contraseña de Acceso</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card card-caratula containerCard">
                                        <div className="card-body">
                                            <h5 className="text-3">Cambio de contraseña de operaciones</h5>
                                            <hr></hr>
                                            <div className="row row-form-text0">
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Ingrese su contraseña de operaciones actual</label>
                                                    <input type="password" className="form-control txtControl" id="currentOpsPass" onChange={handleChange} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Ingrese su nueva contraseña de operaciones</label>
                                                    <input type="password" className="form-control txtControl" id="newOpsPass" onChange={handleChange} />
                                                </div>
                                                <div className="col-lg-4">
                                                    <label className="col-form-label">Confirme su nueva contraseña de operaciones</label>
                                                    <input type="password" className="form-control txtControl" id="newOpsPassConfirm" onChange={handleChange} />
                                                </div>
                                            </div>
                                            <div className="row row-botton">
                                                <div className="col-lg-8"></div>
                                                <div className="col-lg-4">
                                                    <div className="d-grid gap-2 mx-auto buttonAdmin">
                                                        <button className="btnLogin" type="button" onClick={updateOpsPass}>Cambiar Contraseña de Operaciones</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
//            <RecoverPassModal />

export default Profile
