import React from 'react'
import { getInstitutionName } from '../../services/institution';
import TransferAccountsTickets from '../Tickets/TransferAccountsTickets';
import ReactDOMServer from 'react-dom/server';

import Modal from 'react-bootstrap/Modal';

function FinishOperation({ transferData, transferType, referenceNumber, fromAccountName, toAccountName, show, handleClose }) {
    const queRecibo = () => {
        const printWindow = window.open('', '', 'height=800,width=800');
        const ticketHtml = ReactDOMServer.renderToString(<TransferAccountsTickets data={transferData} referenceNumber={referenceNumber} transferType={transferType} fromAccountName={fromAccountName} toAccountName={toAccountName} />); // Convertir el componente a una cadena HTML
        printWindow.document.write(ticketHtml);
        printWindow.document.close();
    }

    const realDestination = transferData.last_four ? transferData.last_four : transferData.destination;

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Operación confirmada</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contModal">
                        {(() => {
                            switch (transferType) {
                                case 1: return <label className="form-label-general subTitleModal">Traspaso entre cuentas propias realizada</label>;
                                case 2: return <label className="form-label-general subTitleModal">Traspaso a cuentas de terceros realizada</label>;
                                case 3: return <label className="form-label-general subTitleModal">Recarga de tarjetas realizada</label>;
                                default: return null;
                            }
                        })()}
                        <div className="card card-caratula">
                            <div className="row row-form-text">
                                <div className="col-lg-4 content-label">
                                    <label className="col-form-label">Clave de rastreo:</label>
                                </div>
                                <div className="col-lg-8 content-input">
                                    <input type="text" className="form-control txtControl" value={referenceNumber} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text">
                                <div className="col-lg-4 content-label">
                                    <label className="col-form-label">De su cuenta número:</label>
                                </div>
                                <div className="col-lg-8 content-input">
                                    <input type="number" className="form-control txtControl" value={transferData.origin} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text">
                                <div className="col-lg-4 content-label">
                                    <label className="col-form-label">De la institución bancaria:</label>
                                </div>
                                <div className="col-lg-8 content-input">
                                    <input type="text" className="form-control txtControl" value={getInstitutionName(transferData.origin?.substring(0, 3)) || ''} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-4 content-label">
                                    <label className="col-form-label">A su número de cuenta:</label>
                                </div>
                                <div className="col-lg-8 content-input">
                                    <input type="text" className="form-control txtControl" value={realDestination} readOnly />
                                </div>
                            </div>
                            {(transferType < 3) && <div>
                                <div className="row row-form-text">
                                    <div className="col-lg-4 content-label">
                                        <label className="col-form-label">Nombre del beneficiario:</label>
                                    </div>
                                    <div className="col-lg-8 content-input">
                                        <input type="text" className="form-control txtControl" value={transferData.beneficiary} readOnly />
                                    </div>
                                </div>

                                <div className="row row-form-text">
                                    <div className="col-lg-4 content-label">
                                        <label className="col-form-label">De la institución bancaria:</label>
                                    </div>
                                    <div className="col-lg-8 content-input">
                                        <input type="text" className="form-control txtControl" value={transferData.destinationBank || ''} readOnly />
                                    </div>
                                </div>
                            </div>}

                            <div className="row row-form-text">
                                <div className="col-lg-4 content-label">
                                    <label className="col-form-label">Monto a transferir:</label>
                                </div>
                                <div className="col-lg-8 content-input">
                                    <input type="text" className="form-control txtControl" value={dollarUS.format(transferData.amount)} readOnly />
                                </div>
                            </div>

                            {(transferType < 3) && <div>
                                <div className="row row-form-text">
                                    <div className="col-lg-4 content-label">
                                        <label className="col-form-label">Concepto:</label>
                                    </div>
                                    <div className="col-lg-8 content-input">
                                        <input type="text" className="form-control txtControl" value={transferData.concept} readOnly />
                                    </div>
                                </div>

                                <div className="row row-form-text">
                                    <div className="col-lg-4 content-label">
                                        <label className="col-form-label">Número de referencia:</label>
                                    </div>
                                    <div className="col-lg-8 content-input">
                                        <input type="text" className="form-control txtControl" value={transferData.reference} readOnly />
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" onClick={queRecibo} className="btn btn-primary2 btnModal">Imprimir</button>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={handleClose}>Cerrar</button>
                    </div>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default FinishOperation
