import React, { useState } from 'react';
import './Footer.css';
//import logo from '../../assets/icons/Logo.svg';
//import help from '../../assets/icons/help.svg';
import cnbv from '../../assets/img/CNBV.png';
import buro from '../../assets/img/buroCredito.png';
import condusef from '../../assets/img/Condusef.png';
import Help from '../Modals/Help';
import aviso from '../../doc/AvisodePrivacidad.pdf';

function Footer() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShowPdf = () => {
    const pdfWindow = window.open();
    pdfWindow.location.href = aviso;
  };

  const showCnbv = () => {
    window.open('https://www.gob.mx/cnbv', '_blank');
  };

  const showBuroCredito = () => {
    window.open('https://www.buro.gob.mx/', '_blank');
  };

  const showCondusef = () => {
    window.open('https://www.gob.mx/condusef', '_blank');
  };

  return (
    <footer className="fixed-bottom">
      <div className="row">
        <div className="col-sm-3 text-center">
          <p className='text-footer'> © 2024 Derechos reservados </p>
        </div>
        <div className="col-sm-2 text-center">
          <a href="#" onClick={showCnbv}>
            <img src={cnbv} alt="" className='text-center' />
          </a>
        </div>
        <div className="col-sm-2 text-center">
          <a href='#' onClick={showBuroCredito}>
            <img src={buro} alt="" className='text-center' />
          </a>
        </div>
        <div className="col-sm-2 text-center">
          <a href='#' onClick={showCondusef}>
            <img src={condusef} alt="" className='text-center' />
          </a>
        </div>
        <div className="col-sm-2 text-center">
          <a className='sin' href='#' onClick={handleShowPdf}>
            <p className='text-aviso'>Aviso de Privacidad</p>
          </a>
        </div>
        <div className="col-sm-1 text-center">
          <a className='space-footer' href="#" onClick={handleShow}>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-question-circle-fill space-footer" viewBox="0 0 16 16">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247m2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
            </svg>
          </a>
        </div>
      </div>
      <Help show={show} handleClose={handleClose} />
    </footer>
  )
}

export default Footer
