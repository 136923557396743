import React, { useState, useEffect } from "react";
import img from "../../assets/img/logo.jpeg";

function MultiFilePrintTicket({ data, type }) {
    //console.log(data);
    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date());
        }, 1000); // Actualizar cada segundo

        return () => clearInterval(intervalId); // Limpiar el intervalo al desmontar el componente
    }, []); // El efecto se ejecuta solo una vez al montar el componente

    var fecha = '';
    var hora = ''
    if (data[0]) {
        //console.log(data[0]);
        fecha = new Date(data[0][11]).toLocaleDateString();
        hora = new Date(data[0][11]).toLocaleTimeString();
    }

    return (
        <html>
            <head>
                <title>Comprobante de trasferencia SitesPay</title>
                <link
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
                    rel="stylesheet"
                    integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
                    crossOrigin="anonymous"
                />
                <link href="/files/Tickets.css" rel="stylesheet" />
            </head>
            <body>
                <div id="pdf-content">
                    {data.map((item, index) => (
                        <div key={index}>
                            <div className="card card-ticket">
                                <div className="card-header">
                                    <div className="clearfix">
                                        <div className="float-start">
                                            <img className="imgicon" src={img} alt="SitesPay" />
                                        </div>
                                        <div className="float-end">
                                            <h2 className="p-2 title-ticket">Trasferencia SPEI</h2>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-end">Fecha de operación:</p>
                                                </div>
                                                <div className="col-6">
                                                    {fecha === 'Invalid Date' ? (
                                                        <p className="text-start">{currentDate.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' })}</p>
                                                    ) : (
                                                        <p className="text-start">{fecha}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-end">Hora de operación:</p>
                                                </div>
                                                <div className="col-6">
                                                    {hora === 'Invalid Date' ? (
                                                        <p className="text-start">{currentDate.toLocaleTimeString()}</p>
                                                    ) : (
                                                        <p className="text-start">{hora}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-end">De la cuenta origen:</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="text-start"> {item[1]} </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-end">De la institución bancaria:</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="text-start">
                                                        {item[2] != null ? (item[2]) : ('*****')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-end">A nombre de :</p>
                                                </div>
                                                <div className="col-6">
                                                    <p className="text-start">
                                                        {item[3] != null ? (item[3]) : ('*****')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    {type === 11 ? (
                                                        <p className="text-end">A la tarjeta destino:</p>
                                                    ) : (
                                                        <p className="text-end">A la cuenta destino:</p>
                                                    )}
                                                </div>
                                                <div className="col-6 text-start">
                                                    <p className="text-start">{item[4]}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    {type === 11 ? (
                                                        <p className="text-end">Al teléfono del destinatario:</p>
                                                    ) : (
                                                        <p className="text-end">De la institución bancaria:</p>
                                                    )}
                                                </div>
                                                <div className="col-6">
                                                    <p className="text-start">{item[5]}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    {type === 11 ? (
                                                        <p className="text-end">Al correo del destinatario:</p>
                                                    ) : (
                                                        <p className="text-end">A nombre de:</p>
                                                    )}
                                                </div>
                                                <div className="col-6 text-start">
                                                    <p className="text-start">
                                                        {item[6] != null ? (item[6]) : ('*****')}
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    <p className="text-end">Concepto:</p>
                                                </div>
                                                <div className="col-6 text-start">
                                                    {type === 11 ? (
                                                        <p className="text-start">{item[7]}</p>
                                                    ) : (
                                                        <p className="text-start">{item[8]}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    <p className="text-end">Monto a transferir:</p>
                                                </div>
                                                <div className="col-6 text-start">
                                                    {type === 11 ? (
                                                        <p className="text-start">{dollarUS.format(item[8])}</p>
                                                    ) : (
                                                        <p className="text-start">{dollarUS.format(item[9])}</p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    <p className="text-end">Número de referencia:</p>
                                                </div>
                                                <div className="col-6 text-start">
                                                    {type === 11 ? (
                                                        <p className="text-start">{item[9]} </p>
                                                    ) : (
                                                        <p className="text-start">{item[10]} </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 text-end">
                                                    <p className="text-end">Clave de rastreo:</p>
                                                </div>
                                                <div className="col-6 text-start">
                                                    <p className="text-start">{item[0]}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <h6>SitesCapital S.A. DE C.V., SOFOM, E.N.R</h6>
                            </div>
                            <br />
                        </div>
                    ))}
                    <hr />
                </div>
                <script
                    src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js"
                    integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL"
                    crossOrigin="anonymous"
                ></script>
            </body>
        </html>
    );
}

export default MultiFilePrintTicket;