import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { putData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';


function ProfileCompany({ id, loadData, reload, tipo }) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [companyData, setCompanyData] = useState({
        id: 0,
        ingresosMensuales: 0,
        montoMax: 0,
        cantOpMensual: 0
    });

    useEffect(() => {
        if(loadData) {
            //console.log(loadData);
            setCompanyData({
                id: loadData.perfil.id,
                ingresosMensuales: loadData.perfil.ingresosMensuales,
                montoMax: loadData.perfil.montoMax,
                cantOpMensual: loadData.perfil.cantOpMensual
            });
        }
    }, [loadData]);

    const handleOnChange = (e) => {
		const { id, value } = e.target;
        setCompanyData((prevData) => ({
			...prevData,
			[id]: value
		}));
	};

    const saveData = () => {

        //console.log(companyData);
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const dataToCreate = {
            id: companyData.id,
            ingresosMensuales: companyData.ingresosMensuales,
            montoMax: companyData.montoMax,
            cantOpMensual: companyData.cantOpMensual
        }
        //console.log('Mandamos perfil a BD: ', dataToCreate);
        const strPayload = JSON.stringify(dataToCreate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};


        putData(process.env.REACT_APP_ADMIN_PROFILES+ id + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                //console.log(response);
                setAlert({tipo: 'success', msn: 'Perfil actualizado correctamente', show: true});
                //handleCloseModal();
                reload();
            }
        });

    }
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    return (
        <div>
            <div className='row bg-title-admin'>
                <div className='col-sm-6'>
                    <h5 className="title-admin">Datos del perfil {tipo} </h5>
                </div>
                <div className='col-sm-6'>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button className="btn btn-success" onClick={saveData} type="button">Guardar</button>
                    </div>
                </div>
            </div>
            <hr></hr>
            <div className='container-admin'>
                <Message alert={alert} closeAlert={closeAlert} />
                <div className='row row-admin'>
                    <div className='col-md-3 col-admin'>
                    </div>
                    <div className='col-md-6'>
                        <label className="col-form-label-admin">Ingresos mensuales</label>
                        <input type="text" className="form-control txtControl" id="ingresosMensuales" value={companyData.ingresosMensuales} onChange={handleOnChange}/>
                    </div>
                </div>
                <div className='row row-admin'>
                    <div className='col-md-3 col-admin'>
                    </div>
                    <div className='col-md-6'>
                        <label className="col-form-label-admin">Monto máximo mensual</label>
                        <input type="text" className="form-control txtControl" id="montoMax" value={companyData.montoMax} onChange={handleOnChange}/>
                    </div>
                </div>
                <div className='row row-admin'>
                    <div className='col-md-3 col-admin'>
                    </div>
                    <div className='col-md-6'>
                        <label className="col-form-label-admin">Número de operaciones mensuales</label>
                        <input type="text" className="form-control txtControl" id="cantOpMensual" value={companyData.cantOpMensual} onChange={handleOnChange}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileCompany
