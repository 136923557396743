import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { fetchData, putData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';
//import * as catalogs from '../../utils/catalogs';

function PersonaFisica({ id, loadData, reload }) {
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [companyData, setCompanyData] = useState({
        id: 0,
        idCliente: 0,
        domicilioId: 0,
        perfilId: 0,
        nombre: '',
        razonSocial: '',
        rfc: '',
        udnId: 1268,
        nivelCuenta: 4,
        uuid: '',
        costoFijoSpeiIn: 0,
        costoPctSpeiIn: 0,
        costoFijoSpeiOut: 0,
        costoPctSpeiOut: 0,
        tieneDist: 0,
        limiteCredito: 0,
        comisionTarjeta: 0
    });

    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 1268,
        value: 'Seleccione la UDN de la Empresa'
    }]);

    const [udnUpdated, setUdnUpdated] = useState(false);

    //traemos los datos que se necesitan desde los servicios
    useEffect(() => {
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            console.log(error);
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const udns = response.udns.map(item => ({
                            id: item.id, //idUdn,
                            value: item.descripcion,
                            //clientId: item.id
                        }));
                        //console.log(udns);
                        setUdnsToSelect(udns);
                    }
                });
        }
        if(!udnUpdated) {
            //console.log('traigo udns');
            getUdns();
            setUdnUpdated(true);
        }

    }, [navigate, udnUpdated]);

    useEffect(() => {
        if(loadData.id && udnUpdated) {
            //console.log('actualizo loadData: ', loadData);
            setCompanyData({
                id: loadData.id,
                udnId: loadData.udnId, //idCliente o udnId  TODO: este valor está cambiando porque se carga la lista de UDNs despues
                perfilId: loadData.perfilId,
                domicilioId: loadData.domicilioId,
                razonSocial: loadData.razonSocial,
                rfc: loadData.rfc,
                nivelCuenta: loadData.nivelCuenta,
                uuid: loadData.uuid,
                idCliente: loadData.clienteId,
                nombre: loadData.nombre,
                costoFijoSpeiIn: loadData.costoFijoSpeiIn,
                costoPctSpeiIn: loadData.costoPctSpeiIn,
                costoFijoSpeiOut: loadData.costoFijoSpeiOut,
                costoPctSpeiOut: loadData.costoPctSpeiOut,
                tieneDist: loadData.tieneDist,
                limiteCredito: loadData.limiteCredito,
                comisionTarjeta: loadData.comisionTarjeta
            });
        }
    }, [loadData, udnUpdated]);

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === 'costoFijoSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            if (value !== '' && errorIn.integer(value)) {
                e.target.value = companyData[id] || '';
                return;
            }
        }

        if (id === 'costoPctSpeiIn' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }

        if (id === 'costoPctSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }

        if (id === 'costoFijoSpeiOut' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'limiteCredito' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'comisionTarjeta' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = companyData[id] || '';
            return;
        }
        /*
        if (id === 'nombre' && errorIn.lengthTrim(value, 1, 100, 'error en nombre')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'numIdentificacion' && errorIn.lengthTrim(value, 1, 30, 'error en numIdentificacion')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'rfc' && errorIn.lengthTrim(value, 1, 13, 'error en rfc')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'razonSocial' && errorIn.lengthTrim(value, 1, 255, 'error en razon social')) {
            e.target.value = companyData[id] || '';
            return;
        }
        if (id === 'telefono' && errorIn.lengthTrim(value, 1, 16, 'error en telefono')) {
            e.target.value = companyData[id] || '';
            return;
        }
        
        if (id === 'email' && (errorIn.email(value, 'error en email') || errorIn.lengthTrim(value, 1, 45, 'error en email'))) {
            e.target.value = companyData[id] || '';
            return;
        }
        
        if (id === 'serieFirmaElect' && errorIn.lengthTrim(value, 1, 50, 'error en serieFirmaElect')) {
            e.target.value = companyData[id] || '';
            return;
        }
        */

        setCompanyData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    };

    const UpdateCompany = () => {
        //guardo... luego regreso
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
        if(errorIn.lengthTrim(companyData.razonSocial, 1, 250, 'Error en razonSocial')) {
            setAlert({tipo: 'danger', msn: 'Error en razón social', show: true});
            return;
        }
        if(errorIn.lengthTrim(companyData.rfc, 12, 13, 'Error en rfc')) {
            setAlert({tipo: 'danger', msn: 'Error en rfc', show: true});
            return;
        }
        if(errorIn.lengthTrim(companyData.nombre, 1, 100, 'Error en nombre')) {
            setAlert({tipo: 'danger', msn: 'Error en nombre', show: true});
            return;
        }
        if(errorIn.floatPoint(companyData.costoFijoSpeiIn, 'error')) {
            setAlert({tipo: 'danger', msn: 'Error en Costo Fijo SPEI In', show: true});
            return;
        }
        if(errorIn.floatPoint(companyData.costoPctSpeiIn, 'error')) {
            setAlert({tipo: 'danger', msn: 'Error en Costo Porcentual SPEI In', show: true});
            return;
        }
        if(errorIn.floatPoint(companyData.costoFijoSpeiOut, 'error')) {
            setAlert({tipo: 'danger', msn: 'Error en Costo Fijo SPEI Out', show: true});
            return;
        }
        if(errorIn.floatPoint(companyData.costoPctSpeiOut, 'error')) {
            setAlert({tipo: 'danger', msn: 'Error en Costo Porcentual SPEI Out', show: true});
            return;
        }

        const dataToUpdate = {
            id: companyData.id,
            razon_social: companyData.razonSocial,
            rfc: companyData.rfc,
            nivelCuenta: companyData.nivelCuenta,
            nombre: companyData.nombre,
            costoFijoSpeiIn: companyData.costoFijoSpeiIn,
            costoPctSpeiIn: companyData.costoPctSpeiIn,
            costoFijoSpeiOut: companyData.costoFijoSpeiOut,
            costoPctSpeiOut: companyData.costoPctSpeiOut,
            limiteCredito: companyData.limiteCredito,
            comisionTarjeta: companyData.comisionTarjeta
        }
        var urlToPut = process.env.REACT_APP_ADMIN_COMPANIES;
        var idToUpdate = id;

        //console.log('Mandamos a BD: ', dataToUpdate);
        const strPayload = JSON.stringify(dataToUpdate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        //actualizamos empresa con parametro de isPF
        putData(urlToPut + idToUpdate + "?ispf=true&token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                //console.log(response);
                setAlert({tipo: 'success', msn: 'Generales de PF actualizados satisfactoriamente', show: true});
                //handleCloseModal();
                reload();
            }
        });
    }

    function Options({ options }) {
        //console.log(options);
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    return (
        <div>
            <Message alert={alert} closeAlert={closeAlert} />
            <div>
                <h5 className="title-admin">Datos generales de la persona física</h5>
                <hr></hr>
                <div className='container-admin'>
                <div className='row row-admin'>
                    <div className='col-md-6 col-admin'>
                        <label className="col-form-label-admin">UDN</label>
                        <select className="form-select txtControl" id="udnid" value={companyData.idCliente} onChange={handleChange} disabled >
                            <Options options={udnsToSelect} />
                        </select>
                    </div>
                    <div className='col-md-6' >
                        <label className="col-form-label-admin">Nombre</label>
                        <input type="text" className="form-control txtControl" value={companyData.nombre} id='nombre' placeholder="Nombre" onChange={handleChange} />
                    </div>
                </div>
                <div className='row row-admin'>
                    <div className='col-md-6 col-admin'>
                        <label className="col-form-label-admin">RFC</label>
                        <input type="text" className="form-control txtControl" value={companyData.rfc} id='rfc' placeholder="RFC" onChange={handleChange} />
                    </div>
                    <div className='col-md-6'>
                        <label className="col-form-label-admin">Razón Social</label>
                        <input type="text" className="form-control txtControl" value={companyData.razonSocial} id='razonSocial' placeholder="Razón social" onChange={handleChange} />
                    </div>
                </div>
                <div className='row row-admin'>
                    <div className='col-md-6 col-admin' >
                    </div>
                    <div className="col-lg-6 ">
                        <label className="col-form-label-admin">Nivel de Cuenta</label>
                        <select className="form-select txtControl" id="nivelCuenta" value={companyData.nivelCuenta} onChange={handleChange}>
                            <option value="4">Nivel 4</option>
                            <option value="1">Nivel 1</option>
                            <option value="2">Nivel 2</option>
                            <option value="3">Nivel 3</option>
                        </select>
                    </div>
                </div>
                <div className="row row-admin">
                    <div className="col-lg-6 col-admin">
                        <label className="col-form-label-admin">Comisión por costo fijo SPEI In</label>
                        <input type="text" className="form-control txtControl" id='costoFijoSpeiIn' value={companyData.costoFijoSpeiIn} onChange={handleChange} placeholder="Comisión con Costo Fijo para Spei IN" />
                    </div>
                    <div className="col-lg-6 ">
                        <label className="col-form-label-admin">Comisión por porcentaje SPEI In</label>
                        <input type="text" className="form-control txtControl" id='costoPctSpeiIn' value={companyData.costoPctSpeiIn} onChange={handleChange} placeholder="Comisión con por porcentaje para Spei IN" />
                    </div>
                </div>
                <div className="row row-admin">
                    <div className="col-lg-6 col-admin">
                        <label className="col-form-label-admin">Comisión por costo fijo SPEI Out</label>
                        <input type="text" className="form-control txtControl" id='costoFijoSpeiOut' value={companyData.costoFijoSpeiOut} onChange={handleChange} placeholder="Comisión con Costo Fijo para Spei OUT" />
                    </div>
                    <div className="col-lg-6 ">
                        <label className="col-form-label-admin">Comisión por porcentaje SPEI Out</label>
                        <input type="text" className="form-control txtControl" id='costoPctSpeiOut' value={companyData.costoPctSpeiOut} onChange={handleChange} placeholder="Comisión con por porcentaje para Spei OUT" />
                    </div>
                </div>
                <div className="row row-admin">
                    <div className="col-lg-6 col-admin">
                        <label className="col-form-label-admin">Limite de crédito</label>
                        <input type="text" className="form-control txtControl" id="limiteCredito"  value={companyData.limiteCredito} onChange={handleChange} placeholder="" />
                    </div>
                    <div className="col-lg-6 ">
                        <label className="col-form-label-admin">Comisión por recarga de tarjetas SitesPay</label>
                        <input type="text" className="form-control txtControl" id="comisionTarjeta"  value={companyData.comisionTarjeta} onChange={handleChange} placeholder="" />
                    </div>
                </div>
                </div>
            </div>


            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button className="btn btn-success" type="button" onClick={() => UpdateCompany()} >Guardar</button>
            </div>
        </div>
    )
}

export default PersonaFisica
