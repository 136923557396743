import React, { useState, useEffect } from 'react'
import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request';
import { useNavigate } from 'react-router-dom';
import { getInstitutionName, instituciones, getInstitutionCode } from '../../services/institution';
import * as errorIn from '../../utils/validations';

import '../../css/body.css'
import '../../css/forms.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import MostUsedKeys from '../../components/Modals/MostUsedKeys';
import ConfirmOperation from '../../components/Modals/ConfirmOperation'
import Message from '../../components/Message/Message';

function TransferAccounts() {
    const isLogin = true;
    const [isSelect, setIsSelect] = useState(false);

    //const [selected ,setSelectedRow] = useState(null);
    const [accounts, setAccounts] = useState([]);
    const [otherAccounts, setOtherAccounts] = useState([]);
    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const [transferData, setTransferData] = useState({
        origin: '',
        destination: '',
        destinationBank: '',
        beneficiary: '',
        email: '',
        amount: '',
        concept: '',
        reference: '',
        toBankCode: ''
    });
    const navigate = useNavigate();
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [showModal, setShowModal] = useState(false);
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => { setShowModal(false); setConfirmDisabled(false); }

    const handleRowSelect = (row) => {
        setTransferData(prevData => ({
            ...prevData,
            destination: row.cuentaTercero,
            beneficiary: row.nombreTercero,
            //destinationBank: getInstitutionName(row.cuentaTercero.substring(0, 3))
        }));
        setDestinationBank(row.cuentaTercero);
        //(row.num);
        //console.log("Row: " + row.cuentaTercero);
    }

    const title = "cuenta que deses utilizar";

    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setAccounts(response.cuentas)
                } else if (error) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    }
                }
            });
    }, [navigate]);

    const handleChangeOrigin = (e) => {
        const value = e.target.value;

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        setOtherAccounts([]);

        setTransferData({
            ...transferData,
            origin: value,
        });

        fetchData(process.env.REACT_APP_ACCOUNT_OTHERS + value + "/?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (response) {
                    setOtherAccounts(response);
                    if (response.length > 0) {
                        setIsSelect(true);
                    } else {
                        setIsSelect(false);
                    }
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                    return;
                }
            });
    };
    function changeBeneficiary(value) {
        let inputValue = value;
        //const usCharacterPattern = /^[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ ]*$/;
        const usCharacterPattern = /^[A-Za-z0-9 ]*$/;
        // Test the input value against the regular expression pattern
        if (!usCharacterPattern.test(inputValue)) {
            // If it contains non-US characters, remove them and update the input value
            inputValue = inputValue.replace(/[^A-Za-z0-9 ]/g, '');
        }
        return inputValue;
    }

    const setDestinationBank = (act) => {
        const bankCode = getInstitutionCode(act.substring(0, 3));
        // no se como hacer esto en REACT... va a la JS normalito
        // console.log(bankCode);
        transferData.toBankCode = bankCode;
        setTransferData((prevData) => ({
            ...prevData,
            toBankCode: bankCode,
            destinationBank: getInstitutionName(act.substring(0, 3))
        }));

        // const mySelect = document.getElementById('bancoDestino');
        // mySelect[bankCode].selected = true;
    }
    const handleChangeBancoDestino = (e) => {

        const { id, value } = e.target;

        setTransferData((prevData) => ({
            ...prevData,
            toBankCode: value,
            destinationBank: e.target[e.target.selectedIndex].text
        }));

    }
    const handleChange = (e) => {
        var { id, value } = e.target;
        //var destinationBank = '';
        setAlert({ tipo: '', msn: '', show: false });

        if (id === 'destination' && value !== '' && errorIn.integer(value)) {
            e.target.value = transferData[id] || '';
            return;
        }
        if (id === 'destination' && value.length === 16) {
            // podriamos pensar que es débito
            //destinationBank = 'Tarjeta de débito'; //getInstitutionName(value.substring(0, 3));
            if (errorIn.luhnCheck(value)) {
                setAlert({ tipo: 'danger', msn: 'Si es débito, verifique el número de cuenta, dígito verificador incorrecto', show: true });
            }
        }
        if (id === 'destination' && value.length === 18) {
            //destinationBank = getInstitutionName(value.substring(0, 3));
            setDestinationBank(value.substring(0, 3));
            if (errorIn.confirmDV(value)) {
                setAlert({ tipo: 'danger', msn: 'Verifique el número de cuenta, dígito verificador incorrecto', show: true });
            }
        }

        if (id === 'beneficiary') {
            value = changeBeneficiary(value);
        }

        if (id === 'amount' && value !== '' && errorIn.floatPoint(value, ' ') && errorIn.money(value, ' ')) {
            e.target.value = transferData[id] || '';
            return;
        }

        if (id === 'reference' && value !== '' && errorIn.integer(value)) {
            e.target.value = transferData[id] || '';
            return;
        }

        //        if(destinationBank==='') {
        setTransferData((prevData) => ({
            ...prevData,
            [id]: value,
        }));
        // } else {
        //     //console.log('is destinationBank: ', destinationBank);
        //     setTransferData((prevData) => ({
        //         ...prevData,
        //         [id]: value,
        //         ['destinationBank']: destinationBank
        //     }));
        // } 
    };

    const validateTransactionData = () => {
        const errors = [];

        if (errorIn.length(transferData.origin, 16, 18, "Cuenta origen inválida")) errors.push("Cuenta origen inválida");
        if (errorIn.length(transferData.destination, 16, 18, "Cuenta destino inválida")) errors.push("Cuenta destino inválida");
        if (transferData.destination.length > 16) {
            if (errorIn.confirmDV(transferData.destination))
                errors.push("Cuenta destino (clabe) inválida");
        } else {
            if (errorIn.luhnCheck(transferData.destination))
                errors.push("Cuenta destino (débito) inválida");
        }
        if (errorIn.length(transferData.destinationBank.trim(), 1, 100, "Banco destino inválido")) errors.push("Banco destino inválido");
        if (errorIn.length(transferData.beneficiary.trim(), 1, 100, "Nombre de beneficiario inválido")) errors.push("Nombre de beneficiario inválido");
        if (transferData.email.trim() !== '' && errorIn.email(transferData.email, "Email inválido")) errors.push("Email inválido");
        if (errorIn.money(transferData.amount, "Monto inválido")) errors.push("Monto inválido");
        if (errorIn.lengthTrim(transferData.concept, 1, 30, "Concepto inválido")) errors.push("Concepto inválido");
        if (errorIn.numeric(transferData.reference, "Referencia inválida")) errors.push("Referencia inválida");
        if (transferData.reference === '' || errorIn.numeric(transferData.reference, "Referencia inválida")) errors.push("Referencia inválida");

        if (errors.length > 0) {
            setAlert({ tipo: 'danger', msn: errors.join('\n'), show: true });
        } else {
            setConfirmDisabled(true);
            setAlert({ tipo: '', msn: '', show: false });
            handleShowModal();
        }
    };

    const closeAlert = (alert) => {
        setConfirmDisabled(false);
        setAlert(alert);
    };

    const resetTranferData = () => {
        setTransferData({
            origin: '',
            destination: '',
            destinationBank: '',
            beneficiary: '',
            email: '',
            amount: '',
            concept: '',
            reference: ''
        });
    };

    //<input id='destinationBank' disabled={true} className="form-control" value={transferData.destinationBank} placeholder="Nombre del Banco Destino" />

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className="container-fluid2 position-absolute space-acconts">
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-body-container-bg'>
                                    <div className="row contentTitle">
                                        <div className="col-md-9">
                                            <h4 className="subTitle2">Traspaso a cuentas de terceros</h4>
                                        </div>
                                        <div className="col-md-3 divContainerBtn">
                                            <div className="d-grid">
                                                <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body-card">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <div className="row containerRowCard">
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos de cuentas</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <label className="form-label">Cuenta origen</label>
                                                            <select className="form-select" id="origin" value={transferData.origin} onChange={handleChangeOrigin}>
                                                                <option value='0'>Seleccione la cuenta origen</option>
                                                                {accounts.map((account) => (
                                                                    <option
                                                                        key={account.id}
                                                                        value={account.accountNumber}>
                                                                        {account.accountNumber} - {account.description}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            {isSelect ? (
                                                                <div className="contentCenter">
                                                                    <p className="txtMas" data-bs-toggle="modal" data-bs-target="#clavesModal">Mostar cuentas clabe mas utilizadas</p>
                                                                </div>
                                                            ) : (null)}
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Cuenta destino</label>
                                                            <input id="destination" onChange={handleChange} type="text" className="form-control" value={transferData.destination} maxLength='18' />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Banco destino</label>
                                                            <select className="form-select" id="bancoDestino" value={transferData.toBankCode} onChange={handleChangeBancoDestino}>
                                                                {instituciones.map((bank) => (
                                                                    <option key={bank.id} value={bank.codigo}> {bank.description} </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Nombre de Beneficiario</label>
                                                            <input id='beneficiary' onChange={handleChange} maxLength='100' type="text" className="form-control" value={transferData.beneficiary} placeholder="Nombre del Beneficiario" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Correo electrónico (opcional)</label>
                                                            <input type="email" id='email' value={transferData.email} onChange={handleChange} className="form-control" placeholder="ejemplo@gmail.com" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3 mb-sm-0">
                                            <div className="card card-caratula">
                                                <div className="card-body">
                                                    <h5 className="text-3">Datos de transferencia</h5>
                                                    <hr></hr>
                                                    <div className="contentDivRow">
                                                        <div className="mb-3">
                                                            <label className="form-label">Monto a Tansferir</label>
                                                            <input id="amount" value={transferData.amount} onChange={handleChange} className="form-control" placeholder="$0.00" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Concepto</label>
                                                            <input type="text" id="concept" value={transferData.concept} onChange={handleChange} maxLength='30' className="form-control" placeholder="Concepto" />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Número de referencia</label>
                                                            <input type="text" id="reference" value={transferData.reference} onChange={handleChange} maxLength='7' className="form-control" placeholder="Número de referencia" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-grid contentBtn2">
                                        <button onClick={validateTransactionData} className="btnTraspasos" type="button" disabled={confirmDisabled}>Realizar Traspaso</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <MostUsedKeys tipo={1} otherAccounts={otherAccounts} onSelect={handleRowSelect} title={title} />
            <ConfirmOperation transferData={transferData} transferType={2} show={showModal} handleClose={handleCloseModal} resetTranferData={resetTranferData} />
        </div>
    )
}

export default TransferAccounts
