import React, { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto';

import '../../css/body.css'

function LineChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        // Datos de ejemplo
        const data = {
            labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
            datasets: [
                {
                    label: 'Noviembre',
                    data: [[180, 50, 15, 40, 50], [200, 150, 50, 250], [150, 280, 30, 240],[10, 250, 300, 40],[50, 100, 150, 200]],
                    borderColor: 'rgba(75,192,192,1)',
                    borderWidth: 2,
                },
                {
                    label: 'Octubre',
                    data: [100, 45, 120, 20, 300],
                    borderColor: 'rgba(7,12,92,1)',
                    borderWidth: 2,
                },
            ],
        };

        // Configuración de la gráfica
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: 'category', // Assuming your x-axis data is categorical (like month names)
                position: 'bottom',
                beginAtZero: true,
                title: {
                  display: true,
                  text: 'Semanas',
                },
              },
              y: {
                type: 'linear',
                position: 'left',
              },
            },
          };

        // Crear la gráfica
        const ctx = chartRef.current.getContext('2d');
        const myChart = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options,
        });

        // Limpieza al desmontar el componente
        return () => {
            myChart.destroy();
        };
    }, []); // El segundo parámetro asegura que el efecto se ejecute solo una vez al montar el componente

    return (
        <div className='chartLine'>
            <canvas ref={chartRef} />
        </div>
    )
}

export default LineChart
