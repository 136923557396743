import React, { useState, useRef, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as xlsx from 'xlsx';
import * as validate from '../../utils/validations';

function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function BulkLoad({ show, handleClose }) {
    const navigate = useNavigate();
    const fileInputRef = useRef();
    const [selectedFile, setSelectedFile] = useState();
    const [newUsers, setNewUsers] = useState([]);
    const [totalRows, setTotalRows] = useState('');
    const [showDetails, setShowDetails] = useState(false);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [baseData, setBaseData] = useState({
        udnId: 0,
        companyId: 0,
        idDependenDe: 0
    });
    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN',
        key: 0
    }]);
    const [companiesList, setCompaniesList] = useState([]);
    const [userList, setUserList] = useState([]);

    const loadCompanies = (localUID) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        const localID = localUID ? localUID : baseData.udnId;
        if (localID) {

            fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + localID + "/?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const companies = response.empresas.map(item => ({
                            id: item.idEmpresa,
                            value: item.razonSocial,
                        }));
                        setCompaniesList(companies);
                        if (companies[0]) {
                            setBaseData((prevData) => ({
                                ...prevData,
                                'companyId': companies[0].id
                            }));
                        }
                    }
                });
        } else {
            setCompaniesList([]);
            setBaseData({
                udnId: 0,
                companyId: 0
            });
        }
    }

    useEffect(() => {
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const udns = response.udns.map(item => ({
                            id: item.idUdn,
                            value: item.descripcion,
                            key: item.id
                        }));
                        setUdnsToSelect(udns);
                        if (udns[0]) {
                            setBaseData((prevData) => ({
                                ...prevData,
                                udnId: udns[0].id
                            }));
                            loadCompanies(udns[0].id);
                            // deberiamos cargar aqui las "empresas de la udn inicial"
                        }
                    }
                });
        }
        function getUsers() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_AUTH + "?token=" + userData.token, headers)
            .then(({ response, error }) => {
                if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }

                if (response) {
                    const users = response.usuarios.map(item => ({
                        id: item.idUsuario,
                        value: item.email + ' ' + item.nombreUsuario,
                        key: item.idUsuario
                    }));
                    setUserList(users);
                }
            });
        }
        getUdns();
        getUsers();

    }, [navigate]);

    function validateTransactionData(trx) {
        const validationResult = [];

        const addValidation = (errorMessage) => {
            if (errorMessage) {
                validationResult.push(`${errorMessage} en registro ${trx.id}`);
            }
        };
        var datoSanitizado = '';
        //addValidation(validate.lengthTrim(trx.RFC, 13, 13, "Error en RFC"));
        if( trx.Email.indexOf('@gmial.com') > -1 ) {
            validationResult.push(`Posible error en EMAIL en registro ${trx.id}; ¿Está seguro que el dominio es @gmial.com?`);
        }
        if( trx.Email.indexOf('@hotmial.com') > -1 ) {
            validationResult.push(`Posible error en EMAIL en registro ${trx.id}; ¿Está seguro que el dominio es @hotmial.com?`);
        }
        addValidation(validate.lengthTrim(trx.Email, 5, 255, "Error en EMAIL"));
        addValidation(validate.email(trx.Email, "Error en EMAIL"));

        datoSanitizado = validate.sanitizeTextLettersOnly(trx.Nombre_s);
        if (datoSanitizado !== trx.Nombre_s) {
            validationResult.push(`Error en NOMBRE en registro ${trx.id}; solo acepta letras, sin acentos ni números`);
        }
        addValidation(validate.lengthTrim(trx.Nombre_s, 1, 100, "Error en NOMBRE"));

        datoSanitizado = validate.sanitizeTextLettersOnly(trx.Apellido_s);
        if (datoSanitizado !== trx.Apellido_s) {
            validationResult.push(`Error en APELLIDOS en registro ${trx.id}; solo acepta letras, sin acentos ni números`);
        }
        addValidation(validate.lengthTrim(trx.Apellido_s, 1, 100, "Error en APELLIDOS"));

        //addValidation(validate.lengthTrim(trx.Telefono, 10, 10, "Error en TELEFONO, solo números (y 10 posiciones)"));
        // const fechNac = new Date(trx.Fecha_Nacimiento);
        // if(isNaN(fechNac))
        /*
        if (!validate.isValidDate(trx.Fecha_Nacimiento))
            validationResult.push(`Error en fecha de nacimiento en registro ${trx.id}; el formato debe ser aaaa-mm-dd`);

        // trx.Fecha_Nacimiento = fechNac.toDateString();
        if (trx.Identificacion !== 'INE' && trx.Identificacion !== 'PASSPORT')
            validationResult.push(`Error en identificación, en registro ${trx.id}. Favor de usar el excel correcto con los valores disponibles`);
        if (trx.Genero !== 'FEMENINO' && trx.Genero !== 'MASCULINO')
            validationResult.push(`Error en genero, en registro ${trx.id}. Favor de usar el excel correcto con los valores disponibles`);

        addValidation(validate.lengthTrim(trx.Numero_Identificacion, 1, 255, "Error en número de identificación, máximo 200 caracteres"));
        addValidation(validate.lengthTrim(trx.Calle, 1, 100, "Error en Calle, máximo 100 caracteres"));
        addValidation(validate.lengthTrim(trx.Numero_Ext_Int, 1, 40, "Error en número exterior - interior, máximo 40 caracteres"));
        addValidation(validate.lengthTrim(trx.Codigo_Postal, 5, 5, "Error en Código Postal"));
        addValidation(validate.lengthTrim(trx.Ciudad, 1, 100, "Error en Ciudad, máximo 100 caracteres"));
        addValidation(validate.lengthTrim(trx.Estado, 1, 100, "Error en Estado, máximo 100 caracteres"));
        addValidation(validate.lengthTrim(trx.Pais, 1, 3, "Error en Pais. Favor de usar el excel correcto con los valores disponibles"));
        */
        return validationResult;
    }

    const handleSelectedFile = (event) => {
        const excelFile = event.target.files[0];

        //handleReset();
        if (!excelFile) {
            return;
        }

        setSelectedFile(excelFile);
        loadSitesPayExcelFile( excelFile).then( res => {
            var count = 0;
            var hasErrors = false;
            var fullErrorList = [];
            res.forEach(trx => {
                // var re = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);
                // console.log(trx);
                trx.id = trx.__rowNum__ + 1;
                const resultValidation = validateTransactionData( trx );
                if (resultValidation.length < 1) {
                    count++;
                } else {
                    fullErrorList = fullErrorList.concat(resultValidation);
                    hasErrors = true;
                }
                // console.log(trx);
            });

            if (hasErrors) {
                let displayText = "";
                fullErrorList.forEach(errorText => {
                    displayText = displayText + errorText + '\n';
                });
                setAlert({ tipo: 'danger', msn: 'Archivo contiene registros con errores favor de revisar:\n' + displayText, show: true });
                return;
            }
            if (count < 1) {
                setAlert({ tipo: 'danger', msn: 'Archivo dañado o no se cuenta con datos de usuarios para procesar', show: true });
                return;
            }
            // mostrar cuantos registros se tienen, y mostrar un boton para continuar con la carga
            //console.log(res);
            setNewUsers( res );
            setTotalRows('Registros encontrados y listos para procesar: ' + count);
            setShowDetails(true);
            // console.log('n registros leidos: ', count);

        });
    };

    const loadSitesPayExcelFile = (file) => {
        //console.log(excelFile);
        return new Promise(resolve => {
            const reader = new FileReader();
            let result = {};
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = xlsx.read(data, { type: "binary" })
                result['SitesPay1'] = xlsx.utils.sheet_to_json(workbook.Sheets['SitesPay1'], { range: 9 })
                resolve(result.SitesPay1);
            }
            reader.readAsBinaryString(file)
        });
    }

    const handleReset = () => {
        fileInputRef.current.value = '';
        setSelectedFile();
        setNewUsers([]);
        setTotalRows('');
        setShowDetails(false);
        closeAlert();
        handleClose();
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const handleChange = (e) => {
        setAlert({ tipo: '', msn: '', show: false });
        const { id, value } = e.target;

        if (id === 'udnId') {
            loadCompanies(value);
        }

        setBaseData((prevData) => ({
            ...prevData,
            [id]: value
        }));
    }

    const doLoad = () => {
        setAlert({ tipo: '', msn: '', show: false });
        if (baseData && baseData.udnId && baseData.companyId) {
            var usuariosCreados = 0;
            var usuariosConError = 0;
            var usersWithErrors = [];
            let promises = []
            var errores = '';

            newUsers.forEach(( user ) => {
                setTotalRows('Procesando registro ' + (user.id - 10) + ' de ' + newUsers.length);
                // armo objetos a enviar:
                // console.log(user);
                const userToInsert = {
                    username: user.Nombre_s + ' ' + user.Apellido_s,
                    email: user.Email,
                    idClient: parseInt(baseData.udnId),
                    idCompany: baseData.companyId,
                    idRole: '100',
                    pwdAccess: getRandomInt(80000000, 90000000),
                    opsPwd: getRandomInt(80000000, 90000000)
                }
                if( userToInsert.username.length > 100 ) {
                    userToInsert.username = userToInsert.username.substring( 0, 100 );
                }
                /// si hay dependencia, meterla en el objeto, sino, no mandar nada... ??
                if( baseData.idDependenDe ) {
                    userToInsert.idDependeDe = baseData.idDependenDe;
                }
                console.log( userToInsert );

                const userData = JSON.parse(localStorage.getItem('userData'))
                const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
                const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

                const strPayload = JSON.stringify(userToInsert);
                const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
                const payload = { userdata: cryptPayload };
                promises.push(
                    postData(process.env.REACT_APP_AUTH_CREATE_USER + "?token=" + userData.token, payload, headers)
                    .then(({ response, error }) => {
                        if( error ) {
                            usersWithErrors.push( user );
                            usuariosConError++;

                            const responseErrors = error.response.data.errors.errors;
                            if (error.response.status === 401) {
                                localStorage.removeItem('userData');
                                navigate('/');
                                return;
                            } else if (responseErrors !== undefined) {
                                errores = errores + '\n' + responseErrors.map(error => error.message).join('\n');
                            } else {
                                //setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                                errores = errores + '\nError en el sistema, favor de contactarme.';
                            }
                        }

                        //console.log(response);
                        if( response && response.id ) {
                            usuariosCreados++;
                            /*
                            const tarjetaHabienteToInsert = {
                                idUsuario: response.id,
                                idEmpresa: baseData.companyId,
                                rfc: user.RFC,
                                email: user.Email,
                                country: user.Pais,
                                name: user.Nombre_s,
                                surname: user.Apellido_s,
                                phone: user.Telefono,
                                birthdate: user.Fecha_Nacimiento,
                                identificationType: user.Identificacion,
                                identificationValue: user.Numero_Identificacion,
                                gender: (user.Genero === 'MASCULINO') ? 'M' : 'F',
                                streetName: user.Calle,
                                streetNumber: user.Numero_Ext_Int,
                                zipCode: user.Codigo_Postal,
                                city: user.Ciudad,
                                region: user.Estado
                            }
                            const strPayload = JSON.stringify(tarjetaHabienteToInsert);
                            const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
                            const payload = { data: cryptPayload };
                            postData(process.env.REACT_APP_ADMIN_CARDS + "usersFromFile/?token=" + userData.token, payload, headers)
                                .then(({ response, error }) => {
                                    if (error) {
                                        const responseErrors = error.response.data;
                                        if (error.response.status === 401) {
                                            localStorage.removeItem('userData');
                                            navigate('/');
                                            return;
                                        } else if (responseErrors !== undefined) {
                                            setAlert({ tipo: 'danger', msn: responseErrors, show: true });
                                        } else {
                                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                                            console.log(error);
                                        }
                                    }

                                    // if(response) {
                                    //     //console.log(response);
                                    // }
                            });
                            */                                
                        }
                    })
                );
            });
            Promise.all(promises).then(() => {
                //console.log( usuariosCreados );
                //console.log( usersWithErrors );
                setTotalRows( 'Se procesaron correctamente ' + usuariosCreados + ' nuevos usuarios. Se tuvieron: ' + usuariosConError + ' errores.')
                if( errores ) {
                    setAlert({ tipo: 'danger', msn: errores, show: true });
                }
                //fileInputRef.current.value = '';
                //setSelectedFile();
                setNewUsers([]);        
            });
        } else {
            setAlert({ tipo: 'warning', msn: 'Necesita selecciona una UDN y una empresa para asignar las cuentas de los usuarios', show: true });
        }
    }
    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }

    return (
        <div>
            <Modal show={show} onHide={() => { handleReset(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Carga masiva de Usuarios</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className="row-form-text8">
                            <Message alert={alert} closeAlert={closeAlert} />
                            <div className='contentData2'>
                                <p className="formLabel2">Seleccionar el archivo a procesar</p>
                                <div className="row col-select-file">
                                    <div className="col-sm-4 col-btn-choose">
                                        <label className="file-upload-button" htmlFor="fileInput">
                                            <span className='choose-file'>Choose file</span>
                                        </label>
                                    </div>
                                    <div className="col-sm-8">
                                        <input
                                            type="file"
                                            id="fileInput"
                                            accept=".xlsx"
                                            style={{ display: 'none' }}
                                            onChange={handleSelectedFile}
                                            ref={fileInputRef}
                                        />
                                        <input type="text" className="form-control txtControl" readOnly
                                            value={selectedFile ? selectedFile.name : "Seleccionar archivo"}
                                        />
                                    </div>
                                </div>
                                {
                                    showDetails &&
                                    <div className='div-col-data'>
                                        <input type='text' value={totalRows ? totalRows : "Sin registros por procesar"} className="form-control txtControl" readOnly />
                                        <div className="row-form-text9">
                                            <label className="col-form-label">Seleccione la UDN</label>
                                            <select className="form-select" id="udnId" onChange={handleChange} value={baseData.udnId} >
                                                <Options options={udnsToSelect} />
                                            </select>
                                        </div>
                                        <div className="row-form-text9">
                                            <label className="col-form-label">Seleccione la Empresa de la cual dependeran los usuarios</label>
                                            <select className="form-select" id="companyId" onChange={handleChange} value={baseData.companyId} >
                                                <Options options={companiesList} />
                                            </select>
                                        </div>
                                        <div className="row-form-text9">
                                            <label className="col-form-label">Seleccione si existiera un usuario controlador</label>
                                            <select className="form-select" id="idDependenDe" onChange={handleChange} value={baseData.idDependenDe} >
                                                <Options options={userList} />
                                            </select>
                                        </div>
                                        <div className="d-grid gap-2 m-2 d-md-flex justify-content-md-end">
                                            <button onClick={doLoad} type="button" className="btn btn-primary2 btnModal" >Confirmar</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={handleReset} type="button" className="btn btn-primary2 btnModal">Cerrar</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default BulkLoad
