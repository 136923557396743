
const mapping = {
    "001" : "BANXICO",
    "006" : "BANCOMEXT",
    "009" : "BANOBRAS",
    "019" : "BANJERCITO",
    "135" : "NAFIN",
    "166" : "BaBien",
    "168" : "HIPOTECARIA FED",
    "002" : "BANAMEX",
    "012" : "BBVA MEXICO",
    "014" : "SANTANDER",
    "021" : "HSBC",
    "030" : "BAJIO",
    "036" : "INBURSA",
    "042" : "MIFEL",
    "044" : "SCOTIABANK",
    "058" : "BANREGIO",
    "059" : "INVEX",
    "060" : "BANSI",
    "062" : "AFIRME",
    "072" : "BANORTE",
    "106" : "BANK OF AMERICA",
    "108" : "MUFG",
    "110" : "JP MORGAN",
    "112" : "BMONEX",
    "113" : "VE POR MAS",
    "126" : "CREDIT SUISSE",
    "127" : "AZTECA",
    "128" : "AUTOFIN",
    "129" : "BARCLAYS",
    "130" : "COMPARTAMOS",
    "132" : "MULTIVA BANCO",
    "133" : "ACTINVER",
    "136" : "INTERCAM BANCO",
    "137" : "BANCOPPEL",
    "138" : "ABC CAPITAL",
    "140" : "CONSUBANCO",
    "141" : "VOLKSWAGEN",
    "143" : "CIBANCO",
    "145" : "BBASE",
    "147" : "BANKAOOL",
    "148" : "PAGATODO",
    "150" : "INMOBILIARIO",
    "151" : "DONDE",
    "152" : "BANCREA",
    "154" : "BANCO COVALTO",
    "155" : "ICBC",
    "156" : "SABADELL",
    "157" : "SHINHAN",
    "158" : "MIZUHO BANK",
    "159" : "BANK OF CHINA",
    "160" : "BANCO S3",
    "600" : "MONEXCB",
    "601" : "GBM",
    "602" : "MASARI",
    "605" : "VALUE",
    "608" : "VECTOR",
    "613" : "MULTIVA CBOLSA",
    "616" : "FINAMEX",
    "617" : "VALMEX",
    "620" : "PROFUTURO",
    "630" : "CB INTERCAM",
    "631" : "CI BOLSA",
    "634" : "FINCOMUN",
    "638" : "NU MEXICO",
    "642" : "REFORMA",
    "646" : "STP",
    "648" : "EVERCORE",
    "652" : "CREDICAPITAL",
    "653" : "KUSPIT",
    "656" : "UNAGRA",
    "659" : "ASP INTEGRA OPC",
    "661" : "ALTERNATIVOS",
    "670" : "LIBERTAD",
    "677" : "CAJA POP MEXICA",
    "680" : "CRISTOBAL COLON",
    "683" : "CAJA TELEFONIST",
    "684" : "OPM",
    "685" : "FONDO (FIRA)",
    "686" : "INVERCAP",
    "689" : "FOMPED",
    "703" : "TESORED",
    "710" : "NVIO",
    "722" : "MERCADO PAGO",
    "902" : "INDEVAL",
    "903" : "CoDi Valida",
    "812" : "BBVA BANCOMER2*",
    "814" : "SANTANDER2*",
    "821" : "HSBC2*",
    "927" : "AZTECA2*",
    "872" : "BANORTE2*",
    "706" : "ARCUS",
    "802" : "BANAMEX2",
    "860" : "ASP Pruebas"
};

export function getInstitutionName(id) {
    return mapping[id] || '';
}

export const instituciones = [
    { codigo: 2001, id: "001", description: "BANXICO"},
    { codigo: 37006, id: "006", description: "BANCOMEXT"},
    { codigo: 37009, id: "009", description: "BANOBRAS"},
    { codigo: 37019, id: "019", description: "BANJERCITO"},
    { codigo: 37135, id: "135", description: "NAFIN"},
    { codigo: 37166, id: "166", description: "BaBien"},
    { codigo: 37168, id: "168", description: "HIPOTECARIA FED"},
    { codigo: 40002, id: "002", description: "BANAMEX"},
    { codigo: 40012, id: "012", description: "BBVA MEXICO"},
    { codigo: 40014, id: "014", description: "SANTANDER"},
    { codigo: 40021, id: "021", description: "HSBC"},
    { codigo: 40030, id: "030", description: "BAJIO"},
    { codigo: 40036, id: "036", description: "INBURSA"},
    { codigo: 40042, id: "042", description: "MIFEL"},
    { codigo: 40044, id: "044", description: "SCOTIABANK"},
    { codigo: 40058, id: "058", description: "BANREGIO"},
    { codigo: 40059, id: "059", description: "INVEX"},
    { codigo: 40060, id: "060", description: "BANSI"},
    { codigo: 40062, id: "062", description: "AFIRME"},
    { codigo: 40072, id: "072", description: "BANORTE"},
    { codigo: 40106, id: "106", description: "BANK OF AMERICA"},
    { codigo: 40108, id: "108", description: "MUFG"},
    { codigo: 40110, id: "110", description: "JP MORGAN"},
    { codigo: 40112, id: "112", description: "BMONEX"},
    { codigo: 40113, id: "113", description: "VE POR MAS"},
    { codigo: 40126, id: "126", description: "CREDIT SUISSE"},
    { codigo: 40127, id: "127", description: "AZTECA"},
    { codigo: 40128, id: "128", description: "AUTOFIN"},
    { codigo: 40129, id: "129", description: "BARCLAYS"},
    { codigo: 40130, id: "130", description: "COMPARTAMOS"},
    { codigo: 40132, id: "132", description: "MULTIVA BANCO"},
    { codigo: 40133, id: "133", description: "ACTINVER"},
    { codigo: 40136, id: "136", description: "INTERCAM BANCO"},
    { codigo: 40137, id: "137", description: "BANCOPPEL"},
    { codigo: 40138, id: "138", description: "ABC CAPITAL"},
    { codigo: 40140, id: "140", description: "CONSUBANCO"},
    { codigo: 40141, id: "141", description: "VOLKSWAGEN"},
    { codigo: 40143, id: "143", description: "CIBANCO"},
    { codigo: 40145, id: "145", description: "BBASE"},
    { codigo: 40147, id: "147", description: "BANKAOOL"},
    { codigo: 40148, id: "148", description: "PAGATODO"},
    { codigo: 40150, id: "150", description: "INMOBILIARIO"},
    { codigo: 40151, id: "151", description: "DONDE"},
    { codigo: 40152, id: "152", description: "BANCREA"},
    { codigo: 40154, id: "154", description: "BANCO COVALTO"},
    { codigo: 40155, id: "155", description: "ICBC"},
    { codigo: 40156, id: "156", description: "SABADELL"},
    { codigo: 40157, id: "157", description: "SHINHAN"},
    { codigo: 40158, id: "158", description: "MIZUHO BANK"},
    { codigo: 40159, id: "159", description: "BANK OF CHINA"},
    { codigo: 40160, id: "160", description: "BANCO S3"},
    { codigo: 90600, id: "600", description: "MONEXCB"},
    { codigo: 90601, id: "601", description: "GBM"},
    { codigo: 90602, id: "602", description: "MASARI"},
    { codigo: 90605, id: "605", description: "VALUE"},
    { codigo: 90608, id: "608", description: "VECTOR"},
    { codigo: 90613, id: "613", description: "MULTIVA CBOLSA"},
    { codigo: 90616, id: "616", description: "FINAMEX"},
    { codigo: 90617, id: "617", description: "VALMEX"},
    { codigo: 90620, id: "620", description: "PROFUTURO"},
    { codigo: 90630, id: "630", description: "CB INTERCAM"},
    { codigo: 90631, id: "631", description: "CI BOLSA"},
    { codigo: 90634, id: "634", description: "FINCOMUN"},
    { codigo: 90638, id: "638", description: "NU MEXICO"},
    { codigo: 90646, id: "646", description: "STP"},
    { codigo: 90648, id: "648", description: "TACTIV CB"},
    { codigo: 90652, id: "652", description: "CREDICAPITAL"},
    { codigo: 90653, id: "653", description: "KUSPIT"},
    { codigo: 90656, id: "656", description: "UNAGRA"},
    { codigo: 90659, id: "659", description: "ASP INTEGRA OPC"},
    { codigo: 90661, id: "661", description: "ALTERNATIVOS"},
    { codigo: 90670, id: "670", description: "LIBERTAD"},
    { codigo: 90677, id: "677", description: "CAJA POP MEXICA"},
    { codigo: 90680, id: "680", description: "CRISTOBAL COLON"},
    { codigo: 90683, id: "683", description: "CAJA TELEFONIST"},
    { codigo: 90684, id: "684", description: "OPM"},
    { codigo: 90685, id: "685", description: "FONDO (FIRA)"},
    { codigo: 90686, id: "686", description: "INVERCAP"},
    { codigo: 90688, id: "688", description: "CREDICLUB"},
    { codigo: 90689, id: "689", description: "FOMPED"},
    { codigo: 90703, id: "703", description: "TESORED"},
    { codigo: 90710, id: "710", description: "NVIO"},
    { codigo: 90722, id: "722", description: "MERCADO PAGO"},
    { codigo: 90901, id: "901", description: "CLS"},
    { codigo: 90902, id: "902", description: "INDEVAL"},
    { codigo: 90903, id: "903", description: "CoDi Valida"},
    { codigo: 91812, id: "812", description: "BBVA BANCOMER2*"},
    { codigo: 91814, id: "814", description: "SANTANDER2*"},
    { codigo: 91821, id: "821", description: "HSBC2*"},
    { codigo: 91927, id: "927", description: "AZTECA2*"},
    { codigo: 91872, id: "872", description: "BANORTE2*"},
    { codigo: 90706, id: "706", description: "ARCUS"},
    { codigo: 91802, id: "802", description: "BANAMEX2"},
    //{ codigo: 860, id: "860", description: "ASP Pruebas"}
];
instituciones.sort((a, b) => (a.description > b.description) ? 1 : -1);

export function getInstitutionCode(id) {
    for( var i = 0; i < instituciones.length; i++) {
        if( instituciones[i].id === id)
            return instituciones[i].codigo;
    }
    return 0;
}

export function getInstitutionName2(id) {
    //console.log(id);
    for( var i = 0; i < instituciones.length; i++) {
        if( instituciones[i].codigo === id)
            return instituciones[i].description;
    }
    return 0;
}
