import React from 'react'
import '../../css/forms.css'

import TableModal from '../TableModal/TableModal';

function MostUsedKeys({tipo, otherAccounts, onSelect, title}) {

  return (
    <div>
      <div className="modal fade" id="clavesModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body modal-dialog-scrollable">
              <label className="form-label-general">Selecciona la {title} </label>
              <div className="card card-caratula">
                <TableModal tipo={tipo} body={otherAccounts} onSelect={onSelect}/>
              </div>
            </div>
            <div className="modal-footer">
              
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MostUsedKeys
