import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'

import search from '../../assets/icons/search-svgrepo-com.svg'
import Table from '../../components/Table/Table'
import NewUdns from '../../components/Modals/NewUdns'
import EditUdns from '../../components/Modals/EditUdns'
import SearchModal from '../../components/Modals/SearchModal'
import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';

import '../../css/body.css'
import { fetchData, putData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';

function AdministratUdns() {
    const isLogin = true;
    //const isAdmin = true;
    const navigate = useNavigate();
    const [udns, setUdns] = useState([]);
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const selectedOption = "1";
    const [updateData, setUpdateData] = useState(1);
    const reloadCompanyData = () => {
        setUpdateData(updateData + 1);
    }

    //Busqueda
    const [searchData, setSearchData] = useState({
        byIdUdn: '',
        byNameUdn: '',
        byCompanyUdn: ''
    });
    
    useEffect(() => {
        if(updateData)
            getUDNS();
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }, [updateData, pagination.currentPage]);

    const getUDNS = () => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        if(searchData.byNameUdn)
            searchQuery = '&udnname=' + searchData.byNameUdn;
        if(searchData.byCompanyUdn)
            searchQuery = searchQuery + '&udncompanyname=' + searchData.byCompanyUdn;

        fetchData(process.env.REACT_APP_ACCOUNT_CLIENTS + "?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                const responseUdns = response.clientes.map(udn => ({
                    idUdn: udn.idUdn,
                    //nombreCorto: udn.descripcion, //nombre,
                    nombreCorto: udn.nombre,
                    razon_social: udn.razonSocial,
                    rfc: udn.rfc,
                    costoFijoSpeiIn: udn.speiInCosto,
                    costoPctSpeiIn: udn.speiInPct,
                    costoFijoSpeiOut: udn.speiOutCosto,
                    costoPctSpeiOut: udn.speiOutPct,
                    id: udn.id,
                    udnDescripcion: udn.descripcion,
                    esDistribuidor: udn.esDistribuidor,

                    nombrePromotor: udn.nombrePromotor ? udn.nombrePromotor : '',
                    nombreResponsable: udn.nombreResponsable ? udn.nombreResponsable : '',
                    telResponsable: udn.telResponsable ? udn.telResponsable : '',
                    emailResponsable: udn.emailResponsable ? udn.emailResponsable : '',
                    nota: udn.nota ? udn.nota : ''            
                }));
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setUdns(responseUdns);
            }
        });
    };

    const tableHeaders = ['UDN ID', 'Nombre', 'Empresa Controladora','RFC Empresa', 'Acciones'];
    const tableStyles = ['centeredStyle', 'textStyle', 'textStyle','textStyle','centeredStyle'];
    //const tableHeaders = ['UDN ID', 'Nombre', 'Empresa Controladora','RFC Empresa','Número de Empresas','Acciones'];
    //const tableStyles = ['dateStyle', 'textStyle', 'accountStyle','textStyle','amountStyle','amountStyle','centeredStyle'];

    const link = '/administrarUdnEmpresas/';
    const tipo = "UDN";

    const [selected, setSelectedRow] = useState(null);

    const handleRowSelect = (row) => {
        //console.log(row);
        setSelectedRow(row);
    }

    //Modal Buscar
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Modal Agregar
    const [showAdd, setShowAdd] = useState(false);
    const handleCloseAdd = () => setShowAdd(false);
    const handleShowAdd = () => setShowAdd(true);

    //Modal Editar
    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => {
        //console.log(selected);
        setShowEdit(true);
    }

    const isMenu = true;
    const menuIcon = [
        { icon: Edit, url: '', modal: handleShowEdit, clave: '' },
      //  { icon: Delete, url: '', modal: 'modal', clave: '#editarUdn' },
    ]

    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };
    
    const handleUpdate = (data) => {
        //console.log(data);
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const strPayload = JSON.stringify(data);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        putData(process.env.REACT_APP_ADMIN_CLIENTS + data.id + "/?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                handleCloseEdit();
                reloadCompanyData();
            }
         });

    };

    function executeSearch() {
        //console.log(searchData);
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        getUDNS();
    }

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption}/>
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-7 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de unidades de negocio (UDNs)</p>   
                                        </div>
                                        <div className="col-sm-4 contenBtnAdmin">
                                            <button className="btn btn-primary" type="button" onClick={handleShowAdd}>Nueva UDN</button>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={udns} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} isMenu={isMenu} link={link} onSelect={handleRowSelect} tipo='admin' from='udns'
                                            currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems/pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <NewUdns showAdd={showAdd} handleCloseAdd={handleCloseAdd} reload={reloadCompanyData} />
            <EditUdns data={selected} onChange={setSelectedRow} show={showEdit} updateInfo={handleUpdate} handleClose={handleCloseEdit} />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
        </div>
    )
}

export default AdministratUdns
