import React from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import LineChart from '../../components/Chart/LineChart'
import BarChart from '../../components/Chart/BarChart'

function Dashboard() {
    const isLogin = true;

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-admin'>
                <div className='containerAdmin'>
                    <div className='row'>
                        <div className='col-sm-3'>
                            <div className="right-shadow padding-admin">
                                <MenuLateral/>
                            </div>
                        </div>
                        <div className='col-sm-9'>
                            <div className="left-shadow3">
                                <div className='card-sub-title-bg2'>
                                    <h5 className="titelChart">Movimientos</h5>
                                </div>
                                <div className='containerChart'>
                                    <LineChart/>
                                </div>
                                <div className='card-title-bg2'>
                                    <h5 className="subtitelChart">Transferencias</h5>
                                    <h5 className="titelChart">Recurrencia</h5>
                                </div>
                                <div className='containerChart'>
                                    <BarChart/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        
    )
}

export default Dashboard
