import React, {useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';

import { useNavigate } from 'react-router-dom';
import { fetchData, postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';
import * as errorIn from '../../utils/validations';

function NewAccount({data, show, handleClose, reload }) {
    const navigate = useNavigate();
    const [isCreateDisabled, setIsCreateDisabled] = useState(false);
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    var title = 'Nueva cuenta CLABE';

    function Options({ options }) {
        return (
            options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
        );
    }
    const [udnsToSelect, setUdnsToSelect] = useState([{
        id: 0,
        value: 'Seleccione la UDN de la Empresa'
    }]);
    const [empresasToSelect, setEmpresasToSelect] = useState([{
        id: 0,
        value: 'Seleccione la Empresa'
    }]);
    const [companyData, setCompanyData] = useState({
        idCliente: 0,
        idEmpresa: 0,
        nombre: '',
        alias: ''
    });

    useEffect( () => {
        function getUdns() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
    
            fetchData(process.env.REACT_APP_UDNS + "?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }
    
                if (response) {
                    const udns = response.udns.map( item => ({
                        id: item.idUdn,
                        value: item.descripcion,
                    }));
                    setUdnsToSelect(udns);
                    setCompanyData((prevData) => ({
                        ...prevData,
                        'idCliente': udns[0].id
                    }));
                }
             });
        }
        getUdns();
    }, [navigate]);

    useEffect( () => {
        function getEmpresas(id) {
            if(id>0){
                const userData = JSON.parse(localStorage.getItem('userData'))
                const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
                const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
        
                fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + id + "?token=" + userData.token, headers)
                .then(({ response, error}) => {
                    if (error) {
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            navigate('/');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                        } else {
                            setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                        }
                    }
        
                    if (response) {
                        const empresas = response.empresas.map( item => ({
                            id: item.idEmpresa, 
                            value: item.razonSocial,
                        }));
                        
                        setEmpresasToSelect(empresas);
                        setCompanyData((prevData) => ({
                            ...prevData,
                            'idEmpresa': empresas[0].id
                        }));
                    }
                });
            }
        }
        getEmpresas(companyData.idCliente);
    }, [navigate, companyData.idCliente]);

    const handleChange = (e) => {
        setAlert({tipo: '', msn: '', show: false});
		const { id, value } = e.target;
       
        if( id === 'nombre' && errorIn.maxLength(value, 100, 'error en nombre') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id === 'alias' && errorIn.maxLength(value, 20, 'error en alias') ) {
            e.target.value = companyData[id] || '';
            return;
        }
        if( id  === 'bloqueada' ) {
            setCompanyData((prevData) => ({
                ...prevData,
                [id]: !companyData[id]
            }));
            return;
        }
        
		setCompanyData((prevData) => ({
			...prevData,
			[id]: value
		}));
    }

    const handleCreate = () => {
        setAlert({tipo: '', msn: '', show: false});
        setIsCreateDisabled(true);
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        if(!companyData.idEmpresa) {
            setAlert({tipo: 'danger', msn: 'Falta seleccionar empresa', show: true});
            return;
        }
        if(errorIn.lengthTrim(companyData.nombre, 1, 100, 'Error')) {
            setAlert({tipo: 'danger', msn: 'Nombre de cuenta, falta o es demasiado largo (100 max)', show: true});
            return;
        }
        if(errorIn.lengthTrim(companyData.alias, 1, 20, 'Error')) {
            setAlert({tipo: 'danger', msn: 'Alias de cuenta, falta o es demasiado largo (20 max)', show: true});
            return;
        }

        const dataToCreate = {
            companyId: companyData.idEmpresa,
            acctName: companyData.nombre,
            acctAlias: companyData.alias
        }

        const strPayload = JSON.stringify(dataToCreate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {company: cryptPayload};

        postData(process.env.REACT_APP_ACCOUNT_CLABE + "?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                const responseErrors = error.response.data.errors.errors;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if(response) {
                setAlert({tipo: 'success', msn: 'Cuenta creada satisfactoriamente', show: true});
                resetData();
                reload();
            }
            setIsCreateDisabled(false);
        });
    }

    const resetData = () => {
        setCompanyData({
            idCuenta: 0,
            idCliente: 0,
            idEmpresa: 0,
            nombre: '',
            alias: '',
            clabe: '',
            bloqueada: false,
        });
    }

    const handleCloseModal = () => {
        setAlert({tipo: '', msn: '', show: false});
        setCompanyData({
            idCuenta: 0,
            idCliente: 0,
            idEmpresa: 0,
            nombre: '',
            alias: '',
            clabe: '',
            bloqueada: false,
        });
        handleClose();
    }

    const handleExitButtonClick = () => {
        handleClose();
        closeAlert();
        handleCloseModal();
    };

    return (
        <div>
            <Modal show={show} onHide={() => {handleExitButtonClick();}} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h5 className="modal-title"> {title} </h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <div className="row-form-text">
                            <label className="col-form-label">Seleccione UDN de la cual elegir a la empresa</label>
                            <select className="form-select" id="idCliente" value={companyData.idCliente} onChange={handleChange} >
                                <Options options={udnsToSelect} />
                            </select>
                        </div>
                        <div className="row-form-text">
                            <label className="col-form-label">Seleccione la empresa a la cual quiere generarle una nueva CLABE</label>
                            <select className="form-select" id="idEmpresa" value={companyData.idEmpresa} onChange={handleChange} >
                                <Options options={empresasToSelect} />
                            </select>
                        </div>
                        <div className="row row-form-text2">
                            <div className="col-lg-6">
                                <label className="col-form-label">Nombre identificador de la cuenta</label>
                                <input type="text" className="form-control" id="nombre" value={companyData.nombre} onChange={handleChange}  placeholder=" Nombre para identificar la cuenta"/>
                            </div>
                            <div className="col-lg-6">
                                <label className="col-form-label">Alias de la cuenta</label>
                                <input type="text" className="form-control" id="alias" value={companyData.alias} onChange={handleChange}  placeholder=" Alias de la cuenta"/>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={handleCreate} disabled={isCreateDisabled} >Guardar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NewAccount
