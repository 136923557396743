import React, { useState } from "react";
import { useSearchParams, useNavigate } from 'react-router-dom';

import Encrypter from '../../services/Encrypter'

import Header from '../../components/Headers/Header'
import Footer from '../../components/Footer/Footer'
import { postData } from "../../services/Request";

import Message from '../../components/Message/Message'

function RecoverPassword() {
	const isLogin = false;
	const navigate = useNavigate();
	//const { token } = useParams(); //RECIBE EL TOKEN DEL QS
	const [ searchParams ] = useSearchParams();
	const token = searchParams.get("token");
	//console.log(token);
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');

	const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

	const handleChangePass = () => {
		if (password !== passwordConfirm) {
			setAlert({ tipo: 'danger', msn: 'Las contraseñas deben ser iguales', show: true });
		} else {
			const data = { token: token, password: password };
			const changePwd = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(data));
			const headers = { headers: {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':'}}

			postData(process.env.REACT_APP_AUTH_RESETPASS, {data: changePwd}, headers)
			.then(({ response, error }) => {
				if (response) {
					const rspMsg = JSON.parse(response);
					setAlert({tipo: 'success', msn: rspMsg.message, show: true});
				} else if (error) {
					const responseErrors = error.response.data.errors.errors;
					//console.log('Errors: ', responseErrors);
					if (error.response.status === 401) {
						localStorage.removeItem('userData');
						
					} else if (responseErrors!==undefined) {
						console.log(error);
						setAlert({tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true});
					} else {
						console.log(error);
						setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
					}
					return;
				} 
		
				//console.log("R RESETPASS: " + response);
			});
			
		}
	};

	const closeAlert = (alert) => {
		navigate('/');
		setAlert(alert);
	};

	return (
		<div className="body">
			<Header isLogin={isLogin} />
			<div className="container-fluid2 position-absolute space-acconts">
				<div className="containerHome2">
					<div className="container-general">
						<div className="card-body">
							<div className='card-sub-title-bg'>
								<h4 className="subTitle">Recuperación de contraseña</h4>
							</div>
							<div className="bg-container-body-card">
								<div className="card card-caratula containerCardLogin">
									<div className="card-body">
									<Message alert={alert} closeAlert={closeAlert} />
										<form>
											<div className="form-controls-cont">
												<div className="row-form-text5">
													<label className="col-form-label">Ingrese su nueva contraseña</label>
													<input onChange={(e) => setPassword(e.target.value)} type="password" className="form-control txtControl"></input>
												</div>
												<div className="row-form-text5">
													<label className="col-form-label">Confirme su nueva contraseña</label>
													<input onChange={(e) => setPasswordConfirm(e.target.value)} type="password" className="form-control txtControl"></input>
												</div>
											</div>

											<div className="row row-botton">
												<div className="col-lg-8"></div>
												<div className="col-lg-4">
													<div className="d-grid gap-2 mx-auto buttonAdmin">
														<button className="btnLogin" type="button" onClick={handleChangePass} >Cambiar Contraseña de Acceso</button>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	)
}

export default RecoverPassword