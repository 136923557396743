import React, { useState, useEffect } from 'react';
import Pagination from '../Paginations/Pagination';
import './Table.css';

import iconPrint from '../../assets/icons/printer-svgrepo-com.svg'
import iconInfo from '../../assets/icons/information.svg'
import MovementTicket from '../Tickets/MovementTicket';
import ReactDOMServer from 'react-dom/server';

function TableMovements({ headers, data, styles, currentPage, setCurrentPage, totalPages, link, onSelect, addInfo, handleShow,
    selectAll, handleSelectAllChange, selectedRows, handleRowSelectChange }) {
    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const redirectToPage = (id = '') => {
        // Redirige a la página deseada al hacer clic en la fila
        if (link != null) {
            window.location.href = link + id;
        }
    };

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Suscribirse al evento de cambio de tamaño de la ventana cuando el componente se monta
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Limpiar el evento cuando el componente se desmonta
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const queRecibo = ({ data }) => {
        const printWindow = window.open('', '', 'height=800,width=800');
        const ticketHtml = ReactDOMServer.renderToString(<MovementTicket data={data} />); // Convertir el componente a una cadena HTML
        printWindow.document.write(ticketHtml); // Insertar el componente Ticket
        printWindow.document.close();
    }

    return (
        <div>
            <div className="bg-table">
                {windowWidth < 768 ?
                    (
                        <div>
                            {data.map((item, index) => (
                                <div key={index} className="card-table">
                                    <div>
                                        <div className="card-header-table">
                                            <h5 className='header-table'> {item.description} </h5>
                                        </div>
                                        <div className="card-body-table">
                                            <div className='row row-table'>
                                                <div className='col-3'>
                                                    <p className='text-table-date'>{item.date} </p>
                                                </div>
                                                <div className='col-9'>
                                                    <p className='text-table'>{item.operation} </p>
                                                </div>
                                            </div>
                                            <p className='text-table'>Cuenta Desde / hacia: {item.account}</p>
                                            <div className='row row-table'>
                                                <div className='col-6'>
                                                    <p className='text-table-title'> Saldo: <a className=''>{dollarUS.format(item.balance)}</a> </p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='text-table-title2'> Monto: <a className=''>{dollarUS.format(item.amount)}</a> </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <table>
                            <thead className="headtable">
                                <tr className="trHead">
                                    <th>
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                        />
                                    </th>
                                    {headers.map((header, index) => (
                                        <th className="thtable" key={index}>{header}</th>

                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((item, index) => (
                                    <tr className='trtable' key={index} onClick={() => onSelect != null ? onSelect(addInfo ? { index, info: addInfo[index] } : null) : null}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedRows.includes(index)}
                                                onChange={() => handleRowSelectChange(index)}
                                            />
                                        </td>
                                        {Object.values(item).slice(0, headers ? headers.length - 1 : 0).map((value, subIndex) => (
                                            <td key={subIndex} onClick={redirectToPage} className={styles[subIndex]} >
                                                {
                                                    (styles[subIndex] === "amountStyle") || (subIndex === 4 && headers[4] === "Monto")
                                                        || (subIndex === 5 && headers[5] === "Monto") ? dollarUS.format(value) : value
                                                }
                                            </td>
                                        ))}
                                        <td>
                                            <button className='btnMenuTable' onClick={() => queRecibo({ data: item })}>
                                                <img src={iconPrint} alt="" className='iconButton' />
                                            </button>
                                            <button className='btnMenuTable' onClick={handleShow}>
                                                <img src={iconInfo} alt="" className='iconButton' />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                }
            </div>
            <div className='pagination'>
                <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
            </div>
        </div>
    )
}

export default TableMovements
