import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

function CargoDetails({ show, handleClose, handleImproperShow, trxData }) {

    const handleOnClick = () => {
        if( (trxData.tipoOps !== 'PAYMENT' && trxData.tipoOps !== 'REFUND' && trxData.tipoOps !== 'BALANCE_INQUIRY') && (!trxData.statusSites) ) {
            handleClose();
            handleImproperShow();
        } else {
            alert('No puede hacer reclamaciones de pagos o depósitos en su cuenta');
        }
    }

    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Revisar Detalles de la Operación</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className="row row-formtext">
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Fecha:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.fecha : ''} />
                            </div>
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Número de Transacción:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.numTransaction : ''} />
                            </div>
                        </div>
                        <div className="row row-form-text">
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Número de Tarjeta:</label>
                                <input type="text" className="form-control txtControl" id='numCard' readOnly value={trxData ? trxData.tarjeta : ''} />
                            </div>
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Tipo de Operación:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.tipoOps : ''} />
                            </div>
                        </div>
                        <div className="row row-formtext">
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Monto:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.monto : ''} />
                            </div>
                            <div className="col-lg-6 content-input1">
                                <label className="col-form-label">Establecimiento:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.establecimiento : ''} />
                            </div>
                        </div>
                        <div className="row row-formtext">
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Ciudad:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? trxData.ciudad : ''} />
                            </div>
                            <div className="col-lg-6 content-input7">
                                <label className="col-form-label">Estado de la operación:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? (trxData.status ? trxData.status : '') : ''} />
                            </div>
                        </div>
                        <div className="row row-formtext">
                            <div className="col-lg-6 content-input6">
                                <label className="col-form-label">Estatus Pomelo:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? (trxData.statusPom ? trxData.statusPom : '') : ''} />
                            </div>
                            <div className="col-lg-6 content-input7">
                                <label className="col-form-label">Estatus Aclaracion:</label>
                                <input type="text" className="form-control txtControl" readOnly value={trxData ? (trxData.statusSites ? trxData.statusSites : '' ) : ''} />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-primary3 btnModal" onClick={handleOnClick} data={trxData} >Realizar Devolución de Cargo</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CargoDetails