import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/home/home';
import Accounts from './views/accounts/accounts';
import Movements from './views/movements/movements';
import AccountCover from './views/accounts/accountCover';
import TransferAccounts from './views/transfer/transferAccounts';
import RecoverPassword from './views/recoverPassword/recoverPassword';
import TransferBetweenOwnAccounts from './views/transfer/transferBetweenOwnAccounts'
import MultipleTransfers from './views/transfer/multipleTransfers';
import Conciliation from './views/administration/conciliation';
import Dashboard from './views/empresas/dashboard';
import AdministrarUdns from './views/empresas/administerUdns';
import Empresas from './views/empresas/empresas';
import CompanyAccount from './views/empresas/companyAccount';
import CradTransfer from './views/transfer/cradTransfer';
import AdministerUsers from './views/empresas/administerUsers';
import AdministerAccounts from './views/empresas/administerAccounts';
import AdministerUdnAccounts from './views/empresas/administerUdnAccounts';
import Profile from './views/profile/profile';
import UpdateCompany from './views/empresas/updateCompany';
import { ProtectedRoute } from './components/Routes/ProtectedRoute';
import PersonasFisicas from './views/empresas/personasFisicas';
import UpdatePersonaFisica from './views/empresas/updatePfisica';
import UpdateAccount from './views/empresas/updateAccount';
import UpdateUser from './views/empresas/updateUsuarios';
import AccountList from './views/empresas/accountList';
import ManageCards from './views/empresas/manageCards';
import ManageCardholders from './views/empresas/manageCardholders';
import PrivacyPolicies from './views/policies/privacyPolicies';
import Support from './views/support/support';
import InitActivation from './views/activateCard/initActivation';
import CardReady from './views/activateCard/cardReady';
import ActivateCard from './views/activateCard/activateCard';
import MyCards from './views/myCards/myCards';
import SPCardTransfers from './views/transfer/SPCardTransfers';
import Products from './views/homeOut/products';
import Solutions from './views/homeOut/solutions';
import ManageTransactions from './views/empresas/manageTransactions';
import AccountStatus from './views/accounts/accountStatus';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/privacidad' element={<PrivacyPolicies />} />
        <Route path='/soporte' element={<Support />} />
        <Route path='/productos' element={<Products />} />
        <Route path='/soluciones' element={<Solutions />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/accounts' element={<Accounts />} />
          <Route path='/accountCover/:id' element={<AccountCover />} />
          <Route path='/estadosCuenta/:id' element={<AccountStatus />} />
          <Route path='/movements/:id' element={<Movements />} />
          <Route path='/taspasosEntreCuentasPropias' element={<TransferBetweenOwnAccounts />} />
          <Route path='/traspasosAterceros' element={<TransferAccounts />} />
          <Route path='/transferenciasMultiples' element={<MultipleTransfers />} />
          <Route path='/conciliacionOperaciones' element={<Conciliation />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/administrarudns' element={<AdministrarUdns />} />
          <Route path='/empresas' element={<Empresas />} />
          <Route path='/personasfisicas' element={<PersonasFisicas />} />
          <Route path='/cuentasEmpresas/:id' element={<CompanyAccount />} />
          <Route path='/traspasoTarjeta' element={<CradTransfer />} />
          <Route path='/administrarUsuarios' element={<AdministerUsers />} />
          <Route path='/administrarCuentas' element={<AdministerAccounts />} />
          <Route path='/administrarUdnEmpresas/:id' element={<AdministerUdnAccounts />} />
          <Route path='/actualizarEmpresa/:id' element={<UpdateCompany />} />
          <Route path='/actualizarpersonafisica/:id' element={<UpdatePersonaFisica />} />
          <Route path='/actualizarcuenta/:id' element={<UpdateAccount />} />
          <Route path='/actualizarusuario/:id' element={<UpdateUser />} />
          <Route path='/miPerfil' element={<Profile />} />
          <Route path='/listaCuentas/:id' element={<AccountList />} />
          <Route path='/administrarTarjets' element={<ManageCards />} />
          <Route path='/administrarTarjetahabientes' element={<ManageCardholders />} />
          <Route path='/activacionInicial' element={<InitActivation />} />
          <Route path='/tarjetaLista' element={<CardReady />} />
          <Route path='/activarTarjeta' element={<ActivateCard />} />
          <Route path='/MyCards' element={<MyCards />} />
          <Route path='/spcardtransfers' element={<SPCardTransfers />} />
          <Route path='administrarTransacciones' element={<ManageTransactions />} />
        </Route>
        <Route path='/recoverPassword' element={<RecoverPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
