import React, { useState, useEffect, useRef } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import Message from '../../components/Message/Message';
import Table from '../../components/Table/Table'
import SearchModal from '../../components/Modals/SearchModal';
import BulkLoad from '../../components/Modals/BulkLoad';

import * as xlsx from 'xlsx';

function ManageCardholders() {
    const isLogin = true;
    const selectedOption = "7";
    const tipo = "tarjetahabientes";

    //Modal Buscar 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //Modal nuevos usuarios (masivos)
    const [showBulkLoad, setShowBulkLoad] = useState(false);
    const handleCloseBulkLoad = () => setShowBulkLoad(false);
    const handleShowBulkLoad = () => setShowBulkLoad(true);

    const fileInputRef = useRef();
    const [excelFile, setExcelFile] = useState();
    const [selectedFile, setSelectedFile] = useState(null);

    const tableHeaders = [' Tarjetahabiente', ' Correo Electrónico', 'Empresa ', 'Límite de Crédito'];
    const tableStyles = ['textStyle', 'textStyle', 'textStyle', 'centeredStyle'];
    const data = [
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa A', credito: '$1,000,000' },
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa B', credito: '$2,000,000' },
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa C', credito: '$3,000,000' },
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa D', credito: '$4,000,000' },
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa E', credito: '$5,000,000' },
        { tarjeta: 'Marcelo Gómez', correo: 'gomez@gmail.com', empres: 'Empresa F', credito: '$6,000,000' },
    ];

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [pagination, setPagination] = useState({
        itemsPerPage: 15,
        totalItems: 1,
        currentPage: 1
    });

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const handleReset = () => {
        fileInputRef.current.value = '';
    }

    const handleSelectedFile = (event) => {
        const excelFile = event.target.files[0];

        handleReset();

        if (!excelFile) {
            return;
        }

        setExcelFile(excelFile);
        setSelectedFile(excelFile);
        loadSitesPayExcelFile(excelFile)
            .then(res => {
                var count = 0;
                var trxAmount = 0;
                var hasErrors = false;
                var fullErrorList = [];
                res.forEach(trx => {
                    var re = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g);

                    if (!trx.RFC_Beneficiario) {
                        trx.RFC_Beneficiario = 'NC';
                    }

                    if (!re.test(trx.Email)) {
                        trx.Email = '';
                    }

                    trx.id = trx.__rowNum__ + 1;

                    /*const resultValidation = validateTransactionData(trx);
    
                    if (resultValidation.length < 1) {
                        count++;
                        trxAmount = trxAmount + parseFloat(trx.Monto);
                    } else {
                        fullErrorList = fullErrorList.concat(resultValidation);
                        hasErrors = true;
                    }*/
                })

                /* if (count < 1) {
                     setAlert({tipo: 'danger', msn: 'Archivo dañado o no se cuenta con operaciones para procesar', show: true});
                 } else if (!hasErrors) {
                     setIsBtnProcessOpsDisabled(false);
                     setTotalOps(count);
                     setTotalAmount(dollarUS.format(trxAmount));
                 }
     
                 if (hasErrors) {
                     let displayText = "";
                     fullErrorList.forEach( errorText => {
                         displayText = displayText + errorText + '\n';
                     });
                     setAlert({tipo: 'danger', msn: 'Archivo contiene operaciones con errores favor de revisar:\n' + displayText, show: true});
                     setIsBtnResetDisabled(false);
                 } else {
                     const resTrasactions = res.map(item => ({
                         Cuenta_Destino: item.Cuenta_Destino,
                         Destinatario: item.Destinatario,
                         RFC_Beneficiario: item.RFC_Beneficiario,
                         Concepto: item.Concepto,
                         Monto: item.Monto,
                         Referencia_Numerica: item.Referencia_Numerica
                     }));
     
                     setTransactions(resTrasactions);
                     setIsBtnResetDisabled(false);
                     setShowOperations(true);
                 }*/
            })
    };

    const loadSitesPayExcelFile = (file) => {
        return new Promise(resolve => {
            const reader = new FileReader();
            let result = {};
            reader.onload = (e) => {
                const data = e.target.result
                const workbook = xlsx.read(data, { type: "binary" })

                result['SitesPay1'] = xlsx.utils.sheet_to_json(workbook.Sheets['SitesPay1'], { range: 10 })
                resolve(result.SitesPay1);
            }
            reader.readAsBinaryString(file)
        })
    }

    const [searchData, setSearchData] = useState({
        byCompanyId: '',
        byNameCompany: ''
    });

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-5 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Tarjetahabientes</p>
                                        </div>
                                        <div className="col-sm-3 contenBtnAdmin">
                                            <label className="file-upload-button-admin" htmlFor="fileInput">
                                                <span className='choose-file'>Choose file</span>
                                            </label>
                                            <input
                                                type="file"
                                                id="fileInput"
                                                accept=".xlsx"
                                                style={{ display: 'none' }}
                                                onChange={handleSelectedFile}
                                                ref={fileInputRef}
                                            />
                                        </div>
                                        <div className="col-sm-3 contenBtnAdmin">
                                            <button className="btn btn-primary" type="button" onClick={handleShowBulkLoad}>Nuevos Usuarios</button>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={data} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} from='card2'
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
            <BulkLoad show={showBulkLoad} handleClose={handleCloseBulkLoad} />
        </div>
    )
}

export default ManageCardholders
