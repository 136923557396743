import React from 'react';
import './ProgressBar.css';

function ProgressBar({ progress, texto }) {
    return (
        <div className="progress containerProgress" role="progressbar" aria-label="Animated striped example" style={{ height: "20px" }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar progress-bar-striped progress-bar-animated text-center" style={{ width: `${progress}%` }}>
                {progress}% {texto}
            </div>
        </div>
    )
}

export default ProgressBar
