import React from 'react'

function TableModal({ tipo, body, onSelect }) {
    return (
        <div>
            {(() => {
                switch (tipo) {
                case 1:
                    return <div>
                        <div className="row row-form-text tableHeader">
                            <div className="col-lg-6 content-label">
                                <label className="col-form-label">Cuenta a nombre de:</label>
                            </div>
                            <div className="col-lg-6 content-input">
                                <label className="col-form-label table-modal-data">CLABE:</label>
                            </div>
                        </div>
                        {body.map((item, index) => (
                            <div className={`row row-form-text row-table-data ${index % 2 === 0 ? 'even' : 'odd'}`} key={index} onClick={() => onSelect(item)} data-bs-dismiss="modal" aria-label="Close">
                                <div className="col-lg-6  content-label2">
                                    <label className="col-form-label">{item.nombreTercero}</label>
                                </div>
                                <div className="col-lg-6  ">
                                    <label className="col-form-label table-modal-data">{item.cuentaTercero}</label>
                                </div>
                            </div>
                        ))}
                    </div>;
                case 2:
                    return <div>
                        <div className="row-form-text tableHeader">
                            <div className="content-label5">
                                <label className="col-form-label2">Número de Tarjeta:</label>
                            </div>
                        </div>
                        {body.map((item, index) => (
                            <div className={`row row-form-text row-table-data`} key={index} onClick={() => onSelect(item)} data-bs-dismiss="modal" aria-label="Close">
                                <div className="col-lg-12 content-input5">
                                    <label className="col-form-label table-modal-data">{item.cuentaTercero}</label>
                                </div>
                            </div>
                        ))}
                    </div>;
                default:
                    return null;
                }
            })()}
        </div>
    )
}

export default TableModal
