import React from "react";
import Login from "../../components/login/login";
import Header from "../../components/Headers/Header";
import Footer from "../../components/Footer/Footer";
import RecoverPassModal from "../../components/Modals/RecoverPassModal";
import ComponenteSolutions from "../../components/login/ComponenteSolutions";
import ComponentProducts from "../../components/login/ComponentProducts";
import ComponentSuccessStories from "../../components/login/ComponentSuccessStories";

/**
 * 
 * esto va debajo de soluciones
 * 
        <div id="casos" className="section3">
          <ComponentSuccessStories />
        </div>

 * 
 */

function Home() {
  const isLogin = false;
  return (
    <div className="container-fluid">
      <Header isLogin={isLogin} />
      <div className="containers">
        <div id="login" className="section1">
          <Login />
        </div>
        <div id="productos" className="section3">
          <ComponentProducts />
        </div>
        <div id="soluciones" className="section2">
          <ComponenteSolutions />
        </div>
      </div>

      <Footer />
      <RecoverPassModal />
    </div>
  );
}

export default Home;
