import { AES, enc } from "crypto-js";

class Encrypter {

  static aesEncrypt(key, clearText) {
    return AES.encrypt(clearText, key).toString();
  }

  static aesDecrypt(key, encryptedText) {
    var bytes  = AES.decrypt(encryptedText, key);
    return bytes.toString(enc.Utf8);
  }

}

export default Encrypter;