import React, { useState, useEffect } from 'react';
import Pagination from '../Paginations/Pagination';
import { Link } from 'react-router-dom';
import report from '../../assets/icons/file-earmark-text.svg';

import './Table.css';

import MenuIcon from '../Menus/MenuIcon/MenuIcon';

function TableAccount({ headers, data, currentPage, setCurrentPage, totalPages, menuIcon, menuIconR }) {
    var isVisible = true;

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const userData = JSON.parse(localStorage.getItem('userData'));

    return (
        <div>
            {windowWidth < 768 ?
                (
                    <div className="bg-table">
                        {
                            data.map((item, index) => (
                                <div key={index} className="card-table">
                                    <div className="card-header-table">
                                        <h5 className='header-table'>{item.description}</h5>
                                    </div>
                                    <div className="card-body-table">
                                        <p className='text-table'>Clabe: {item.accountNumber} </p>
                                        <p className='saldo tdExtraCuentas text-table'>
                                            <Link to={'/movements/' + item.accountNumber} >
                                                {dollarUS.format(item.balance)}
                                            </Link>
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                )
                :
                (
                    <div className="bg-table">
                        <table>
                            <thead className="headtable">
                                <tr className="trHead">
                                    {headers.map((header, index) => (
                                        <th className={index === 3 || index === 4 ? ("trh") : ("thtable")}
                                            key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            {isVisible ? (
                                <tbody>
                                    {data.map((item, index) => (
                                        <tr key={index}>
                                            <td className='centrado tdExtraCuentas'> {item.accountNumber}</td>
                                            <td className='textStyle tdExtraCuentas'> {item.description}</td>
                                            <td className='saldo tdExtraCuentas'>
                                                <Link to={'/movements/' + item.accountNumber} >
                                                    {dollarUS.format(item.balance)}
                                                </Link>
                                            </td>
                                            <td className='centrado tdExtraCuentas'>
                                                <MenuIcon id={item.accountNumber} menuIcon={menuIcon} />
                                            </td>
                                            { ((userData) && ((userData.roleId === 10) || (userData.roleId === 11)) ) && 
                                            <td className='centrado tdExtraCuentas'>
                                                <Link to={"/estadosCuenta/" + item.accountNumber } className='iconTable'>
                                                    <img src={report} alt="" className='iconButton' />
                                                </Link>
                                            </td>
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan={4}>Sin datos</td>
                                    </tr>
                                </tbody>
                            )}
                        </table>
                    </div>
                )
            }

            <div className="pagination">
                <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
            </div>
        </div>
    )
}

export default TableAccount
