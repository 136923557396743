import React from 'react'
import './MenuBody.css'

function MenuBody({ items, handleShow }) {
    return (
        <nav aria-label="Breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, index) => (
                    <li className="breadcrumb-item" key={index}>
                        {index < items.length - 1 ? (
                            <a className="item-a" href={item.link}>{item.text}</a>
                        ) : (
                            <span className="item-active">{item.text}</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}

export default MenuBody