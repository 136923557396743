import React, { useEffect, useState } from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
//import search from '../../assets/icons/search-svgrepo-com.svg'
import Table from '../../components/Table/Table'

// import NewCompany from '../../components/Modals/NewCompany'
// import AddressCompany from '../../components/Modals/AddressCompany'
// import LegalRepresentative from '../../components/Modals/LegalRepresentative'
//import Edit from '../../assets/icons/edit_icon.svg'
//import Delete from '../../assets/icons/trash.svg'
// import SearchModal from '../../components/Modals/SearchModal'
// import CompanyProfile from '../../components/Modals/CompanyProfile'
import Encrypter from '../../services/Encrypter';
import Message from '../../components/Message/Message';

import '../../css/body.css'
//import NewUdns from '../../components/Modals/NewUdns'
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

function AdministerUdnAccounts() {
    const { id } = useParams();
    const isLogin = true;
    //const isAdmin = true;
    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    useEffect(() => {
        getCompanies(pagination.currentPage);
    }, [pagination.currentPage]);

    const getCompanies = (currentPage) => {
        if (currentPage === undefined)
            currentPage = pagination.currentPage;

        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + id + "/?token=" + userData.token + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
            .then(({ response, error }) => {
                if (error) {
                    console.log(error);
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                    }
                }

                if (response) {
                    const empresasToSet = response.empresas.map(item => ({
                        rfc: item.rfc,
                        nombre: item.nombre,
                        razonSocial: item.razonSocial,
                        speiInPct: item.speiInPct,
                        speiInCost: item.speiInCosto,
                        speiOutPct: item.speiOutPct,
                        speiOutCost: item.speiOutCosto,
                        id: item.idEmpresa,
                        idClient: item.idCliente
                    }));
                    setCompanies(empresasToSet);
                    setPagination((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                }
            });
    };

    const tableHeaders = ['RFC Empresa', 'Nombre', 'Razón Social', 'Spei In %', 'Spei in $', 'Spei Out %', 'Spei out $', 'Acciones'];
    const tableStyles = ['textStyle', 'textStyle', 'textStyle', 'amountStyle', 'amountStyle', 'amountStyle', 'amountStyle', 'centeredStyle'];

    const isMenu = false;

    const menuIcon = [
        // { icon: Edit, url: '', modal: () => setShowFirstModal(true), clave: '' },
        //{ icon: Edit, url: '', modal: 'modal', clave: '#newCompany' },
        //  { icon: Delete, url: '', modal: 'modal', clave: '#editarUdn' },
    ]

    //const link = '/cuentasEmpresas/';
    const selectedOption = "1";


    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-11 containerTitleAdmin">
                                            <p className='tilteAdmin'>Empresas dentro de la UDN: {id} </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={companies} styles={tableStyles} itemsPerPage={pagination.itemsPerPage} menuIcon={menuIcon} isMenu={isMenu} /*link={link} onSelect={handleRowSelect}*/ tipo='admin' from='admin'
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

/*
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>

            <NewCompany data={selected} handleChanges={saveCompany} showFirstModal={showFirstModal} handleCloseAllModals={handleCloseFirstModal} handleShowSecondModals={handleShowSecondModals} handleShowThirdModals={handleShowThirdModals} handleShowFourthModals={handleShowFourthModals} />
            <AddressCompany data={selected} handleChanges={saveAddress} showSecondModal={showSecondModal} handleCloseAllModals={handleCloseSecondModals}/>
            <LegalRepresentative data={selected} handleChanges={saveLegalRep} showThirdModal={showThirdModal} handleCloseThirdModals={handleCloseThirdModals}/>
            <CompanyProfile data={selected} handleChanges={saveProfile} showFourthModal={showFourthModal} handleCloseFourthModals={handleCloseFourthModals}/>
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />

*/
export default AdministerUdnAccounts
