import React,  { useState, useEffect }  from 'react'

import Header from '../../components/Headers/Header'
import MenuLateral from '../../components/Menus/MenuLateral/MenuLateral'
import Footer from '../../components/Footer/Footer'
import search from '../../assets/icons/search-svgrepo-com.svg'
import Message from '../../components/Message/Message';
import Table from '../../components/Table/Table'
import SearchModal from '../../components/Modals/SearchModal';
import StatusChange from '../../components/Modals/StatusChange';

import Encrypter from '../../services/Encrypter';
import { fetchData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';

function ManageCards() {

    const navigate = useNavigate();
    const isLogin = true;
    const selectedOption = "6";
    const tipo = "Tarjetas";


    //Modal Buscar 
    const [show, setShow] = useState(false);
    const handleClose = () => {
        console.log('reload?');
        //reload table to ensure last data shown
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const tableHeaders = ['Código de tarjeta', 'Últimos 4', 'Asignada a', 'Límite de Crédito', 'Balance Garantía', 'Tipo Garantía', 'Cuenta Garantía', 'Estatus ','Acciones'];
    const tableStyles = ['textStyle', 'accountStyle', 'textStyle','amountStyle','amountStyle','centeredStyle','accountStyle','textStyle','centeredStyle'];
    const [cardData, setCardData] = useState([]);

    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });

    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    const [selected, setSelectedRow] = useState(null);
    const handleRowSelect = (row) => {
        setSelectedRow(row);
    }

    const [searchData, setSearchData] = useState({
        byLastFour: '',
        byCardHolder: ''
    });

    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    const getCards = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        var searchQuery = '';
        if(searchData.byLastFour)
            searchQuery = '&bylastfour=' + searchData.byLastFour;
        if(searchData.byCardHolder)
            searchQuery = searchQuery + '&byCardHolder=' + searchData.byCardHolder;

        fetchData(process.env.REACT_APP_ADMIN_CARDS + "?token=" + userData.token + searchQuery + "&items=" + pagination.itemsPerPage + "&page=" + pagination.currentPage, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                // const cardsData = response.tarjetas.map(item => ({
                //     field: item.field,
                // }));
                console.log(response);
                setPagination((prevData) => ({
                    ...prevData,
                    totalItems: response.totalRows
                }));
                setCardData( response.tarjetas );
            }
        });
    }

    useEffect( () => {
        //console.log('load cards from DB');
        getCards();
    }, [pagination.currentPage]);

    function executeSearch() {

        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        //doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
        getCards();
    }


    const lookforNewCards = () => {
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        fetchData(process.env.REACT_APP_ADMIN_GET_NEW_CARDS + "?token=" + userData.token, headers)
        .then(({ response, error}) => {
            if (error) {
                console.log(error);
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else {
                    console.log(error);
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
            }

            if (response) {
                //console.log(response);
                setAlert({tipo: 'success', msn: 'Se procesaron: ' + response.totalNewCards + ' tarjetas', show: true});
            }
        });
    }

    //Modal
    const [showStatus, setShowStatus] = useState(false);
    const handleCloseStatus = () => setShowStatus(false);
    const handleShowStatus = () => setShowStatus(true);

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className="container-fluid2 position-absolute space-admin">
                <div className='row'>
                    <div className='col-sm-3'>
                        <div className="right-shadow2 padding-admin">
                            <MenuLateral selected={selectedOption} />
                        </div>
                    </div>
                    <div className='col-sm-9'>
                        <div className="left-shadow2">
                            <div className="card-body">
                                <div className='card-sub-title-bg2'>
                                    <div className='row'>
                                        <div className="col-sm-7 containerTitleAdmin">
                                            <p className='tilteAdmin'>Administración de Tarjetas</p>
                                        </div>
                                        <div className="col-sm-4 contenBtnAdmin">
                                            <button className="btn btn-primary" type="button" onClick={ lookforNewCards }>Cargar nuevas Tarjetas</button>
                                        </div>
                                        <div className="col-sm-1 content-row">
                                            <button className='btnSearchModal' type="button" onClick={handleShow}>
                                                <img src={search} alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-container-body">
                                    <Message alert={alert} closeAlert={closeAlert} />
                                    <Table headers={tableHeaders} data={cardData} styles={tableStyles} onSelect={handleRowSelect} itemsPerPage={pagination.itemsPerPage} menuIcon={handleShowStatus} from={'card'}
                                        currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems/pagination.itemsPerPage)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
            <StatusChange card={selected} show={showStatus} handleClose={handleCloseStatus}/>
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
        </div>
    )
}

export default ManageCards
