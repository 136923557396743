import { Navigate, Outlet } from "react-router-dom";

export const ProtectedRoute =  ({children}) => {
    const userData = JSON.parse(localStorage.getItem('userData'));

    if (!userData || !userData.token) {
        localStorage.removeItem('userData');
        return <Navigate to='/' />;
    }
    return <Outlet />
}