import React, { useState } from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Message from '../../components/Message/Message';

import { useNavigate } from 'react-router-dom'

function ActivateCard() {
    const isLogin = true;

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const navigate = useNavigate();

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const handleNext = () => {
        navigate('/MyCards');
    }

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <div className="row">
                                    <div className="col-sm-9">

                                    </div>
                                    <div className="col-sm-3 contenBtnAdmin">

                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-body-card2">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="card card-caratula containerCard">
                                    <div>
                                        <div className="mx-auto p-2 content-cardReady text-center">
                                            <p className='text-6'>¡Gracias por activar su tarjeta!</p>
                                            <p className='text-6'>Su tarjeta está lista para ser usada y la podrá ver listada en "Mis Tarjetas"</p>
                                            <div className='row space-card'>
                                                <div className='col-sm-3'>
                                                </div>
                                                <div className='col-sm-6'>
                                                    <button className="btn btn-success2" type="button" onClick={handleNext}>Terminar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ActivateCard
