import React, { useState } from "react";
import "../../css/body.css";
import "../../css/forms.css";
import { postData } from "../../services/Request";
import Encrypter from "../../services/Encrypter";
import { useNavigate } from "react-router-dom";
import Message from "../../components/Message/Message";
import RecoverPassModal from "../Modals/RecoverPassModal";

import imgFondo from "../../assets/img/ciudad.png";
import img from "../../assets/img/principal.png";

function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [alert, setAlert] = useState({ tipo: "", msn: "", show: false });
  const navigate = useNavigate();

  const handleLogin = () => {
    setAlert({ tipo: "", msn: "", show: false });
    //const cryptedPwd = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, password);
    const data = { username, cryptedPwd: password };
    const dataEnc = Encrypter.aesEncrypt(
      process.env.REACT_APP_API_SECRET,
      JSON.stringify(data)
    );
    const headers = {
      headers: {
        authorization: "APIAuth " + process.env.REACT_APP_API_KEY + ":",
      },
    };

    postData(
      process.env.REACT_APP_AUTH_SIGNIN,
      { data: dataEnc },
      headers
    ).then(({ response, error }) => {
      if (response) {
        localStorage.setItem("userData", response);
        const uData = JSON.parse(response);
        if (uData.roleId === 100) {
          navigate("/MyCards");
        } else {
          navigate("/Accounts");
        }
      } else if (error) {
        if (error.response.status === 400) {
          setAlert({
            tipo: "danger",
            msn: "Credenciales incorrectas. Por favor, inténtalo de nuevo.",
            show: true,
          });
        } else if (error.response.status === 500) {
          setAlert({ tipo: "danger", msn: error.message, show: true });
        } else {
          console.log("E login: " + error);
          setAlert({
            tipo: "danger",
            msn: "Ooops algo nos salió mal!",
            show: true,
          });
        }
      }
    });
  };

  const closeAlert = (alert) => {
    setAlert(alert);
  };

  const handleCard = () => {
    navigate("/activarTarjeta");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="overlay">
      <div className="row space">
        <div className="col-md-4 col-login">
          <div className="login-shadow">
            <div className="card-body cardLogin">
              <div className="bg-seson-continer">
                <p className="title-1">
                  <strong>INICIAR</strong> SESIÓN
                </p>
              </div>
              <div className="content-login">
                <Message alert={alert} closeAlert={closeAlert} />
                <form className="containerForm">
                  <div className="div-login">
                    <label className="form-label-login">Usuario</label>
                    <input
                      type="email"
                      className="form-control form-control-login"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="form-label-login">Contraseña</label>
                    <input
                      type="password"
                      className="form-control form-control-login"
                      id="exampleInputPassword1"
                      placeholder="Contraseña"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-login"
                      type="button"
                      onClick={handleLogin}
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <div className="container-1">
                    <div className="btnForget">
                      <p className="txtForget" onClick={handleShow}>
                        ¿Olvidaste tu contraseña?
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <RecoverPassModal show={show} handleClose={handleClose} />
          </div>
        </div>
        <div className="col-md-8 col-portada">
          <h1 className="text-Login">SitesPay</h1>
          <div className="Title-login">
            <h2 className="sub-title-login">
              Simplifica tus{" "}
              <span className="resaltar">operaciones de tesorería</span>{" "}
            </h2>
            <div className="row">
              <div className="col-6 col-text">
                <p className="text-login2">
                  {" "}
                  <span className="resaltar-login">GESTIONA</span> DE MANERA
                  <span className="resaltar-login"> RÁPIDA</span> LOS{" "}
                  <span className="resaltar-login">PAGOS Y COBROS</span> DE TU
                  EMPRESA
                </p>
              </div>
              <div className="col-6 col-img2">
                <img src={img} alt="" className="img-login2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
