import React, { useState } from 'react'
import { postData } from '../../services/Request'
import Encrypter from '../../services/Encrypter'
import FinishOperation from '../../components/Modals/FinishOperation'
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message'

import Modal from 'react-bootstrap/Modal';
import { getInstitutionName } from '../../services/institution';

import '../../css/forms.css'

function ConfirmOperation({ transferData, transferType, show, handleClose, resetTranferData }) {
    const [password, setPassword] = useState('');
    const [cveRastreo, setreferenceNumber] = useState('');
    const [fromAccountName, setFromAccountName] = useState('');
    const [toAccountName, setToAccountName] = useState('');
    const navigate = useNavigate();

    const [confirmDisabled, setConfirmDisabled] = useState(false);
    const [show2, setShow] = useState(false);
    const handleClose2 = () => { setShow(false); setConfirmDisabled(false); resetTranferData(); };
    const handleShow2 = () => setShow(true);
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const handleTransfer = () => {
        setConfirmDisabled(true);
        setAlert({ tipo: '', msn: '', show: false });
        const userData = JSON.parse(localStorage.getItem('userData'));
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } };
        const encryptedPass = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, password);

        const data = {
            trxType: transferType,
            accountFrom: transferData.origin,
            accountTo: transferData.destination,
            transferAmount: transferData.amount,
            concept: transferData.concept,
            refNumber: transferData.reference,
            opsPass: encryptedPass,
            email: transferData.email,
            beneficiary: transferData.beneficiary,
            beneficiaryRfc: 'ND',
            toBankCode: transferData.toBankCode
        };

        const payObjStr = JSON.stringify(data);
        const payment = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, payObjStr);
        const body = { data: payment };

        postData(process.env.REACT_APP_ACCOUNT_TRANSFER + "?token=" + userData.token, body, headers)
            .then(({ response, error }) => {
                setConfirmDisabled(false);
                if (response) {
                    const responseData = JSON.parse(response);
                    setreferenceNumber(responseData.referenceNumber);
                    setFromAccountName(responseData.fromAccountName);
                    setToAccountName(responseData.toAccountName);
                    handleClose();
                    handleShow2();
                    clearModal();
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        setAlert({ tipo: 'danger', msn: 'Error en el systema', show: true });
                    }
                    return;
                }
            });
    };

    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const clearModal = () => {
        setPassword('');
        setAlert({ tipo: '', msn: '', show: false });
    };

    const dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); clearModal(); }} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Confirmar Operación</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="contModal">
                        <Message alert={alert} closeAlert={closeAlert} />
                        <h6 className="sutitle-modal">
                            {transferType === 1 ? 'Traspaso entre cuentas propias' : 'Traspaso a cuentas de terceros'}
                        </h6>
                        <div className="card card-caratula">
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">De su cuenta número:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="number" className="form-control txtControl" value={transferData.origin} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">De la institución bancaria:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={getInstitutionName(transferData.origin?.substring(0, 3)) || ''} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">A su número de cuenta:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="number" className="form-control txtControl" value={transferData.destination} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Nombre del beneficiario:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={transferData.beneficiary} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">De la institución bancaria:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={transferData.destinationBank || ''} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Monto a transferir:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={dollarUS.format(transferData.amount)} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Concepto:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={transferData.concept} readOnly />
                                </div>
                            </div>

                            <div className="row row-form-text">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Número de referencia:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="text" className="form-control txtControl" value={transferData.reference} readOnly />
                                </div>
                            </div>
                            <div className="row row-form-text2">
                                <div className="col-lg-6 content-label">
                                    <label className="col-form-label">Ingrese su contraseña de operaciones:</label>
                                </div>
                                <div className="col-lg-6 content-input">
                                    <input type="password" id="operationPass" className="form-control txtControl" onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" onClick={handleTransfer} className="btn btn-primary2 btnModal" disabled={confirmDisabled}>Confirmar</button>
                    </div>
                </Modal.Footer>
            </Modal>
            <FinishOperation transferData={transferData} transferType={transferType} referenceNumber={cveRastreo} fromAccountName={fromAccountName} toAccountName={toAccountName} show={show2} handleClose={handleClose2} />
        </div>
    )
}

export default ConfirmOperation
