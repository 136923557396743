import React, { useEffect, useState } from 'react';
import { fetchData } from '../../services/Request';
import Encrypter from '../../services/Encrypter';

import Modal from 'react-bootstrap/Modal';

import * as errorIn from '../../utils/validations';

const handleAccountsSearchChange = (e, setValues) => {
    const { id, value } = e.target;

    //busqueda para cuentas
    if (id === 'byCardBin' && value !== '' && errorIn.numeric(value)) {
        e.target.value = '';
        return;
    }
    if (id === 'byCardName')
        e.target.value = errorIn.sanitizeText(value);

    //busqueda para movimientos
    if (id === 'byToAccountBin' && value !== '' && errorIn.integer(value)) {
        e.target.value = '';
        return;
    }
    if (id === 'byTrackId')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'byConcept')
        e.target.value = errorIn.sanitizeText(value);

    //busqueda para Udns
    if (id === 'byIdUdn' && value !== '' && errorIn.integer(value)) {
        e.target.value = '';
        return;
    }

    if (id === 'byNameUdn')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'byCompanyUdn')
        e.target.value = errorIn.sanitizeText(value);

    //busqueda para Empresas y Personas Fisica
    if (id === 'byNameUdn')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'byCompanyUdn')
        e.target.value = errorIn.sanitizeText(value);

    //busqueda por Cuentas  
    if (id === 'byNumberAccount' && value !== '' && errorIn.integer(value)) {
        e.target.value = '';
        return;
    }
    if (id === 'byNameAccount')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'byNameCompany')
        e.target.value = errorIn.sanitizeText(value);

    //busqueda por Usuarios 
    if (id === 'byUserMail')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'roleId')
        e.target.value = errorIn.sanitizeText(value);
    if (id === 'companyId') {
        // var selectedIndex = e.target.options.selectedIndex;
        // var customAtrribute= e.target.options[selectedIndex].getAttribute('key');
        e.target.value = errorIn.sanitizeText(value);
    }

    // busquedas para tarjetas
    if (id === 'byLastFour' && value !== '' && errorIn.integer(value)) {
        e.target.value = '';
        return;
    }
    if (id === 'byCardHolder')
        e.target.value = errorIn.sanitizeText(value);

    //Busqueda por fechas en mis tarjetas
    if (id === 'byStartDate' && value !== '') {
        e.target.value = errorIn.isValidDate(value);
    }

    if (id === 'byEndDate' && value !== '') {
        e.target.value = errorIn.isValidDate(value);
    }
    setValues((prevData) => ({
        ...prevData,
        [id]: value
    }));

};

// const handleChangeRole = (e) => {
//     const { id, value } = e.target;
// }

function OptionsCompanies({ options }) {
    return (
        options.map(option => <option key={option.id} value={option.idEmpresa}> {option.value} </option>)
    );
}
function Options({ options }) {
    return (
        options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
    );
}

const cleanUp = (setValues) => {
    setValues((prevData) => {
        const newData = { ...prevData };
        Object.keys(newData).forEach((key) => {
            newData[key] = '';
        });
        return newData;
    });
}

function searchFor(doSearch, doClose) {
    doSearch();
    doClose();
}

function SearchModal({ tipo, sDo, show, handleClose, searchObj, setSearchObj }) {

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const closeAlert = () => {
        setAlert({ tipo: '', msn: '', show: false });
    };

    const [rolesToSelect, setRolesToSelect] = useState([{
        id: 0,
        value: 'Seleccione el role del usuario'
    }]);

    const [companyToSelect, setCompanyToSelect] = useState([{
        id: 0,
        value: 'Seleccione la empresa'
    }]);


    useEffect(() => {
        if (tipo === 'usuarios') {
            getRoles();
            getCompany();
        }
        function getRoles() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_AUTH_ROLES + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        //console.log(response.roles);
                        const roles = response.roles.map(item => ({
                            id: item.id,
                            //idRole: item.id,
                            value: item.nombre,
                        }));
                        setRolesToSelect(roles);
                    }
                });
        }

        function getCompany() {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

            fetchData(process.env.REACT_APP_ACCOUNT_COMPANIES + "?token=" + userData.token, headers)
                .then(({ response, error }) => {
                    if (error) {
                        console.log(error);
                        const responseErrors = error.response.data.errors.errors;
                        if (error.response.status === 401) {
                            localStorage.removeItem('userData');
                            return;
                        } else if (responseErrors !== undefined) {
                            setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                        } else {
                            setAlert({ tipo: 'danger', msn: 'Error en el sistema', show: true });
                        }
                    }

                    if (response) {
                        const company = response.empresas.map(item => ({
                            id: item.id,
                            idEmpresa: item.idEmpresa,
                            value: item.nombre,
                        }));
                        setCompanyToSelect(company);
                    }
                });
        }
    }, []);

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Búsqueda de {tipo}</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">{(() => {
                        switch (tipo) {
                            case "mis cuentas":
                                return (
                                    <div className="row-form-text4">
                                        <div className="content-label4">
                                            <label className="col-form-label">Número de cuenta (prefijos)</label>
                                            <input id='byCardBin' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCardBin} type="text" className="form-control txtControl" />
                                        </div>
                                        <div className="content-label4">
                                            <label className="col-form-label">Nombre de cuenta</label>
                                            <input id='byCardName' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCardName} type="text" className="form-control txtControl" />
                                        </div>
                                    </div>
                                );
                            case "movimientos":
                                return (
                                    <div>
                                        <div className="row row-form-text">
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Desde (Seleccione la fecha inicial)</label>
                                                <input id='byDateIn' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byDateIn} type="date" className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Hasta (Seleccione la fecha final)</label>
                                                <input id='byDateOut' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byDateOut} type="date" className="form-control txtControl" />
                                            </div>
                                        </div>
                                        <div className="row row-form-text mb-2">
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Por concepto</label>
                                                <input id='byConcept' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byConcept} type="text" className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Clave de rastreo</label>
                                                <input id='byTrackId' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byTrackId} type="text" className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Número de cuenta destino</label>
                                                <input id='byToAccountBin' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byToAccountBin} type="text" className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "UDN":
                                return (
                                    <div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Nombre de la UDN</label>
                                                <input type="text" id="byNameUdn" onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byNameUdn} className="form-control txtControl" />
                                            </div>
                                        </div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Nombre de la empresa Controladora</label>
                                                <input type="text" id="byCompanyUdn" onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCompanyUdn} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "empresa":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">RFC</label>
                                                <input type="text" id='byCompanyId' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCompanyId} className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Nombre de la empresa</label>
                                                <input type="text" id='byNameCompany' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byNameCompany} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "persona fisica":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">RFC</label>
                                                <input type="text" id='byCompanyId' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCompanyId} className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Nombre de la empresa</label>
                                                <input type="text" id='byNameCompany' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byNameCompany} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "usuarios":
                                return (
                                    <div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Tipo (Role) de usuario</label>
                                                <select className="form-select" id="roleId" onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.roleId} >
                                                    <Options options={rolesToSelect} />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Empresa</label>
                                                <select className="form-select" id="companyId" onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.companyId} >
                                                    <OptionsCompanies options={companyToSelect} />
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Correo electrónico</label>
                                                <input type="text" id='byUserMail' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byUserMail} className="form-control txtControl" />
                                            </div>
                                        </div>

                                    </div>
                                );
                            case "admin cuentas":
                                return (
                                    <div>
                                        <div className="row-form-text">
                                            <div className="content-label3">
                                                <label className="col-form-label">Número Cuenta</label>
                                                <input type="text" id='byNumberAccount' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byNumberAccount} className="form-control txtControl" />
                                            </div>
                                        </div>
                                        <div className="row-form-text2">
                                            <div className="content-label3">
                                                <label className="col-form-label">Nombre de la cuenta</label>
                                                <input type="text" id='byNameAccount' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byNameAccount} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "Tarjetas":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Últimos 4 dígitos</label>
                                                <input type="text" id='byLastFour' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byLastFour} className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Nombre Tarjetahabiente</label>
                                                <input type="text" id='byCardHolder' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCardHolder} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "tarjetahabientes":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Tarjetahabiente</label>
                                                <input type="text" id='byCompanyId' className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label3">
                                                <label className="col-form-label">Correo Electrónico</label>
                                                <input type="text" id='byNameCompany' className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "TransaccionesTDC":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Últimos 4 dígitos</label>
                                                <input type="text" id='byLastFour' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byLastFour} className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Nombre Tarjetahabiente</label>
                                                <input type="text" id='byCardHolder' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byCardHolder} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );
                            case "mis tarjetas":
                                return (
                                    <div>
                                        <div className="row row-form-text2">
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Fecha de inicio</label>
                                                <input type="date" id='byStartDate' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byStartDate} className="form-control txtControl" />
                                            </div>
                                            <div className="col-lg-6 content-label1">
                                                <label className="col-form-label">Fecha fin</label>
                                                <input type="date" id='byEndDate' onChange={e => handleAccountsSearchChange(e, setSearchObj)} value={searchObj.byEndDate} className="form-control txtControl" />
                                            </div>
                                        </div>
                                    </div>
                                );

                            default:
                                return null;
                        }
                    })()}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div>
                        <button type="button" className="btn btn-secondary btnModal" onClick={(e) => cleanUp(setSearchObj)}>Limpiar filtros</button>
                        <button type="button" className="btn btn-primary2 btnModal" onClick={(e) => searchFor(sDo, handleClose)}>Buscar</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SearchModal
