import React from 'react';
import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';

function PrivacyPolicies() {
    const isLogin = false;
    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='body'>
                <div className='container-fluid2 position-absolute space-acconts'>
                    <div className="containerHome2">
                        <div className="container-general">
                            <div className="card-body">
                                <div className='card-sub-title-bg'>
                                    <h4 className="subTitle">Política de Privacidad de SitesPay</h4>
                                </div>
                                <div className="bg-container-body-card2">
                                    <div className="card cardPolitice">
                                        <label className="txtPolicies">Fecha de vigencia: Enero 8, 2024</label>
                                        <p className='txtPolicies'>SitesPay se compromete a proteger la privacidad de sus usuarios en todo momento</p>
                                        <p className='txtPolicies2'>Esta Política de Privacidad describe cómo SitesPay recopila, utiliza y protege la información personal de los usuarios.</p>
                                        <hr></hr>
                                        <div className='containerPolicies' >
                                            <ol className="list-group list-group-numbered">
                                                <li className="list-group-item txtPolicies2">Información recopilada
                                                    <ul className="custom-marker">
                                                        <li className='costom-li'>
                                                            <a className='item-costom'>
                                                                SitesPay no recopila información alguna a través de su sitio web ni sus aplicaciones móviles.
                                                            </a>
                                                        </li>
                                                        <li className='costom-li'>
                                                            <a className='item-costom'>
                                                                El cliente deberá proporcionar los datos y registro a través del proceso de creación de cuenta, y llenando el cuestionario “Conoce a tu cliente” (KYC por sus siglas en inglés), para poder crear su cuenta en el sistema de SitesPay y con ello que se le proveea un usuario y contraseña para acceder al sistema.
                                                            </a>
                                                        </li>
                                                        <li className='costom-li'>
                                                            <a className='item-costom'>
                                                                Información de pago: al realizar transacciones a través de SitesPay, los usuarios proporcionan información financiera, como números de tarjeta de crédito o débito, para procesar los pagos.
                                                            </a>
                                                        </li>
                                                        <li className='costom-li'>
                                                            <a className='item-costom'>
                                                                Información adicional: SitesPay puede recopilar información adicional de los usuarios para mejorar la experiencia del usuario y brindar un mejor servicio. Esta información puede incluir preferencias de usuario, patrones de uso y datos demográficos.
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className="list-group-item txtPolicies2">SitesPay utiliza la información personal recopilada para los siguientes propósitos:
                                                
                                                </li>
                                                <li className="list-group-item txtPolicies2">
                                                    Protección de la información: SitesPay implementa medidas de seguridad para proteger la información personal de los usuarios contra accesos no autorizados, pérdidas, uso indebido o alteración. Toda la información transmitida a través de SitesPay se cifra utilizando tecnologías de cifrado seguras.
                                                </li>
                                                <li className="list-group-item txtPolicies2">
                                                    Divulgación de la información: SitesPay no comparte, vende ni alquila la información personal de los usuarios con terceros para ningún propósito, incluida la publicidad o la mercadotecnia.
                                                </li>
                                                <li className="list-group-item txtPolicies2">
                                                    Cambios en la Política de Privacidad: SitesPay se reserva el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Se notificarán a los usuarios los cambios significativos mediante publicaciones en el sitio web o por correo electrónico.
                                                </li>
                                            </ol>
                                            <hr></hr>
                                            <p className='txtPolicies'>
                                                Al utilizar SitesPay, los usuarios aceptan los términos y condiciones de esta Política de 
                                                Privacidad. Si tienen alguna pregunta o inquietud sobre esta Política de Privacidad, 
                                                pueden ponerse en contacto con nosotros a través de <a href="#" className="link-primary2">info@sitespay.mx</a>
                                            </p>
                                            <p className='txtPolicies2'>Última actualización: Marzo 7, 2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicies
