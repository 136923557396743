import React from 'react';
import Modal from 'react-bootstrap/Modal';
import mail from '../../assets/icons/mail.svg';
import whats from '../../assets/icons/whatsapp.svg'

function Help({ show, handleClose }) {
    const phoneNumber = '3331731186'; // Reemplaza con el número de teléfono
    const message = '';

    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <div>
            <Modal show={show} onHide={() => { handleClose(); }} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Ayuda</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="card card-caratula">
                        <div className='space-modal'>
                            <p className='txtForget'>Para solicitar ayuda favor de enviar un correo a</p>
                            <p className='help'>
                                <img src={mail} alt="" className='space-icon' />
                                info@sitespay.mx
                            </p>
                            <p className='txtForget'>o enviar mensaje por</p>
                            <a href={whatsappURL} target="_blank" rel="noopener noreferrer" className='help'>
                                <p className='help'>
                                    <img src={whats} alt="" className='space-icon' />
                                    WhatsApp
                                </p>
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Help