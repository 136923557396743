import React from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import ComponenteSolutions from '../../components/login/ComponenteSolutions';

function Solutions() {
    return (
        <div>
            <Header isLogin={false} />
            <div className='body'>
                <ComponenteSolutions/>
            </div>
            <Footer />
        </div>
    )
}

export default Solutions
