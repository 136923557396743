import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

function BarChart() {
    const chartRef = useRef(null);

    useEffect(() => {
        // Datos de ejemplo
        const data = {
            labels: ['5219812481232', '821981248882', '329812488822', '329812488823'],
            datasets: [{
                axis: 'y',
                label: '',
                data: [95, 70, 44, 20],
                fill: false,
                backgroundColor: [
                  'rgba(57, 34, 112, 0.80)',
                  'rgba(93, 58, 147, 0.80)',
                  'rgba(122, 90, 168, 0.80)',
                  'rgba(175, 157, 204, 0.80)',
                ],
                borderColor: [
                  'rgb(57, 34, 112)',
                  'rgb(93, 58, 147)',
                  'rgb(122, 90, 168)',
                  'rgb(175, 157, 204)',
                ],
                borderWidth: 1
            }]
        };

        // Configuración de la gráfica
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y',
            label: false
        };

        // Crear la gráfica
        const ctx = chartRef.current.getContext('2d');
        const myChart = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
        });

        // Limpieza al desmontar el componente
        return () => {
            myChart.destroy();
        };
    }, []);

    return (
        <div className='chartLine'>
            <canvas ref={chartRef} />
        </div>
    )
}

export default BarChart
