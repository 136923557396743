import React, { useState, useEffect } from 'react';

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import Message from '../../components/Message/Message';

import Encrypter from '../../services/Encrypter';
import { postData } from '../../services/Request'
import { useNavigate } from 'react-router-dom'

function CardReady() {
    const isLogin = true;
    const activationId = localStorage.getItem('activationCode');

    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });
    const navigate = useNavigate();

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const handleNext = () => {
        navigate('/activarTarjeta');
    }

    const [maxTokenTimeout, setMaxTokenTimeout] = useState(900);
    const [seconds, setSeconds] = useState(0);
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    useEffect(() => {
        if (isTimerRunning) {
            const intervalId = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds + 1);
            }, 1000);

            return () => clearInterval(intervalId);
        }
    }, [isTimerRunning]);

    useEffect(() => {
        if (seconds >= maxTokenTimeout) { // 15 minutos = 900 segundos
            setIsTimerRunning(false);
            resetTimer();
        }
    }, [maxTokenTimeout, seconds]);

    const progressPercentage = (seconds / maxTokenTimeout) * 100;
    const resetTimer = () => {
        setSeconds(0);
        setIsTimerRunning(true);
    };

    const [iFrameUrl, setIFrameUrl] = useState('');

    useEffect( () => {
        var successLink = 'https://sitespay.mx/activarTarjeta';
        if( process.env.REACT_APP_BASE_URL !== 'https://api.sitespay.mx/api' )
            successLink = 'https://testv1.sitespay.mx/activarTarjeta';
        //requerimos un token para el usuario
        function getCardHolderToken() {
            const userData = JSON.parse(localStorage.getItem('userData'));
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            const strPayload = '{"activationId":"'+activationId+'"}';
            const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
            postData(process.env.REACT_APP_ADMIN_CARDS + "user/activation/?token=" + userData.token, { data: cryptPayload }, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error. Por favor refresque la página. Si el error continua, póngase en contacto con nosotros', show: true});
                    }
                }

                if( response ) {
                    const authToken = JSON.parse(response);
                    setMaxTokenTimeout(authToken.expires_in);
                    setIsTimerRunning(true);
                    //const url = 'https://secure-data-web-stage.pomelo.la/v1/activate-card/?auth=' + authToken.access_token + '&country=mex&locale=es&success_link=' + successLink;
                    const url = 'https://secure-data-web.pomelo.la/v1/activate-card/?auth=' + authToken.access_token + '&country=mex&locale=es&success_link=' + successLink;
                    setIFrameUrl(url)
                }
            });
        }
        getCardHolderToken();
    }, [activationId, navigate]);

//<button className="btn-primary" type="button" onClick={handleNext}>Next</button>

    return (
        <div>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg'>
                                <div className="row">
                                    <div className="col-sm-9">
                                        
                                    </div>
                                    <div className="col-sm-3 contenBtnAdmin">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="bg-container-body-card2">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <div className="card card-caratula containerCard3">
                                    <div className='card-body'>
                                        <div className="mx-auto p-2 content-cardReady2">


                                            <iframe title='Activar Tarjeta' src={iFrameUrl}></iframe>


                                        </div>
                                        <div className="progress" style={{ height: '30px' }}>
                                            <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${progressPercentage}%` }} aria-valuenow={progressPercentage} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                        <p>Tiempo transcurrido: {Math.floor(seconds / 60)}:{String(seconds % 60).padStart(2, '0')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CardReady
