import React, { useState, useEffect } from 'react'
import '../../css/body.css'

import Header from '../../components/Headers/Header';
import Footer from '../../components/Footer/Footer';
import MenuBody from '../../components/Body/Menubody/MenuBody';
import TableAccount from '../../components/Table/TableAccount';
import impresora from '../../assets/icons/printer-svgrepo-com.svg';
import SearchModal from '../../components/Modals/SearchModal';
import { fetchData } from '../../services/Request';
import Encrypter from '../../services/Encrypter';
import { useNavigate } from 'react-router-dom';
import Message from '../../components/Message/Message';
import search from '../../assets/icons/search-svgrepo-com.svg';
import report from '../../assets/icons/file-earmark-text.svg';

function Accounts() {
    const [accounts, setAccounts] = useState([]);
    const navigate = useNavigate();
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [alert, setAlert] = useState({ tipo: '', msn: '', show: false });

    const [searchData, setSearchData] = useState({
        byCardBin: '',
        byCardName: ''
    });
    const [pagination, setPagination] = useState({
        itemsPerPage: 50,
        totalItems: 1,
        currentPage: 1
    });
    const setCurrentPage = (newPage) => {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: newPage
        }));
    }

    async function message(message) {
        setAlert({ tipo: 'danger', msn: message, show: true });
    }

    async function doSearch(currentPage, otherParams, setFunc) {
        if (currentPage === undefined)
            currentPage = pagination.currentPage;

        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers: { 'authorization': 'APIAuth ' + process.env.REACT_APP_API_KEY + ':' + token } }

        fetchData(process.env.REACT_APP_ACCOUNTS + "?token=" + userData.token + "&items=" + pagination.itemsPerPage + "&page=" + currentPage + otherParams, headers)
            .then(({ response, error }) => {
                if (response) {
                    //console.log(response);
                    setFunc(response.cuentas);
                    setPagination((prevData) => ({
                        ...prevData,
                        totalItems: response.totalRows
                    }));
                } else if (error) {
                    const responseErrors = error.response.data.errors.errors;
                    if (error.response.status === 401) {
                        //TODO: hay manera de hacer el logout con mensaje y 5 segundos de tiempo???
                        localStorage.removeItem('userData');
                        navigate('/');
                    } else if (responseErrors !== undefined) {
                        setAlert({ tipo: 'danger', msn: responseErrors.map(error => error.message).join('\n'), show: true });
                    } else {
                        console.log(error);
                        setAlert({ tipo: 'danger', msn: 'Error en el systema', show: true });
                    }
                    return;
                }
            });
    }

    function executeSearch() {
        setPagination((prevData) => ({
            ...prevData,
            currentPage: 1
        }));
        console.log(searchData.byCardBin);
        //tuve que dejarlo así en lugar de sin parametro y que siempre leyera el estado, porque pareciera que no se actualiza tan rápido el estado
        //además que, en useEffect, si necesitamos el parametro, para que cada vez que se cambie, se busque y se rendere de nuevo
        doSearch(1, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
    }

    const tipo = "mis cuentas";

    useEffect(() => {
        //TODO: este lo podríamos quitar... y jugar con el menú, de manera que CARDHOLDER role, solo vea cosas de tarjetas... 
        if (userData.roleId !== 100) {
            doSearch(pagination.currentPage, '&actprefix=' + searchData['byCardBin'] + '&actname=' + searchData['byCardName'], setAccounts);
        }

        if (userData.importantMessage) {
            message(userData.importantMessage);
        }

    }, [pagination.currentPage]);

    // const tableHeaders = ['Número de Cuenta', 'Descripción', 'Saldo', 'Carátula de cuenta', 'Estados de Cuenta'];
    var tableHeaders = ['Número de Cuenta', 'Descripción', 'Saldo', 'Carátula de cuenta'];
    if ((userData.roleId === 10) || (userData.roleId === 11)) {
        tableHeaders = ['Número de Cuenta', 'Descripción', 'Saldo', 'Carátula de cuenta', 'Estados de Cuenta'];
    }
    

    const breadcrumbItems = [
        { text: userData.userName, link: '/accounts' },
        { text: 'Cuentas', link: '/accounts' },
    ];

    const menuIcon = [{ icon: impresora, url: '/accountCover', modal: '', clave: '' }];
    const menuIconR = [{ icono: report, url: '', modal: '', clave: '' }]

    const isLogin = true;

    const closeAlert = (alert) => {
        setAlert(alert);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div className='body'>
            <Header isLogin={isLogin} />
            <div className='container-fluid2 position-absolute space-acconts'>
                <div className="containerHome2">
                    <div className="container-general">
                        <div className="card-body">
                            <div className='card-sub-title-bg justify-content-between'>
                                <div className='float-start'>
                                    <MenuBody items={breadcrumbItems} handleShow={handleShow} />
                                </div>
                                <div className='float-end content-row'>
                                    <button className='btnSearchModal' type="button" onClick={handleShow}>
                                        <img className='imgSearch' src={search} alt="" />
                                    </button>
                                </div>
                            </div>
                            <div className="bg-container-body">
                                <Message alert={alert} closeAlert={closeAlert} />
                                <TableAccount headers={tableHeaders} data={accounts} currentPage={pagination.currentPage} setCurrentPage={setCurrentPage} totalPages={Math.ceil(pagination.totalItems / pagination.itemsPerPage)} menuIcon={menuIcon} link={''} menuIconR={menuIconR} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SearchModal tipo={tipo} show={show} handleClose={handleClose} sDo={executeSearch} searchObj={searchData} setSearchObj={setSearchData} />
            <Footer />
        </div>
    )
}

export default Accounts