
var Paises = new Map();
Paises.set("117", "MEXICO");
Paises.set("1", "AFGANISTAN");
Paises.set("2", "ALBANIA");
Paises.set("3", "ALEMANIA");
Paises.set("4", "ANDORRA");
Paises.set("5", "ANGOLA");
Paises.set("6", "ANTIGUA Y BARBUDA");
Paises.set("7", "ARABIA SAUDITA");
Paises.set("8", "ARGELIA");
Paises.set("9", "ARGENTINA");
Paises.set("10", "ARMENIA");
Paises.set("11", "AUSTRALIA");
Paises.set("12", "AUSTRIA");
Paises.set("13", "AZERBAIYAN");
Paises.set("14", "BAHAMAS");
Paises.set("15", "BANGLADES");
Paises.set("16", "BARBADOS");
Paises.set("17", "BAREIN");
Paises.set("18", "BELGICA");
Paises.set("19", "BELICE");
Paises.set("20", "BENIN");
Paises.set("21", "BIELORRUSIA");
Paises.set("22", "BIRMANIA");
Paises.set("23", "BOLIVIA");
Paises.set("24", "BOSNIA Y HERZEGOVINA");
Paises.set("25", "BOTSUANA");
Paises.set("26", "BRASIL");
Paises.set("27", "BRUNEI");
Paises.set("28", "BULGARIA");
Paises.set("29", "BURKINA FASO");
Paises.set("30", "BURUNDI");
Paises.set("31", "BUTAN");
Paises.set("32", "CABO VERDE");
Paises.set("33", "CAMBOYA");
Paises.set("34", "CAMERUN");
Paises.set("35", "CANADA");
Paises.set("36", "CATAR");
Paises.set("37", "CHAD");
Paises.set("38", "CHILE");
Paises.set("39", "CHINA");
Paises.set("40", "CHIPRE");
Paises.set("41", "CIUDAD DEL VATICANO");
Paises.set("42", "COLOMBIA");
Paises.set("43", "COMORAS");
Paises.set("44", "COREA DEL NORTE");
Paises.set("45", "COREA DEL SUR");
Paises.set("46", "COSTA DE MARFIL");
Paises.set("47", "COSTA RICA");
Paises.set("48", "CROACIA");
Paises.set("49", "CUBA");
Paises.set("50", "DINAMARCA");
Paises.set("51", "DOMINICA");
Paises.set("52", "ECUADOR");
Paises.set("53", "EGIPTO");
Paises.set("54", "EL SALVADOR");
Paises.set("55", "EMIRATOS ARABES UNIDOS");
Paises.set("56", "ERITREA");
Paises.set("57", "ESLOVAQUIA");
Paises.set("58", "ESLOVENIA");
Paises.set("59", "ESPAÑA");
Paises.set("60", "ESTADOS UNIDOS");
Paises.set("61", "ESTONIA");
Paises.set("62", "ETIOPIA");
Paises.set("63", "FILIPINAS");
Paises.set("64", "FINLANDIA");
Paises.set("65", "FIJI");
Paises.set("66", "FRANCIA");
Paises.set("67", "GABON");
Paises.set("68", "GAMBIA");
Paises.set("69", "GEORGIA");
Paises.set("70", "GHANA");
Paises.set("71", "GRANADA");
Paises.set("72", "GRECIA");
Paises.set("73", "GUATEMALA");
Paises.set("75", "GUINEA");
Paises.set("74", "GUINEA ECUATORIAL");
Paises.set("76", "GUINEA-BISAU");
Paises.set("77", "GUYANA");
Paises.set("78", "HAITI");
Paises.set("79", "HONDURAS");
Paises.set("80", "HUNGRIA");
Paises.set("81", "INDIA");
Paises.set("82", "INDONESIA");
Paises.set("83", "IRAK");
Paises.set("84", "IRAN");
Paises.set("85", "IRLANDA");
Paises.set("86", "ISLANDIA");
Paises.set("87", "ISLAS MARSHALL");
Paises.set("88", "ISLAS SALOMÓN");
Paises.set("89", "ISRAEL");
Paises.set("90", "ITALIA");
Paises.set("91", "JAMAICA");
Paises.set("92", "JAPON");
Paises.set("93", "JORDANIA");
Paises.set("94", "KAZAJISTAN");
Paises.set("95", "KENIA");
Paises.set("96", "KIRGUISTAN");
Paises.set("97", "KIRIBATI");
Paises.set("98", "KUWAIT");
Paises.set("99", "LAOS");
Paises.set("100", "LESOTO");
Paises.set("101", "LETONIA");
Paises.set("102", "LIBANO");
Paises.set("103", "LIBERIA");
Paises.set("104", "LIBIA");
Paises.set("105", "LIECHTENSTEIN");
Paises.set("106", "LITUANIA");
Paises.set("107", "LUXEMBURGO");
Paises.set("108", "MADAGASCAR");
Paises.set("109", "MALASIA");
Paises.set("110", "MALAUI");
Paises.set("111", "MALDIVAS");
Paises.set("112", "MALI");
Paises.set("113", "MALTA");
Paises.set("114", "MARRUECOS");
Paises.set("115", "MAURICIO");
Paises.set("116", "MAURITANIA");
Paises.set("118", "MICRONESIA");
Paises.set("119", "MOLDAVIA");
Paises.set("120", "MONACO");
Paises.set("121", "MONGOLIA");
Paises.set("122", "MONTENEGRO");
Paises.set("123", "MOZAMBIQUE");
Paises.set("124", "NAMIBIA");
Paises.set("125", "NAURU");
Paises.set("126", "NEPAL");
Paises.set("127", "NICARAGUA");
Paises.set("128", "NIGER");
Paises.set("129", "NIGERIA");
Paises.set("130", "NORUEGA");
Paises.set("131", "NUEVA ZELANDA");
Paises.set("132", "OMAN");
Paises.set("133", "PAÍSES BAJOS");
Paises.set("134", "PAKISTAN");
Paises.set("135", "PALAOS");
Paises.set("136", "PANAMA");
Paises.set("137", "PAPUA NUEVA GUINEA");
Paises.set("138", "PARAGUAY");
Paises.set("139", "PERU");
Paises.set("140", "POLONIA");
Paises.set("141", "PORTUGAL");
Paises.set("142", "REINO UNIDO");
Paises.set("143", "REPUBLICA CENTROAFRICANA");
Paises.set("144", "REPUBLICA CHECA");
Paises.set("145", "REPUBLICA DE MACEDONIA");
Paises.set("146", "REPUBLICA DEL CONGO");
Paises.set("147", "REPUBLICA DEMOCRATICA DEL CONGO");
Paises.set("148", "REPUBLICA DOMINICANA");
Paises.set("149", "REPUBLICA SUDAFRICANA");
Paises.set("150", "RUANDA");
Paises.set("151", "RUMANIA");
Paises.set("152", "RUSIA");
Paises.set("153", "SAMOA");
Paises.set("154", "SAN CRISTOBAL Y NIEVES");
Paises.set("155", "SAN MARINO");
Paises.set("156", "SAN VICENTE Y LAS GRANADINAS");
Paises.set("157", "SANTA LUCIA");
Paises.set("158", "SANTO TOME Y PRINCIPE");
Paises.set("159", "SENEGAL");
Paises.set("160", "SERBIA");
Paises.set("161", "SEYCHELLES");
Paises.set("162", "SIERRA LEONA");
Paises.set("163", "SINGAPUR");
Paises.set("164", "SIRIA");
Paises.set("165", "SOMALIA");
Paises.set("166", "SRI LANKA");
Paises.set("167", "SUAZILANDIA");
Paises.set("169", "SUDAN");
Paises.set("168", "SUDAN DEL SUR");
Paises.set("170", "SUECIA");
Paises.set("171", "SUIZA");
Paises.set("172", "SURINAM");
Paises.set("173", "TAILANDIA");
Paises.set("174", "TANZANIA");
Paises.set("175", "TAYIKISTÁN");
Paises.set("176", "TIMOR ORIENTAL");
Paises.set("177", "TOGO");
Paises.set("178", "TONGA");
Paises.set("179", "TRINIDAD Y TOBAGO");
Paises.set("180", "TUNEZ");
Paises.set("181", "TURKMENISTAN");
Paises.set("182", "TURQUIA");
Paises.set("183", "TUVALU");
Paises.set("184", "UCRANIA");
Paises.set("185", "UGANDA");
Paises.set("186", "URUGUAY");
Paises.set("187", "UZBEKISTÁN");
Paises.set("188", "VANUATU");
Paises.set("189", "VENEZUELA");
Paises.set("190", "VIETNAM");
Paises.set("191", "YEMEN");
Paises.set("192", "YIBUTI");
Paises.set("193", "ZAMBIA");
Paises.set("194", "ZIMBABUE");


//dato un id de Pais, regresa el nombre del mismo
export const countryName = (id) => {
    var nombre = Paises.get(id);
    return nombre;

}

export const findCountry = (text) => {
    for (let entry of Paises.entries()) {
        if( entry[1] === text )
            return entry[0];
    }
    return 0;
}