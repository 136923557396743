import React, { useEffect, useState } from 'react';
import Encrypter from '../../services/Encrypter';
import { fetchData, postData } from '../../services/Request'
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Message from '../../components/Message/Message';


function StatusChange({ card, show, handleClose }) {

    const navigate = useNavigate();
    const [alert, setAlert] = useState({tipo: '', msn: '', show: false});
    const closeAlert = () => {
        setAlert({tipo: '', msn: '', show: false});
    };

    // function Options({ options }) {
    //     return (
    //         options.map(option => <option key={option.id} value={option.id}> {option.value} </option>)
    //     );
    // }

    const [cardIsActive, setCardIsActive] = useState(false);
    const [cardIsBlocked, setCardIsBlocked] = useState(false);
    const [cardHasCashWarranty, setCardHasCashWarranty] = useState(false);
    const [cardHasClabeWarranty, setCardHasClabeWarranty] = useState(false);
    const [reasonChange, setReasonChange] = useState('');

    const [newWarrantyType, setNewWarrantyType] = useState(0);
    const [clabeSelected, setClabeSelected] = useState(0);
    const [clabesToSelect, setClabesToSelect] = useState([]);
    const [disableClabeWarranty, setDisableClabeWarranty] = useState(false);

    useEffect( () => {
        function getUserAvailableClabes(userId) {
            const userData = JSON.parse(localStorage.getItem('userData'))
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

            var rsp = null;
            fetchData(process.env.REACT_APP_ACCOUNT_GET_USERS_CLABES + userId + "/?token=" + userData.token, headers)
            .then(({ response, error}) => {
                if (error) {
                    if (error.response.status === 404) {
                        //console.log('Usuario sin clabes para elegir', error.response.data);
                        setDisableClabeWarranty(true);
                        setClabesToSelect([]);
                        return;
                    }
                    const responseErrors = error.response.data;
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else if (responseErrors !== undefined) {
                        setAlert({tipo: 'danger', msn: responseErrors, show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                    return;
                }

                if(response) {
                    // console.log(response);
                    // rsp = response;
                    setDisableClabeWarranty(false);
                    setClabesToSelect( response.cuentas );                    
                    return;
                }
            });
            return rsp;
        }
        if( card ) {
            console.log(card);
            closeAlert();
            // independientemente del tipo de garantía, me traigo las clabes a las que el usuario (tarjetahabiente) tiene acceso
            getUserAvailableClabes(card.idUsuario);

            setReasonChange('');
            setNewWarrantyType(card.tipoGarantia);
            switch(card.estatus) {
                case 'ACTIVE':
                    setCardIsActive(true);
                    setCardIsBlocked(false);
                    break;
                case 'BLOCKED':
                    setCardIsBlocked(true);
                    setCardIsActive(false);
                    break;
                default:
                    setCardIsActive(false);
                    setCardIsBlocked(false);
                    // no podemos hacer nada... dejamos todo desactivamos
                break;
            }

            // y aquí ponemos los datos como corresponda para el tema de tipo de garantía
            //console.log(card.tipoGarantia);
            switch(card.tipoGarantia) {
                case 0:
                    setClabeSelected(0);
                    setClabesToSelect([]);
                    setCardHasClabeWarranty(false);
                    setCardHasCashWarranty(true);
                    break;
                case 1:
                    setCardHasClabeWarranty(true);
                    setCardHasCashWarranty(false);
                    // aqui tengo que "posicionar" el select de clabes con la correspondiente a la cuenta de garantía del usuario
                    // será que con esto jala ??
                    setClabeSelected(card.cuentaGarantia);
                    break;
                default:
                    setClabeSelected(0);
                    setClabesToSelect([]);
                    setCardHasCashWarranty(false);
                    setCardHasClabeWarranty(false);
                    break;
            }
        }
    }, [card, navigate]);

    const sendStatusChange = async (status, reason) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
        const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}

        const dataToCreate = {
            statusCode: status,
            statusReason: reason
        }
        //console.log('Mandamos perfil a BD: ', dataToCreate);
        const strPayload = JSON.stringify(dataToCreate);
        const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, strPayload);
        const payload = {data: cryptPayload};

        var rsp = null;
        await postData(process.env.REACT_APP_ADMIN_CARDS + 'status/' + card.cardId + "/?token=" + userData.token, payload, headers)
        .then(({ response, error}) => {
            if (error) {
                const responseErrors = error.response.data;
                if (error.response.status === 401) {
                    localStorage.removeItem('userData');
                    navigate('/');
                    return;
                } else if (responseErrors !== undefined) {
                    setAlert({tipo: 'danger', msn: responseErrors, show: true});
                } else {
                    setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                }
                return;
            }

            if(response) {
                rsp = JSON.parse( response );
                return;
            }
        });
        return rsp;
    }

    const reasonChanged = (e) => {
        closeAlert();
        const { value } = e.target;
        setReasonChange(value);
    }

    const blockCard = async () => {
        closeAlert();
        if(reasonChange) {
            if(reasonChange !== 'CLIENT_INTERNAL_REASON' && reasonChange !== 'USER_INTERNAL_REASON') {
                setAlert({tipo: 'warning', msn: 'La razón del bloqueo solo puede ser por decisión del cliente o de SITESPAY', show: true});
                return;
            }
            const resp = await sendStatusChange(1, reasonChange);
            if(resp && resp.status === 'BLOCKED') {
                setAlert({tipo: 'success', msn: 'Estatus de la tarjeta modificado', show: true});
            } else {
                setAlert({tipo: 'danger', msn: 'Hubo un error en el cambio de estatus, avisar al administrador', show: true});
            }
        } else {
            setAlert({tipo: 'warning', msn: 'Necesita seleccionar la razón del bloqueo', show: true});
            return;
        }
    }
    const cancelCard = async () => {
        closeAlert();
        if(!reasonChange) {
            setAlert({tipo: 'warning', msn: 'Requiere la razón por la cual cancelará esta tarjeta', show: true});
            return;
        }
        const resp = await sendStatusChange(3, reasonChange);
        console.log(resp);
        if(resp && resp.status === 'DISABLED') {
            setAlert({tipo: 'success', msn: 'Estatus de la tarjeta modificado', show: true});
        } else {
            setAlert({tipo: 'danger', msn: 'Hubo un error en el cambio de estatus, avisar al administrador', show: true});
        }
    }
    const reactivateCard = async () => {
        closeAlert();
        if( card.estatus !== 'BLOCKED' ) {
            setAlert({tipo: 'warning', msn: 'Estatus de tarjeta incorrecto para poderla reactivar', show: true});
        }
        if(reasonChange !== 'CLIENT_INTERNAL_REASON' && reasonChange !== 'USER_INTERNAL_REASON') {
            setAlert({tipo: 'warning', msn: 'La razón para levantar el bloqueo solo puede ser por decisión del cliente o de SITESPAY', show: true});
            return;
        }
        const resp = await sendStatusChange(0, reasonChange);
        if(resp && resp.status === 'ACTIVE') {
            setAlert({tipo: 'success', msn: 'Estatus de la tarjeta modificado', show: true});
        } else {
            setAlert({tipo: 'danger', msn: 'Hubo un error en el cambio de estatus, avisar al administrador', show: true});
        }

    }

    const clabeChanged = (e) => {
        closeAlert();
        //setClabeSelected con lo que sea que llegó seleccionado... e.target... etc
        //const { id, value } = e.target;
        //console.log('clabeChanged: ', id, value);
        const { value } = e.target;
        setClabeSelected(value);
    }

    const warrantySet = (e) => {
        closeAlert();
        const { id } = e.target;
        //console.log('warrantySet: ', id, value);
        switch(id) {
            case 'cashWarranty':
                setNewWarrantyType(0);

                setCardHasCashWarranty(true);
                setCardHasClabeWarranty(false);
                break;
            case 'clabeWarranty':
                setNewWarrantyType(1);

                setCardHasClabeWarranty(true);
                setCardHasCashWarranty(false);
                break;
            default:
                //do nothing
                break;
        }
    }

    const warrantyChange = () => {
        setAlert({tipo: '', msn: '', show: false});
        if( (newWarrantyType !== card.tipoGarantia) || ( newWarrantyType && (clabeSelected !== card.cuentaGarantia)) ) {

            var changeWarrantyObj = {
                warrantyType: newWarrantyType,
                warrantyValue: ''
            }

            //hay cambio de garantía
            if(newWarrantyType === 1) {
                //console.log('pasamos de garantía liquida a clabe: ', clabeSelected, card.cuentaGarantia );
                if( ( !clabeSelected) || ((card.cuentaGarantia) &&( clabeSelected === card.cuentaGarantia))) {
                    setAlert({tipo: 'warning', msn: 'Favor de seleccionar una cuenta clabe para ser asignada', show: true});
                    return;
                } else {
                    changeWarrantyObj.warrantyValue = clabeSelected;
                }
            } // else { pasamos de garantia con cuenta a garantía liquida, no hay mucho que hacer }

            const userData = JSON.parse(localStorage.getItem('userData'));
            const token = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, userData.token);
            const headers = { headers:  {'authorization':'APIAuth '+ process.env.REACT_APP_API_KEY + ':' + token}}
            const cryptPayload = Encrypter.aesEncrypt(process.env.REACT_APP_API_SECRET, JSON.stringify(changeWarrantyObj));
            postData(process.env.REACT_APP_ADMIN_CARDS + "warranty/" + card.cardId + "/?token=" + userData.token, { data: cryptPayload }, headers)
            .then(({ response, error}) => {
                if (error) {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem('userData');
                        navigate('/');
                        return;
                    } else {
                        console.log(error);
                        setAlert({tipo: 'danger', msn: 'Error en el sistema', show: true});
                    }
                }

                if( response ) {
                    const rspObj = JSON.parse(response);
                    if(rspObj.status === 'ok') {
                        card.tipoGarantia = newWarrantyType;
                        card.cuentaGarantia = clabeSelected;
                        setAlert({tipo: 'success', msn: 'Cambio realizado', show: true});
                    } else {
                        setAlert({tipo: 'danger', msn: 'Error al actualizar tipo de garantía', show: true});
                    }
                }
            });

        } else {
            setAlert({tipo: 'warning', msn: 'No hay cambios por registrar', show: true});
            //console.log('NO warranty TO BE changed');
        }
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5 className="modal-title">Estatus de la tarjeta</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Message alert={alert} closeAlert={closeAlert} />
                    <div className="row containerCard4">
                        <div className="col-md">
                            <div className="card card-caratula">
                                <div className="card-body">
                                    {(cardIsActive) && 
                                        <div id="showActive">
                                            <p>La tarjeta con terminación {card.last_four} esta ACTIVA</p>
                                            <h5 className="text-9">Cambio de estatus</h5>
                                            <hr></hr>
                                            <div className="form-check">
                                                <select className="form-select form-select-sm mb-2" aria-label="Large select example" id="reasons" value={reasonChange} onChange={reasonChanged}>
                                                    <option value="">Seleccione una razón del cambio</option>
                                                    <option value="CLIENT_INTERNAL_REASON">Decisión de SITESPAY</option>
                                                    <option value="USER_INTERNAL_REASON">Decisión del tarjetahabiente</option>
                                                    <option value="LOST">Perdida</option>
                                                    <option value="STOLEN">Robada</option>
                                                    <option value="BROKEN">Inservible</option>
                                                </select>
                                                <div>
                                                    <button type="button" className="btn btn-outline-warning button-check" onClick={blockCard}></button>
                                                    <label className="form-check-label2">Bloquear tarjeta (temporalmente)</label>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btn-outline-danger button-check" onClick={cancelCard}></button>
                                                    <label className="form-check-label2">Cancelar tarjeta (permanentemente)</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {(cardIsBlocked) && 
                                        <div id="showBlocked">
                                            <p>La tarjeta con terminación {card.last_four} esta BLOQUEADA</p>
                                            <h5 className="text-9">Cambio de estatus</h5>
                                            <hr></hr>
                                            <div className="form-check">
                                                <select className="form-select form-select-sm mb-2" aria-label="Large select example" id="reasons" value={reasonChange} onChange={reasonChanged}>
                                                    <option value="">Seleccione una razón del cambio</option>
                                                    <option value="CLIENT_INTERNAL_REASON">Decisión de SITESPAY</option>
                                                    <option value="USER_INTERNAL_REASON">Decisión del tarjetahabiente</option>
                                                    <option value="LOST">Perdida</option>
                                                    <option value="STOLEN">Robada</option>
                                                    <option value="BROKEN">Inservible</option>
                                                </select>
                                                <div>
                                                    <button type="button" className="btn btn-outline-primary button-check" onClick={reactivateCard}></button>
                                                    <label className="form-check-label2">Reactivar tarjeta</label>
                                                </div>
                                                <div>
                                                    <button type="button" className="btn btn-outline-danger button-check" onClick={cancelCard}></button>
                                                    <label className="form-check-label2">Cancelar tarjeta (permanentemente)</label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row containerCard4">
                        <div className="col-md">
                            <div className="card card-caratula">
                                <div className="card-body">
                                    <h5 className="text-9">Cambio de garantia</h5>
                                    <hr></hr>
                                    <div className="form-check">
                                        <input type="radio" className="btn-check" name="options-outlined2" id="cashWarranty" autoComplete="off" checked={cardHasCashWarranty} onChange={warrantySet} />
                                        <label className="btn btn-outline-success btn-circle" htmlFor="cashWarranty"></label>
                                        <label className="form-check-label2" htmlFor="cashWarranty">Garantía líquida</label>
                                    </div>
                                    <div className="form-check">
                                        <input type="radio" className="btn-check" name="options-outlined2" id="clabeWarranty" autoComplete="off" checked={cardHasClabeWarranty} onChange={warrantySet} disabled={disableClabeWarranty} />
                                        <label className="btn btn-outline-secondary btn-circle" htmlFor="clabeWarranty"></label>
                                        <label className="form-check-label3" htmlFor="clabeWarranty">Garantía con cuenta clabe</label>
                                        <select className="form-select form-select-sm" aria-label="Large select example" onChange={clabeChanged} value={clabeSelected} disabled={disableClabeWarranty} >
                                            <option value='0'>Seleccione cuenta clabe</option>
                                            {clabesToSelect.map((account) => (
                                                <option
                                                    key={account.id}
                                                    value={account.accountNumber}>
                                                    {account.accountNumber} - {account.description}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="card-footer text-end">
                                    <button type="button" className="btn btn-primary2 btnModal" onClick={warrantyChange}>Guardar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                <button type="button" className="btn btn-primary2 btnModal" onClick={handleClose}>Cerrar</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default StatusChange
